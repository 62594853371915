<template>
  <v-container fluid>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" sm="10" class="pb-2">
        <span class="header-title pt-5"> {{ $t('menu.setting') }} </span>
      </v-col>
    </v-row>
    <v-form v-model="formValid" ref="myForm">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" sm="10">
        <v-switch v-model="isRegister" color="primary" flat hide-details inset v-bind:label="$t('setting_management.allow_register')"></v-switch>
      </v-col>

      <v-col cols="12" sm="10">
        <v-switch v-model="isAutoTopUp" color="primary" inset v-bind:label="$t('setting_management.auto_top_up')"></v-switch>
      </v-col>

      <v-col cols="12" sm="10">
        <v-textarea v-model="userNotification" v-bind:label="$t('setting_management.user_notification')" :rules="stringRules" outlined background-color="#ffffff"
                    required></v-textarea>
      </v-col>

      <v-col cols="12" sm="10">
        <v-textarea v-model="saleNotification" v-bind:label="$t('setting_management.sale_notification')" :rules="stringRules" background-color="#ffffff" outlined required></v-textarea>
      </v-col>

      <v-col cols="12" sm="10">
        <v-textarea v-model="senderSpam" v-bind:label="$t('setting_management.sender_spam')" :rules="stringRules" background-color="#ffffff" outlined required></v-textarea>
      </v-col>

      <v-col cols="12" sm="10">
        <v-textarea v-model="contentSpam" v-bind:label="$t('setting_management.content_spam')" :rules="stringRules" background-color="#ffffff" outlined required></v-textarea>
      </v-col>

      <v-col cols="12" sm="10">
        <v-text-field v-model="banking" v-bind:label="$t('setting_management.banking')" :rules="stringRules" background-color="#ffffff" outlined required dense></v-text-field>
      </v-col>

      <v-col cols="12" sm="10">
        <v-text-field v-model="account" v-bind:label="$t('setting_management.account')" :rules="stringRules" background-color="#ffffff" outlined required dense></v-text-field>
      </v-col>

      <v-col cols="12" sm="10">
        <v-text-field v-model="bankingAccount" v-bind:label="$t('setting_management.banking_account')" :rules="stringRules" background-color="#ffffff" outlined required dense></v-text-field>
      </v-col>

      <v-col cols="12" sm="10">
        <v-text-field v-model="bankingName" v-bind:label="$t('setting_management.banking_name')" :rules="stringRules" background-color="#ffffff" outlined required dense></v-text-field>
      </v-col>

      <v-col cols="12" sm="10">
        <v-text-field v-model="formatTopUp" v-bind:label="$t('setting_management.format_top_up')" :rules="stringRules" background-color="#ffffff" outlined required dense></v-text-field>
      </v-col>

      <v-col cols="12" sm="10">
        <v-numeric-field v-model="minimumPrice"
                         v-bind:label="$t('setting_management.minimum_price')" :rules="numberRules" background-color="#ffffff"  outlined dense>
        </v-numeric-field>
      </v-col>

      <v-col cols="12" sm="10">
        <span>{{ $t('setting_management.qrcode_bank') }}</span>
        <image-input v-model="qrcode" @input="uploadImage" fieldName="qrcode" class="mr-4">
          <div slot="activator">
            <v-img width="150px" height="150px" contain v-if="!qrcode || !qrcode.base64" class="white lighten-3 mb-3">
              <span>{{ $t('common.select_image') }}</span>
            </v-img>
            <v-img width="150px" height="150px" contain :src="qrcode.base64" v-else class="mb-3">
            </v-img>
          </div>
        </image-input>
      </v-col>

      <v-col cols="12" sm="10" class="mt-5">
        <v-textarea v-model="FAQ"
                         v-bind:label="$t('setting_management.FAQ')" :rules="stringRules" background-color="#ffffff"  outlined dense>
        </v-textarea>
      </v-col>

<!--      <v-col cols="12" sm="10">-->
<!--        <v-file-input-->
<!--            show-size-->
<!--            v-model="apiFile"-->
<!--            label="Tài liệu"-->
<!--        ></v-file-input>-->
<!--      </v-col>-->

      <v-col cols="12" sm="10">
        <v-btn large dark color="primary" @click="updateSettingInfor"> {{ $t('common.update') }}</v-btn>
      </v-col>
    </v-row>
    </v-form>

    <v-snackbar
        v-model="snackbar"
        :color="color"
        :timeout="2000"
        top
        right
        elevation="24"
        class="mt-5"
    >
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import i18n from '@/plugins/i18n';
import ImageInput from "@/components/ImageInput";
import VNumericField from "@/components/VNumericField";

export default {
  data: () => ({
    isRegister: true,
    isAutoTopUp: true,
    userNotification: '',
    saleNotification: '',
    senderSpam: '',
    contentSpam: '',
    banking: '',
    bankingName: '',
    account: '',
    bankingAccount: '',
    FAQ: '',
    formatTopUp: '',
    minimumPrice: 50000,
    formValid: false,
    apiFile: {},
    base64File: '',
    snackbar: false,
    message: '',
    color: '#0C8F4D',

    listConfig: [],
    qrcode: {},
    stringRules: [
      v => !!v || i18n.t('validate.string_require'),
      //   v => (v && v.length > 3) || i18n.t('validate.string_invalid')
    ],

    numberRules: [
      v => !!v || i18n.t('validate.not_empty'),
      v => (v && v > 0) || i18n.t('validate.string_invalid')
    ],
  }),

  watch: {
    apiFile: function (newVal, oldVal) {
      if(newVal) {
        this.createBase64Image(newVal);
      } else {
        this.base64File = null;
      }
    }
  },

  components: {
    VNumericField: VNumericField,
    ImageInput: ImageInput
  },

  computed: {

  },

  async created() {
    await this.getSettingInfor();
  },

  methods: {
    async getSettingInfor() {
      try {
        const response = await this.$getAllConfig();
        if (response.status === 200) {
          // const contextArr = [].concat(response.data);
          this.listConfig = [].concat(response.data);

          this.listConfig.forEach(item => {
            if (item.key == 'EnableRegister') {
              this.isRegister = item.value === "true" ? true : false;
            } else if (item.key == 'AutoTopUp') {
              this.isAutoTopUp = item.value === "true" ? true : false;
            } else if (item.key == 'UserNotification') {
              this.userNotification = item.value;
            } else if (item.key == 'SaleNotification') {
              this.saleNotification = item.value;
            } else if (item.key == 'SenderSpam') {
              this.senderSpam = item.value;
            } else if (item.key == 'ContentSpam') {
              this.contentSpam = item.value;
            } else if (item.key == 'BankingAccount') {
              this.bankingAccount = item.value;
            } else if (item.key == 'BankingName') {
              this.bankingName = item.value;
            } else if (item.key == 'banking') {
              this.banking = item.value;
            } else if (item.key == 'Account') {
              this.account = item.value;
            } else if (item.key == 'FormatTopUp') {
              this.formatTopUp = item.value;
            } else if (item.key == 'MinimumPrice') {
              this.minimumPrice = item.value;
            } else if (item.key == 'FAQ') {
              this.FAQ = item.value;
            } else if (item.key == 'QRCode') {
              this.qrcode.base64 = item.value;
            }
            // else if (item.key == 'APIPDF') {
            //   this.base64File = item.value;
            // }
          });
        } else if (response.data) {
          alert(i18n.t(response.data));
        } else {
          alert(i18n.t('validate.request_error'));
        }
      } catch (e) {
        alert(i18n.t('validate.request_error'));
      }
    },

    async updateSettingInfor() {
      this.$refs.myForm.validate();

      if (!this.validateSettingInfor()) {
        return;
      }

      this.listConfig.forEach(item => {
        if (item.key == 'EnableRegister') {
          item.value = this.isRegister;
        } else if (item.key == 'AutoTopUp') {
          item.value = this.isAutoTopUp;
        } else if (item.key == 'UserNotification') {
          item.value = this.userNotification;
        } else if (item.key == 'SaleNotification') {
          item.value = this.saleNotification;
        } else if (item.key == 'SenderSpam') {
          item.value = this.senderSpam;
        } else if (item.key == 'ContentSpam') {
          item.value = this.contentSpam;
        } else if (item.key == 'BankingAccount') {
          item.value = this.bankingAccount;
        } else if (item.key == 'BankingName') {
          item.value = this.bankingName;
        } else if (item.key == 'banking') {
          item.value = this.banking;
        } else if (item.key == 'Account') {
          item.value = this.account;
        } else if (item.key == 'FormatTopUp') {
          item.value = this.formatTopUp;
        } else if (item.key == 'MinimumPrice') {
          item.value = this.minimumPrice;
        } else if (item.key == 'FAQ') {
          item.value = this.FAQ;
        } else if (item.key == 'QRCode') {
          item.value = this.qrcode.base64;
        }
        // else if (item.key == 'APIPDF') {
        //   this.base64File = item.value;
        // }
      });

      // let requestBody = [
      //   {
      //     key: 'EnableRegister',
      //     value: this.isRegister
      //   },
      //   {
      //     key: 'AutoTopUp',
      //     value: this.isAutoTopUp
      //   },
      //   {
      //     key: 'UserNotification',
      //     value: this.userNotification
      //   },
      //   {
      //     key: 'SaleNotification',
      //     value: this.saleNotification
      //   },
      //   {
      //     key: 'SenderSpam',
      //     value: this.senderSpam
      //   },
      //   {
      //     key: 'ContentSpam',
      //     value: this.contentSpam
      //   },
      //   {
      //     key: 'banking',
      //     value: this.banking
      //   },
      //   {
      //     key: 'Account',
      //     value: this.account
      //   },
      //   {
      //     key: 'BankingName',
      //     value: this.bankingName
      //   },
      //   {
      //     key: 'BankingAccount',
      //     value: this.bankingAccount
      //   },
      //   {
      //     key: 'FormatTopUp',
      //     value: this.formatTopUp
      //   },
      //   {
      //     key: 'MinimumPrice',
      //     value: this.minimumPrice
      //   },
      //   {
      //     key: 'FAQ',
      //     value: this.FAQ
      //   },
      //   {
      //     key: 'QRCode',
      //     value: this.qrcode.base64
      //   }
      // ]

      try {
        const response = await this.$createConfig(this.listConfig);
        if (response.status === 200) {
          this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
          this.message = 'Cập nhật thành công!'
          this.snackbar = true;
        } else if (response.data) {
          await this.$fire({
            title: i18n.t('common.error'),
            text: response.data,
            type: "error"
          });
        }
      } catch (e) {
        console.log(e.stack);
      }
    },

    createBase64Image: function(FileObject) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.base64File = event.target.result;
      }
      reader.readAsDataURL(FileObject);
    },

    validateSettingInfor() {
      if (!this.userNotification || this.userNotification.length == 0) {
        return false;
      }

      if (!this.saleNotification || this.saleNotification.length == 0) {
        return false;
      }

      if (!this.senderSpam || this.senderSpam.length == 0) {
        return false;
      }

      if (!this.contentSpam || this.contentSpam.length == 0) {
        return false;
      }

      if (!this.bankingAccount || this.bankingAccount.length == 0) {
        return false;
      }

      if (!this.banking || this.banking.length == 0) {
        return false;
      }

      if (!this.account || this.account.length == 0) {
        return false;
      }

      if (!this.formatTopUp || this.formatTopUp.length == 0) {
        return false;
      }

      if (!this.FAQ || this.FAQ.length == 0) {
        return false;
      }

      if (!this.minimumPrice || this.minimumPrice < 0) {
        return false;
      }

      // if (!this.base64File || this.base64File.length == 0) {
      //   return false;
      // }

      return true;
    },

    uploadImage() {
      // this.qrcodeBase64 = this.qrcode.base64;
      console.log("uploadImage");
    },
  }
}
</script>