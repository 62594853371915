import Vue from "vue";

export default {
    install() {
        Vue.prototype.$getAllService = async function (page, limit) {
            let result = null;
            try {
                const response = await this.$http.get(process.env.VUE_APP_GET_ALL_SERVICE_API + "?sort=desc&limit=" + limit + "&page=" + page);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$getAllServiceForUser = async function (page, limit) {
            let result = null;
            try {
                const response = await this.$http.get(process.env.VUE_APP_GET_ALL_SERVICE_API + "?sort=desc&status=1&limit=" + limit + "&page=" + page);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$createService = async function (requestData) {
            let result = null;
            try {
                const response = await this.$http.post(process.env.VUE_APP_CREATE_SERVICE_API, JSON.stringify(requestData));
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$updateService = async function (id, requestData) {
            let result = null;
            try {
                const response = await this.$http.put(process.env.VUE_APP_UPDATE_SERVICE_API + id, JSON.stringify(requestData));
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$deleteService = async function (id) {
            let result = null;
            try {
                const response = await this.$http.delete(process.env.VUE_APP_DELETE_SERVICE_API + id);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$statusService = function(item) {
            if (item.status == 0) {
                return 'otpError';
            } else {
                return 'otpSuccess';
            }
        };
    },
};