// import Vue from "vue";

import Vue from "vue";
import moment from "moment";

export default {
    install () {
        Vue.prototype.$formatStartUTCDate = function (date) {
            // yyyy-MM-dd'T'HH:mm:ss
            let time = moment(String(date)).local().format("YYYY-MM-DDT00:00:00Z");
            return moment.utc(String(time)).format("YYYY-MM-DDTHH:mm:ssZ");
        };

        Vue.prototype.$formatEndUTCDate = function (date) {
            let time = moment(String(date)).local().format("YYYY-MM-DDT23:59:59Z");
            return moment.utc(String(time)).format("YYYY-MM-DDTHH:mm:ssZ");
        };

        Vue.prototype.$formatUTCDate = function (date) {
            let time = moment(String(date)).local().format("YYYY-MM-DDTHH:mm:ssZ");
            return moment.utc(String(time)).format("YYYY-MM-DDTHH:mm:ssZ");
        };
        // Vue.prototype.$showToastSuccess = function (msg) {
        //     this.$toast(msg, {
        //         color: process.env.VUE_APP_SUCCESS_COLOR_TOAST,
        //         icon: 'check',
        //         dismissable: true,
        //         queueable: true
        //     });
        // };
        //
        // Vue.prototype.$showToastWarning = function (msg) {
        //     this.$toast(msg, {
        //         color: process.env.VUE_APP_WARNING_COLOR_TOAST,
        //         dismissable: true,
        //         queueable: true
        //     });
        // };
        //
        // Vue.prototype.$showToastError = function (msg) {
        //     this.$toast(msg, {
        //         color: process.env.VUE_APP_ERROR_COLOR_TOAST,
        //         dismissable: true,
        //         queueable: true
        //     });
        // };
    },
};
