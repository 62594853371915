<template>
  <v-container>
    <div>
      <v-row >
        <p> Hướng dẫn sử dụng fplus với codesim.net </p>
      </v-row >
      <v-row v-if="result">
        <iframe width="640" height="480" :src="result" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </v-row>
    </div>

  </v-container>
</template>

<script>

import i18n from '@/plugins/i18n';

export default {
  data: () => ({
    valid: true,
    formValid: false,
    editedItem: {},
    FAQ: '',
    overlay: false,

    youtubeURL: "",
    result: "",

    stringRules: [
      v => !!v || i18n.t('validate.not_empty'),
      //   v => (v && v.length > 5) || i18n.t('validate.string_invalid')
    ],

    phoneRules: [
      v => !!v || i18n.t('validate.not_empty'),
      v => (v && v.length > 9) || i18n.t('validate.phone_invalid'),
    ],

    passdRules: [
      v => !!v || i18n.t('validate.password_require'),
      v => (v && v.length > 5) || i18n.t('validate.password_invalid'),
    ],
  }),

  components: {

  },

  watch: {
  },

  computed: {
  },

  async created () {
    await this.getSettingInfor();
  },

  methods: {
    async getSettingInfor() {
      try {
        const response = await this.$getAllConfig();
        if (response.status === 200) {
          const contextArr = [].concat(response.data);
          contextArr.forEach(item => {
            if (item.key == 'FAQ') {
              this.youtubeURL = item.value;
              this.loadURL();
            }
          });
        } else if (response.data) {
          alert(i18n.t(response.data));
        } else {
          alert(i18n.t('validate.request_error'));
        }
      } catch (e) {
        alert(i18n.t('validate.request_error'));
      }
    },

    loadURL() {
      const youtubeEmbedTemplate = "https://www.youtube.com/embed/";
      const url = this.youtubeURL.split(
          /(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/
      );
      console.log("url", url);
      const YId =
          undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0];
      console.log("YId", YId);
      if (YId === url[0]) {
        console.log("not a youtube link");
      } else {
        console.log("it's  a youtube video");
      }
      const topOfQueue = youtubeEmbedTemplate.concat(YId);
      console.log("topOfQueue", topOfQueue);
      this.result = topOfQueue;
      this.youtubeURL = "";
    }
  }
}
</script>

<style scoped>
p {
  color: #1D243E;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  padding-top: 20px;
}
</style>