import Vue from "vue";

export default {
    install() {
        Vue.prototype.$getAllCredit = async function (page, limit, from, to) {
            let result = null;
            try {
                const response = await this.$http.get(process.env.VUE_APP_GET_ALL_CREDIT + "?sort=desc&limit=" + limit + "&page=" + page + "&from_date=" + from + "&to_date=" + to);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$getAllCreditAdmin = async function (page, limit, from, to) {
            let result = null;
            try {
                const response = await this.$http.get(process.env.VUE_APP_GET_ALL_CREDIT_ADMIN + "?sort=desc&limit=" + limit + "&page=" + page + "&date_from=" + from + "&date_to=" + to);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$cancelCredit = async function (id) {
            let result = null;
            try {
                const response = await this.$http.post(process.env.VUE_APP_CANCEL_CREDIT + id);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$createCredit = async function (requestData) {
            let result = null;
            try {
                const response = await this.$http.post(process.env.VUE_APP_CREATE_CREDIT, JSON.stringify(requestData));
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$updateCredit = async function (id, requestData) {
            let result = null;
            try {
                const response = await this.$http.post(process.env.VUE_APP_UPDATE_CREDIT + id, JSON.stringify(requestData));
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$updateNoteCredit = async function (id, requestData) {
            let result = null;
            try {
                const response = await this.$http.put(process.env.VUE_APP_UPDATE_NOTE_CREDIT + id, JSON.stringify(requestData));
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$getAllCreditUser = async function (userId, page, limit, from, to) {
            let result = null;
            try {
                const response = await this.$http.get(process.env.VUE_APP_GET_ALL_CREDIT + "?sort=desc&limit=" + limit + "&page=" + page + "&user_id=" + userId+ "&from_date=" + from + "&to_date=" + to);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$statusCredit = function(item) {
            if (item.status == 0) {
                return 'otpError';
            } else if (item.status == 1) {
                return 'otpSuccess';
            } else {
                return 'otpError';
            }
        };
    },
};