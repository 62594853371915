<template>
  <v-container>
    <div>
      <v-row >
        <v-col cols="12" sm="12">
          <p class="header-title">{{ $t('profile.title') }} </p>
        </v-col>
      </v-row >
    </div>

    <v-row>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-text-field background-color="#ffffff" v-model="editedItem.username" v-bind:label="$t('user_management.account')" :rules="stringRules" outlined
                           dense readonly></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-numeric-field background-color="#ffffff" v-model="editedItem.balance" v-bind:label="$t('user_management.balance')" outlined
                          dense readonly></v-numeric-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-text-field background-color="#ffffff" v-model="editedItem.code" v-bind:label="$t('user_management.top_up_code')" :rules="stringRules" outlined
                          dense readonly></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="6">
<!--            <v-text-field background-color="#ffffff" v-model="editedItem.full_name" label="6 số cuối số điện thoại" :rules="stringRules" outlined-->
<!--                          dense></v-text-field>-->
            <v-text-field background-color="#ffffff" v-model="editedItem.full_name" :rules="displayNameRules"
                          label="6 số cuối số điện thoại"
                          outlined name="username"
                          oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          type = "number"
                          maxlength = "6" required/>
          </v-col>
        </v-row>
<!--        <v-row no-gutters>-->
<!--          <v-col cols="12" sm="6">-->
<!--            <v-text-field background-color="#ffffff" v-model="editedItem.phone" :rules="stringRules" v-bind:label="$t('user_management.phone')" outlined-->
<!--                          dense></v-text-field>-->
<!--          </v-col>-->
<!--        </v-row>-->
<!--        <v-row no-gutters>-->
<!--          <v-col cols="12" sm="6">-->
<!--            <v-text-field background-color="#ffffff" v-model="editedItem.role.content" v-bind:label="$t('user_management.role')" outlined-->
<!--                          dense readonly></v-text-field>-->
<!--          </v-col>-->
<!--        </v-row>-->
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-textarea background-color="#ffffff" v-model="editedItem.api_key" v-bind:label="$t('user_management.api_key')" outlined dense readonly></v-textarea>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="2" class="mr-2">
            <v-btn large block color="primary" class="normal-button" @click="updateInformation"> {{ $t('profile.btn_title') }} </v-btn>
          </v-col>

          <v-col cols="12" sm="2">
            <v-btn dark large block color="#0C8F4D" class="normal-button" @click="generateAPIKey"> Tạo API Key mới </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-row>


    <v-overlay :value="overlay" :z-index="0">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
        v-model="snackbar"
        :color="color"
        :timeout="2000"
        top
        right
        elevation="24"
        class="mt-5"
    >
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>

import i18n from '@/plugins/i18n';
import VNumericField from "@/components/VNumericField";

export default {
    data: () => ({
      valid: true,
      formValid: false,
      editedItem: {},

      overlay: false,
      snackbar: false,
      message: '',
      color: '#0C8F4D',

      stringRules: [
        v => !!v || i18n.t('validate.not_empty'),
      //   v => (v && v.length > 5) || i18n.t('validate.string_invalid')
      ],

      phoneRules: [
          v => !!v || i18n.t('validate.not_empty'),
          v => (v && v.length > 9) || i18n.t('validate.phone_invalid'),
      ],

      passdRules: [
          v => !!v || i18n.t('validate.password_require'),
          v => (v && v.length > 5) || i18n.t('validate.password_invalid'),
      ],

      displayNameRules: [
        v => !!v || i18n.t('validate.string_require'),
        v => (v && v.length === 6) || i18n.t('validate.string_invalid'),
      ],
    }),

    components: {
      VNumericField
    },

    watch: {
    },

    computed: {
    },

    async created () {
      const response = await this.$getInformationUser();
      if (response.status === 200) {
        this.editedItem = response.data;
        // if (this.roles.length > 0) {
        //   this.selectRole = this.roles.find(role => role.id === this.editedItem.role_id)
        // }
      }
    },

    methods: {
      async generateAPIKey() {
        const response = await this.$generateAPIKey();

        if (response.status === 200) {
          this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
          this.message = i18n.t('common.update_success');
          this.snackbar = true;

          this.editedItem.api_key = response.data;
        } else {
          this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
          this.message = i18n.t('common.error')
          this.snackbar = true;
        }
      },

      async updateInformation() {
        // this.$refs.myForm.validate();

        if (!this.validateUserInfor()) {
          return;
        }

        this.overlay = true;

        const requestBody = {
          full_name: this.editedItem.full_name,
          phone: this.editedItem.phone,
          content: ""
        }

        const response = await this.$updateInformationUser(requestBody);
        this.overlay = false;

        if (response.status === 200) {
          this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
          this.message = i18n.t('common.update_success');
          this.snackbar = true;
        } else {
          this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
          this.message = i18n.t('common.error')
          this.snackbar = true;
        }
      },

      validateUserInfor() {
        if (!this.editedItem.phone || this.editedItem.phone.length == 0) {
          return false;
        }

        if (!this.editedItem.full_name || this.editedItem.full_name.length == 0) {
          return false;
        }

        return true;
      },
    }
}
</script>

<style scoped>
p {
    color: #1D243E;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    padding-top: 20px;
}
</style>