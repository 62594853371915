<template>
  <v-container fluid>
    <div>
      <v-row justify="space-between">
        <v-col cols="12" sm="6" justify="center">
          <p class="header-title"> {{$t('credit_management.title')}} </p>
        </v-col>

        <v-col cols="12" sm="6" justify="center">
          <div class="d-flex flex-row-reverse">
            <v-btn color="primary" dark @click="showImportDialog" class="ml-4">{{
                "Import"
              }}</v-btn>

            <v-btn color="primary" dark @click="getData" class="ml-4">{{
                $t("common.search")
              }}</v-btn>

            <vc-date-picker v-model="endDate" :popover="{label: 'Hover', visibility: 'hover'}" style="width: 120px;" class="ml-4">
            </vc-date-picker>
            <span class="mt-2 ml-2">đến</span>
            <vc-date-picker v-model="startDate" :popover="{label: 'Hover', visibility: 'hover'}" style="width: 120px;" class="ml-4">
            </vc-date-picker>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :loading="loading"
        sort-by="email"
        class="elevation-1"
        hide-default-footer
        :page.sync="page"
        @dblclick:row="editItem"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event">
      <template v-slot:top>

        <v-toolbar flat color="white" class="pt-5">
          <v-row justify="start">
            <v-col cols="12" sm="2">
              <v-autocomplete v-model="itemsPerPage" :items="numberPage" outlined :dense="true"></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="4">

            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field v-model="search" append-icon="search" v-bind:label="$t('common.search')" single-line hide-details outlined :dense="true"></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>

        <div>
          <span class="defaultFont">Tổng giao dịch: <span class="defaultBoldFont"> {{ $thousandSeprator(totalItem) }}</span>.
            Tổng tiền: <span class="defaultBoldFont"> {{ $thousandSeprator(totalMoney) }} </span> </span>
        </div>
      </template>

      <template v-slot:[`item.payment`]="{ item }">
        <span class="text-no-wrap">{{ $thousandSeprator(item.payment) }}</span>
      </template>

      <template v-slot:[`item.user`]="{ item }">
        <span class="text-no-wrap" v-if="item.unknown === 0">{{ item.user.username }}</span>
        <v-btn dark depressed color="#ff586b" height="30px" width="90px" @click="findingUser(item)"  v-else>Tìm người nạp</v-btn>
      </template>

      <template v-slot:[`item.time`]="{ item }">
        <span class="text-no-wrap">{{ item.time | formatDate }}</span>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="contentAddress d-flex justify-start">
          <v-tooltip
              bottom
              max-width="300px"
              color="primary"
          >
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" size="12px" color="white" class="iconBorderRed" @click="cancelCredit(item)" v-if="item.unknown === 0 && item.status === 1">
                cancel
              </v-icon>
            </template>
            <span>Hủy giao dịch</span>
          </v-tooltip>

          <span v-if="item.unknown === 0 && item.status === 0">
            Đã bị hủy
          </span>
        </div>

      </template>

      <template v-slot:no-data>
        <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
      </template>
    </v-data-table>

    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="pageCount"></v-pagination>
    </div>

    <searching-user-dialog
        v-model="dialogSearching"
        :editedItem="editedItem"
        @closeDialog="closeSearchingDialog"
        @updateCredit="updateData"
        v-if="dialogSearching">
    </searching-user-dialog>

    <create-note-credit
        v-model="dialogUpdateNote"
        :editedItem="editedItem"
        @closeDialog="closeDialogUpdateNote"
        @updateSuccess="updateData"
        v-if="dialogUpdateNote">
    </create-note-credit>

    <import-credit-dialog
        v-model="dialogImport"
        :editedItem="editedItem"
        @closeDialog="closeImportDialog"
        v-if="dialogImport">
    </import-credit-dialog>

    <v-snackbar
        v-model="snackbar"
        :color="color"
        :timeout="2000"
        top
        right
        elevation="24"
        class="mt-5"
    >
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import i18n from '@/plugins/i18n';
import SearchingUserDialog from "@/components/SearchingUserDialog";
import CreateNoteCredit from "@/components/CreateNoteCredit";
import moment from "moment";
import ImportCreditDialog from "@/components/ImportCreditDialog";

export default {
  data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10000,
    loading: true,

    totalItem: 0,
    totalMoney: 0,

    snackbar: false,
    message: '',
    color: '#0C8F4D',

    dialogUpdateNote: false,
    dialogImport: false,

    editedItem: {},
    dialogSearching: false,

    numberPage: [
      50, 100, 300, 500, 1000, 5000, 10000
    ],
    dateRange: {start: new Date(), end: new Date()},
    startDate: new Date(),
    endDate: new Date(),
    search: '',
    dialog: false,
    headers: [
      { text: '#', align: 'left', sortable: false, value: 'index', },
      { text: i18n.t('credit_management.create_date'), value: 'time' },
      { text: i18n.t('credit_management.bank'), value: 'bank' },
      { text: i18n.t('credit_management.content'), value: 'content' },
      { text: i18n.t('credit_management.payment'), value: 'payment' },
      { text: i18n.t('credit_management.user'), value: 'user' },
      { text: i18n.t('credit_management.note'), value: 'note' },
      { text: '', value: 'action', sortable: false }
    ],

    desserts: [],
    stringRules: [
      v => !!v || i18n.t('validate.not_empty'),
      // v => (v && v.length > 5) || i18n.t('validate.string_invalid')
    ]
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? i18n.t('common.add') : i18n.t('common.edit')
    },
  },

  components: {
    ImportCreditDialog: ImportCreditDialog,
    SearchingUserDialog: SearchingUserDialog,
    CreateNoteCredit: CreateNoteCredit
  },

  watch: {
    page() {
      this.page = 1;
      this.getData();
    },
  },

  async created () {
    await this.getData()
  },

  methods: {
    async getData() {
      try {
        const from = this.$formatStartUTCDate(this.startDate);
        const to = this.$formatEndUTCDate(this.endDate);

        this.loading = true;
        const response = await this.$getAllCreditAdmin(this.page - 1, this.itemsPerPage, from, to);
        this.loading = false;

        if (response.status === 200) {
          this.desserts = [].concat(response.data.content);
          this.pageCount = response.data.totalPages;
          this.totalItem = response.data.totalElements;
          this.totalMoney = response.data.totalPayment;

          this.desserts.forEach(
              (item, index) => {
                item.index = (this.page - 1) * this.itemsPerPage + index + 1;
              }
          );
        }
      } catch (e) {
        console.log(e.stack);
      }
    },

    findingUser(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogSearching = true;
    },

    closeSearchingDialog(value) {
      this.dialogSearching = value;
      this.editedItem = null;
    },

    editItem(event, {item}) {
      this.editedItem = Object.assign({}, item);
      this.dialogUpdateNote = true;
    },

    closeDialogUpdateNote(value) {
      this.dialogUpdateNote = value;
      this.editedItem = null;
    },

    showImportDialog() {
      this.dialogImport = true;
    },

    closeImportDialog(value) {
      this.dialogImport = value;
    },

    async updateData() {
      this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
      this.message = 'Cập nhật thành công!'
      this.snackbar = true;

      await this.getData();
    },

    async cancelCredit(item) {
      const response = await this.$cancelCredit(item.id);
      if (response.status === 200) {
        this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
        this.message = 'Cập nhật thành công!'
        this.snackbar = true;
      } else {
        this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
        this.message = 'Cập nhật không thành công!'
        this.snackbar = true;
      }

      await this.getData();
    }
  },
}
</script>