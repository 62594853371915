import Vue from 'vue';
import Vuex from 'vuex';

//Modules
import language from './modules/language';
import login from './modules/login';
import actions from './actions';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    language,
    login,
  },
  actions: actions
});

export default store;