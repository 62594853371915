<template>
  <div>
    <v-dialog v-model="value" persistent max-width="80%">
      <v-card class="elevation-16 mx-auto">
        <v-card-title>
          <span class="header-title">{{ $t('transaction_management.title') }}</span>
        </v-card-title>

        <v-card-text>
          <v-data-table :headers="headers" :items="desserts" :search="search" class="elevation-1" hide-default-footer
                        :page.sync="page" :loading="loading" :items-per-page="itemsPerPage"
                        @page-count="pageCount = $event">
            <template v-slot:[`item.create_date`]="{ item }">
              <span class="text-no-wrap">{{ item.create_date | formatDate }}</span>
            </template>

            <template v-slot:[`item.payment`]="{ item }">
              <span class="text-no-wrap">{{ $thousandSeprator(item.payment)  }}</span>
            </template>

            <template v-slot:no-data>
              <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
            </template>
          </v-data-table>

          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
          </div>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions class="justify-space-between">
          <v-spacer></v-spacer>
          <v-btn color="primary" dark @click="closeDialog">{{ $t('common.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n';
// import moment from 'moment';

export default {
  name: 'event-log',

  props: {
    value: Boolean,
    editedItem: Object
  },


  data: () => ({
    loading: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,

    search: '',

    headers: [
      {text: '#', align: 'left', sortable: false, value: 'index',},
      {text: i18n.t('transaction_management.create_date'), sortable: false, value: 'create_date'},
      {text: i18n.t('transaction_management.money'), sortable: false, value: 'payment'},
      {text: i18n.t('transaction_management.content'), sortable: false, value: 'content'}
    ],
    desserts: [],
  }),

  computed: {},

  watch: {},

  mounted() {

  },

  async created() {
    this.getLog();
  },


  methods: {
    getLog() {
      try {
        this.desserts = this.editedItem.transactionResponseList;
        this.desserts.forEach((item, index) => item.index = index + 1);
      } catch (e) {
        console.log(e.stack);
      }
    },

    closeDialog() {
      this.$emit('closeDialog', false);
    }
  }
}
</script>

<style scoped>
.v-text-field .v-input__control .v-input__slot {
  min-height: 45px !important;
  /* display: flex !important; */
  align-items: center !important;
}
</style>
