<template>
  <v-container fluid>
    <div class="mt-5 mb-5">
      <div class="d-flex flex-row-reverse">
        <!--        <v-btn text class="flag-icon-background flag-icon-vn" @click="changeLocaleVN()">-->
        <!--        </v-btn>-->

        <!--        <v-btn text class="flag-icon-background flag-icon-gb" @click="changeLocaleEN()">-->
        <!--        </v-btn>-->
      </div>

    </div>

    <v-row align="center" justify="center" class="mt-5">
      <v-col cols="12" sm="6" xl="4">
        <v-card class="elevation-12">
          <div class="header">
            <p class="text-center"> {{ $t('register.title') }} </p>
          </div>

          <v-card-text class="px-10">
            <v-form ref="form" v-model="valid" lazy-validation @keyup.enter.native="register">
              <v-text-field v-model="username" :rules="usernameRules"
                            @keydown.space.prevent
                            v-bind:label="$t('register.username')" outlined name="username"
                            type="text" required/>


<!--              <v-text-field v-model="phone" :rules="phoneRules" v-bind:label="$t('register.phone')" outlined name="username"-->
<!--                           type="text" required/>-->

              <v-text-field v-model="password" :append-icon="show ? 'visibility' : 'visibility_off'" :rules="passRules"
                            v-bind:label="$t('register.password')" outlined id="password" name="password"
                            :type="show ? 'text' : 'password'" required @click:append="show = !show"
              />

              <v-text-field v-model="confirmPassword" :error-messages="vertifyPassword" :append-icon="show ? 'visibility' : 'visibility_off'" :rules="passRules"
                            v-bind:label="$t('register.re_password')" outlined id="password" name="password"
                            :type="show ? 'text' : 'password'" required @click:append="show = !show"
              />

              <v-text-field v-model="displayName" :rules="displayNameRules"
                            label="6 số cuối số điện thoại"
                            outlined name="username"
                            oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            type = "number"
                            maxlength = "6" required/>
              <span>Yêu cầu nhập chính xác để admin cấp lại mật khẩu khi quên tài khoản!</span>
            </v-form>
            <vue-recaptcha
                sitekey="6Lf5i4IlAAAAABqj4OMOf7qTD0mUSSrU3h6djmOa"
                ref="recaptcha"
                @verify="onCaptchaVerified"
                @expired="onCaptchaExpired"
            >
            </vue-recaptcha>
          </v-card-text>

          <v-container class="px-10 pb-10" fluid>
            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-btn large block color="primary" height="60px" class="normal-button" @click="register" :disabled="!isCapChaValid">{{ $t('register.title') }}</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>


    <v-overlay :value="overlay" :z-index="0">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
        v-model="snackbar"
        :color="color"
        :timeout="2000"
        top
        right
        elevation="24"
        class="mt-5"
    >
      {{ message }}
    </v-snackbar>
  </v-container>

</template>

<script>
import i18n from '@/plugins/i18n';
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  data: () => ({
    show: false,
    valid: true,
    overlay: false,
    isCapChaValid: false,

    snackbar: false,
    message: '',
    color: '#0C8F4D',
    recaptchaToken: '',
    password: '',
    confirmPassword: '',
    displayName: '',
    phone: '',
    passRules: [
      v => !!v || i18n.t('validate.password_require'),
      v => (v && v.length > 3) || i18n.t('validate.password_invalid'),
    ],
    usernameRules: [
      v => !!v || i18n.t('validate.username_require'),
      v => /^[A-Za-z0-9]+$/.test(v) || i18n.t('validate.username_invalid')
    ],
    displayNameRules: [
      v => !!v || i18n.t('validate.string_require'),
      v => (v && v.length === 6) || i18n.t('validate.string_invalid'),
    ],
    phoneRules: [
      (v) => !!v || i18n.t("validate.not_empty"),
      (v) => (v && v.length > 9) || i18n.t("validate.phone_invalid"),
    ],
    username: '',
  }),

  components: {
    VueRecaptcha
  },

  computed: {
    vertifyPassword() {
      return this.password === this.confirmPassword ? '' : i18n.t('validate.not_match');
    }
  },

  created() {

  },

  methods: {
    onCaptchaVerified: function (recaptchaToken) {
      this.isCapChaValid  = true;
      this.recaptchaToken = recaptchaToken;
    },

    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },

    register: async function () {
      this.$refs.form.validate();

      if (!this.username || this.username.length == 0) {
        return;
      }

      if (!/^[A-Za-z0-9]+$/.test(this.username)) {
        return;
      }

      if (!this.displayName || this.displayName.length !== 6) {
        return;
      }

      if (!this.password || this.password.length == 0) {
        return;
      }

      if (!this.confirmPassword || this.confirmPassword.length == 0) {
        return;
      }

      if (this.password !== this.confirmPassword) {
        return;
      }

      this.overlay = true;

      const self = this;
      const requestBody = {
        username: self.username.toLowerCase(),
        password: self.password,
        full_name: self.displayName,
        phone: self.phone,
        token: self.recaptchaToken
      };

      try {
        const response =  await this.$register(requestBody);
        this.overlay = false;
        if (response.data && response.status == process.env.VUE_APP_SUCCESS_STATUS) {
          this.$fire({
            title:  i18n.t('register.success_register'),
            text: "",
            type: "success"
          }).then(() => {
            this.$router.push('/login');
            // this.login();
          });

        } else if (response.data && !response.data.success) {
          this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
          this.message = i18n.t('register.error_register')
          this.snackbar = true;
        } else {
          alert(i18n.t('validate.request_error'));
        }
      } catch(e) {
        console.log(e.stack);
        alert(i18n.t('validate.request_error'));
      }
    },

    async login() {
      const self = this;
      const requestBody = {
        username: self.username,
        password: self.password,
        token: self.recaptchaToken
      };

      try {
        const response =  await this.$login(requestBody);
        console.log(response);
        this.overlay = false;
        if (response.status == process.env.VUE_APP_SUCCESS_STATUS) {
          // console.log(response.data);
          localStorage.setItem('access_token', response.data.token);
          localStorage.setItem("username", response.data.username);
          localStorage.setItem("userId", response.data.id);
          localStorage.setItem("role_id", response.data.role_id);
          localStorage.setItem("code", response.data.code);

          return await this.$router.push('/');
        } else {
          this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
          this.message = i18n.t('validate.request_error')
          this.snackbar = true;
        }
      } catch(e) {
        alert(i18n.t('validate.request_error'));
      }
    },

    validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true
      }
    },

    reset() {
      this.$refs.form.reset()
    },

    resetValidation() {
      this.$refs.form.resetValidation()
    },
  }
}
</script>

<style scoped>
.header {
  height: 60px;
}

p {
  color: #1D243E;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  padding-top: 20px;
}

.small-button {
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  color: #929AAE;
}

.normal-button {
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
}
</style>
