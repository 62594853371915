<template>
    <div class="wrapper d-flex flex-row align-center">
        <v-avatar size="64">
            <img v-if="data.service.image" :src="data.service.image"/>
            <img v-else src='@/assets/no-photo.png'/>
        </v-avatar>
        <div style="width: calc(100% - 76px)">
            <div class="d-flex justify-space-between" style="width: 100%">
                <div class="font-weight-bold" style="font-size:17px">{{ data.service.name }}</div>
                <div :class='$statusOTP(data) + " status-container"'>
                    <span>{{ $OTPStatusString(data.status) }}</span>
                </div>
            </div>
            <div class="wrap-text">{{ $t('buy_code_management.create_date') }}: {{
                data.create_date | formatDate
                }}
            </div>
            <div class="wrap-text">{{ $t('buy_code_management.price') }}: {{ $thousandSeprator(data.payment) }}</div>
            <div class="wrap-text">{{ $t('buy_code_management.phone') }}: {{ data.sim.phone }}
              <v-icon color="primary" small class="ml-2 mr-2" @click="copyClipboardInDialog(data.sim.phone)"
                      v-if="data.sim.phone">
                file_copy
              </v-icon>
            </div>
            <div class="wrap-text">{{ $t('buy_code_management.code') }}: <span class="font-weight-bold">{{
                data.otp
                }}</span>
              <v-icon color="primary" small class="ml-2 mr-2" @click="copyClipboardInDialog(data.otp)"
                      v-if="data.otp">
                file_copy
              </v-icon>
            </div>
            <div class="d-flex justify-end" style="column-gap: 12px">
                <v-btn v-if="data.status === 1 && data.type !== 'audio'" color="primary" height="25px"
                       style="font-size:12px" @click="showDetailDialog(data)">Chi tiết
                </v-btn>
                <vuetify-audio v-if="data.status === 1 && data.type === 'audio'" :file="data.audioMp3"
                               color="primary"/>
                <v-btn v-if="data.status === 1" color="primary" height="25px" style="font-size:12px" @click="renewSMS(data)">Mua lại</v-btn>
                <v-btn v-if="data.status === 2" dark color="cancel" height="25px" style="font-size:12px" @click="cancelSMS(data)">
                    {{ $t('common.cancel') }}
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InfoItem",
    props: {
        data: {
            default: {},
            type: Object
        },
        showDetailDialog: Function,
        cancelSMS: Function,
        renewSMS: Function,
        copyClipboardInDialog: Function,
    },
}
</script>

<style scoped>
.wrapper {
    height: 150px;
    column-gap: 12px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 12px;
}

.wrap-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.status-container {
    font-size: 12px;
    margin: 0;
    height: 19px;
    display: flex;
    align-items: center
}
</style>