<template>
  <div>
    <v-dialog v-model="value" persistent max-width="90%">
      <v-card class="elevation-16 mx-auto">
        <v-card-title class="header-title" primary-title>
          <span>Nhập liệu</span>
          <v-spacer></v-spacer>
          <v-icon @click="closeDialog()">close</v-icon>
        </v-card-title>

        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="desserts"
              :search="search"
              :loading="loading"
              sort-by="email"
              class="elevation-1"
              hide-default-footer
              :page.sync="page"
              :items-per-page="itemsPerPage"
              @page-count="pageCount = $event">
            <template v-slot:top>
              <v-toolbar flat color="white" class="mb-2">
                <v-row justify="start">
                  <v-col cols="12" sm="6" justify="center">
                    <v-file-input
                        v-model="chosenFile"
                        outlined
                        label="File input"
                        dense
                        show-size
                        class="mt-2"
                        @change="previewFiles"
                    ></v-file-input>
                  </v-col>

                  <v-col cols="12" sm="6" justify="center">
                    <v-btn color="primary" dark @click="createPreview" width="80" class="mt-2">{{ $t('common.save') }}</v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
              <div>
              <span class="defaultFont">Tổng giao dịch: <span class="defaultBoldFont"> {{ $thousandSeprator(totalItem) }}</span>.
              Tổng tiền: <span class="defaultBoldFont"> {{ $thousandSeprator(totalMoney) }} </span> </span>

                <!--          <span>Tổng giao dịch: <span class="statisticFont"> {{ $thousandSeprator(totalItem) }}</span>. Tổng tiền: <span class="statisticFont"> {{ $thousandSeprator(totalMoney) }} </span> </span>-->
              </div>
            </template>

            <template v-slot:[`item.payment`]="{ item }">
              <span class="text-no-wrap">{{ $thousandSeprator(item.payment) }}</span>
            </template>

            <template v-slot:[`item.create_date`]="{ item }">
              <span class="text-no-wrap">{{ item.create_date | formatDate }}</span>
            </template>

            <template v-slot:no-data>
              <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
            </template>
          </v-data-table>

          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        :color="color"
        :timeout="2000"
        top
        right
        elevation="24"
        class="mt-5"
    >
      {{ message }}
    </v-snackbar>
  </div>
</template>
<script>
import i18n from '@/plugins/i18n';
import moment from 'moment';
import * as XLSX from "xlsx";

export default {
  name: 'import-credit-dialog',

  props: {
    value: Boolean
  },

  data: () => ({
    loading: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,

    totalItem: 0,
    totalMoney: 0,
    chosenFile: null,
    search: '',

    snackbar: false,
    message: '',
    color: '#0C8F4D',

    headers: [
      { text: '#', align: 'left', sortable: false, value: 'index', },
      { text: i18n.t('top_up_management.create_date'), value: 'time' },
      { text: i18n.t('credit_management.bank'), value: 'bank' },
      { text: i18n.t('top_up_management.content'), value: 'content' },
      { text: i18n.t('top_up_management.payment'), value: 'payment' },
      // { text: i18n.t('credit_management.user'), value: 'user_id' },
      // { text: '', value: 'action', sortable: false }
    ],

    desserts: [],
  }),

  computed: {},

  watch: {},

  mounted() {

  },

  created() {

  },


  methods: {
    closeDialog() {
      this.$emit('closeDialog', false);
    },

    async createPreview() {
      let currentTime = new Date()
      for (const item of  this.desserts) {
        currentTime = new Date(currentTime.getTime() - 2 * 1000)
        item.time = this.$formatUTCDate(currentTime);

        const response = await this.$createCredit(item);
        if (response.status == 200) {
          this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
          this.message = 'Cập nhật thành công ' + item.content;
          this.snackbar = true;
        }
      }
      this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
      this.message = 'Cập nhật thành công';
      this.snackbar = true;
    },

    previewFiles() {
      const reader = new FileReader();
      reader.onload = () => {
        const data = reader.result;
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0]
        let worksheet = workbook.Sheets[sheetName];
        this.desserts = XLSX.utils.sheet_to_json(worksheet);

        this.totalItem = this.desserts.length;

        this.totalMoney = 0;
        const regex = /MM[A-Z0-9]{8}$/gm;
        this.desserts.forEach((item) => {
            this.totalMoney += item.payment;
            const subString = item.content.split(' ');
            subString.forEach((sub)=> {
              const matches = regex.exec(sub.trim());
              if (matches) {
                item.content = matches[0];
              }
            })
          }
        );
      }
      reader.readAsArrayBuffer(this.chosenFile)
    }
  }
}
</script>

<style scoped>
.v-text-field .v-input__control .v-input__slot {
  min-height: 45px !important;
  /* display: flex !important; */
  align-items: center !important;
}
</style>
