<template>
    <v-container
            class="container-class" fluid @scroll="onScrollList"
            style="overflow: auto; height: calc(100vh - 56px)"
    >
        <div>
            <p class="header-title"> {{ $t('top_up_management.information') }} </p>
        </div>
        <!--        note on mobile-->
        <NoteTopUpMobile
                v-if="isMobile"
                :bankingAccount="bankingAccount"
                :bankingName="bankingName"
                :account="account"
                :formatTopUp="formatTopUp"
                :minimumPrice="minimumPrice.toString()"
                :copyClipboard="copyClipboard"
        />
        <!--        note on desktop-->
        <v-card v-else class="mb-10">
            <v-card-text class="px-2">
                <v-row>
                    <v-col cols="12" sm="8">
                        <span class="bank-title"> {{ banking }} </span> <br/><br/>
                        <span> Số TK: <span class="bold-title"> {{ bankingAccount }} </span>
              <v-icon small class="ml-2" @click="copyClipboard(bankingAccount)">
                content_copy
              </v-icon>
            </span> <br/>
                      <span> Ngân hàng: <span class="bold-title"> {{ bankingName }} </span> </span> <br/>
                        <span> Tên chủ TK: <span class="bold-title"> {{ account }} </span> </span> <br/>
                        <span> Nội dung chuyển khoản: <span class="bold-title"> {{ formatTopUp }} </span>
              <v-icon small class="ml-2" @click="copyClipboard(formatTopUp)">
                content_copy
              </v-icon>
            </span> <br/>
                        <span> Lưu ý: </span> <br/>
                        <span> * Khi chuyển khoản quý khách vui lòng ghi đúng nội dung ck mà web cung cấp để auto nạp số dư. </span>
                        <br/>
                        <span> * Nếu quý khách ghi nhầm nội dung ck vui lòng chụp ảnh bill chi tiết gửi vào zalo hỗ trợ kèm theo nội dung ck đúng. </span>
                        <br/>
                        <span> * Số tiền nạp tối thiểu là {{ $thousandSeprator(minimumPrice) }}đ</span> <br/>
                        <span> * Quý khách ghi đúng thông tin nạp tiền thì tài khoản sẽ được cộng tự động sau khi giao dịch thành công. </span>
                        <br/>
                        <span class="notice-title"> * Hệ thống không chịu trách nhiệm nếu nội dung nạp không đúng. </span>
                        <br/>
                    </v-col>

                    <v-col cols="12" sm="4" class="justify-center">
                        <div style="height: 100%;">
                            <v-img width="200px" height="200px" contain :src="qrcodePath"
                                   style="margin-left: auto;margin-right: auto;">
                            </v-img>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <!--        filter and table on desktop-->
        <template v-if="!isMobile">
            <div>
                <v-row justify="space-between">
                    <v-col cols="12" sm="6" justify="center">
                        <p class="header-title"> {{ $t('credit_management.title') }} </p>
                    </v-col>
                    <v-col cols="12" sm="6" justify="center">
                        <div class="d-flex flex-row-reverse">
                            <v-btn color="primary" dark @click="onSearch" class="ml-4">{{
                                $t("common.search")
                                }}
                            </v-btn>

                            <vc-date-picker v-model="endDate" :popover="{label: 'Hover', visibility: 'hover'}"
                                            style="width: 120px;" class="ml-4">
                            </vc-date-picker>
                            <span class="mt-2 ml-2">đến</span>
                            <vc-date-picker v-model="startDate" :popover="{label: 'Hover', visibility: 'hover'}"
                                            style="width: 120px;" class="ml-4">
                            </vc-date-picker>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <v-data-table
                    :headers="headers"
                    :items="desserts"
                    :search="search"
                    :loading="loading"
                    sort-by="email"
                    class="elevation-1"
                    hide-default-footer

                    :items-per-page="itemsPerPage">
                <template v-slot:top>

                    <v-toolbar flat color="white" class="pt-5 mb-2">
                        <v-row justify="start">
                            <v-col cols="12" sm="2">
                                <v-autocomplete v-model="itemsPerPage" :items="numberPage" outlined
                                                :dense="true"></v-autocomplete>
                            </v-col>

                            <v-col cols="12" sm="5">

                            </v-col>

                            <v-col cols="12" sm="5">
                                <v-text-field v-model="search" append-icon="search" v-bind:label="$t('common.search')"
                                              single-line hide-details outlined :dense="true"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-toolbar>
                    <div>
          <span class="defaultFont">Tổng giao dịch: <span class="defaultBoldFont"> {{
              $thousandSeprator(totalItem)
              }}</span>.
            Tổng tiền: <span class="defaultBoldFont"> {{ $thousandSeprator(totalMoney) }} </span> </span>

                        <!--          <span>Tổng giao dịch: <span class="statisticFont"> {{ $thousandSeprator(totalItem) }}</span>. Tổng tiền: <span class="statisticFont"> {{ $thousandSeprator(totalMoney) }} </span> </span>-->
                    </div>
                </template>

                <template v-slot:[`item.payment`]="{ item }">
                    <span class="text-no-wrap">{{ $thousandSeprator(item.payment) }}</span>
                </template>

                <template v-slot:[`item.create_date`]="{ item }">
                    <span class="text-no-wrap">{{ item.create_date | formatDate }}</span>
                </template>


                <template v-slot:[`item.status`]="{ item }">
                    <span :class="$statusCredit(item)">{{ $creditStatusString(item.status) }}</span>
                </template>


                <template v-slot:no-data>
                    <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
                </template>
            </v-data-table>
            <div class="text-center pt-2">
                <v-pagination v-model="page" :length="pageCount"></v-pagination>
            </div>
        </template>
        <!--        filter and list on mobile-->
        <template v-if="isMobile">
            <!--            filter-->
            <v-row class="mt-4">
                <v-col cols="12">
                    <div class="d-flex align-center" style="column-gap: 8px">
                        <v-dialog
                                ref="dialog"
                                v-model="modalDatePicker"
                                :return-value.sync="dateRange"
                                persistent
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        background-color="#FFFFFF"
                                        v-model="rangeTimeText"
                                        label="Khoảng thời gian"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        :dense="true"
                                        hide-details
                                        height="48px"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                    v-model="dateRange"
                                    scrollable
                                    range
                                    locale="vi"
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                        text
                                        color="primary"
                                        @click="modalDatePicker = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.dialog.save(dateRange)"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </div>
                </v-col>
                <v-col cols="12">
                    <v-btn color="primary" @click="onSearch" width="100%" height="48px" style="font-size:17px">
                        {{ $t("common.search") }}
                    </v-btn>
                </v-col>
            </v-row>
            <!--            list item-->
            <div class="mt-3 mb-2" style="font-size:17px">Tổng giao dịch: {{ totalItem }}</div>
            <HistoryItem
                    v-for="(item,index) in desserts"
                    :key="item.id" :data="item"
            />
        </template>

        <v-snackbar
                v-model="snackbar"
                :color="color"
                :timeout="2000"
                top
                right
                elevation="24"
                class="mt-5"
        >
            {{ message }}
        </v-snackbar>
    </v-container>
</template>

<script>
import i18n from '@/plugins/i18n';
import {isMobileBrowser} from "@/utils/Device";
import NoteTopUpMobile from "@/pages/NoteTopUpMobile.vue";
import HistoryItem from "@/components/HistoryItem.vue";
import InfoItem from "@/components/InfoItem.vue";
import moment from "moment/moment";

export default {
    components: {InfoItem, HistoryItem, NoteTopUpMobile},
    data: () => ({
        qrcodePath: null,
        page: 1,
        pageCount: 0,
        itemsPerPage: 50,
        loading: false,
        startDate: new Date(),
        endDate: new Date(),
        numberPage: [
            50, 100, 300, 500, 1000
        ],

        totalItem: 0,
        totalMoney: 0,

        snackbar: false,
        message: '',
        color: '#0C8F4D',

        search: '',
        dialog: false,
        headers: [
            {text: '#', align: 'left', sortable: false, value: 'index',},
            {text: i18n.t('top_up_management.create_date'), value: 'create_date'},
            {text: i18n.t('credit_management.bank'), value: 'bank'},
            // { text: i18n.t('top_up_management.content'), value: 'content' },
            {text: i18n.t('top_up_management.payment'), value: 'payment'},

            {text: i18n.t('top_up_management.status'), value: 'status'}
            // { text: i18n.t('credit_management.user'), value: 'user_id' },
            // { text: '', value: 'action', sortable: false }
        ],

        desserts: [],
        stringRules: [
            v => !!v || i18n.t('validate.not_empty'),
            // v => (v && v.length > 5) || i18n.t('validate.string_invalid')
        ],

        bankingName: '',
        banking: '',
        account: '',
        bankingAccount: '',
        formatTopUp: '',
        minimumPrice: 50000,
        qrcode: {},
        dateRange: null,
        modalDatePicker: false
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? i18n.t('common.add') : i18n.t('common.edit')
        },
        isMobile() {
            return isMobileBrowser()
        },
        rangeTimeText() {
            if (this.dateRange?.length !== 2) return ''
            const startDateTxt = moment(this.dateRange[0], "YYYY-MM-DD").format("MM/DD/YYYY")
            const endDateTxt = moment(this.dateRange[1], "YYYY-MM-DD").format("MM/DD/YYYY")
            return `${startDateTxt} ~ ${endDateTxt}`
        }
    },

    watch: {
        itemsPerPage() {
            this.page = 1;
            this.getData();
        },

        page() {
            this.getData(!this.isMobile);
        },
        dateRange(val) {
            if (val?.length !== 2) return
            const format = "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
            this.startDate = moment(val[0], "YYYY-MM-DD").startOf("day").format(format)
            this.endDate = moment(val[1], "YYYY-MM-DD").endOf("day").format(format)
        }
    },

    async created() {
        await this.getSettingInfor();
        await this.getData()
    },

    methods: {
        getDataByDate() {
            this.page = 1;
            this.getData();
        },

        async getData(resetData = true) {
            try {
                const from = this.$formatStartUTCDate(this.startDate);
                const to = this.$formatEndUTCDate(this.endDate);

                this.loading = true;
                const response = await this.$getAllCredit(this.page - 1, this.itemsPerPage, from, to);
                this.loading = false;

                if (response.status === 200) {
                    if (resetData || this.page === 1) {
                        this.desserts = [].concat(response.data.content);
                    } else {
                        this.desserts = [...this.desserts, ...response.data.content];
                    }
                    this.pageCount = response.data.totalPages;
                    this.totalItem = response.data.totalElements;
                    this.totalMoney = response.data.totalPayment;
                    this.desserts.forEach(
                        (item, index) => {
                            item.index = (this.page - 1) * this.itemsPerPage + index + 1;
                        }
                    );
                }
            } catch (e) {
                console.log(e.stack);
            }
        },

        copyClipboard(value) {
            const self = this;
            this.$copyText(value).then(function () {
                self.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
                self.message = 'Copy thành công ' + value;
                self.snackbar = true;
            }, function () {
                self.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
                self.message = 'Copy không thành công ' + value;
                self.snackbar = true;
            })
        },

        async getSettingInfor() {
            try {
                const response = await this.$getAllConfig();
                if (response.status === 200) {
                    const contextArr = [].concat(response.data);
                    contextArr.forEach(item => {
                        if (item.key == 'BankingAccount') {
                            this.bankingAccount = item.value;
                        } else if (item.key == 'banking') {
                            this.banking = item.value;
                        } else if (item.key == 'Account') {
                            this.account = item.value;
                        } else if (item.key == 'FormatTopUp') {
                            this.formatTopUp = item.value + localStorage.getItem("code");
                        } else if (item.key == 'MinimumPrice') {
                            this.minimumPrice = item.value;
                        } else if (item.key == 'QRCode') {
                            this.qrcode.base64 = item.value;
                        } else if (item.key == 'BankingName') {
                          this.bankingName = item.value;
                        }
                    });

                    this.qrcodePath = this.generateQRCode(this.bankingName, this.bankingAccount, this.formatTopUp, this.account);
                } else if (response.data) {
                    alert(i18n.t(response.data));
                } else {
                    alert(i18n.t('validate.request_error'));
                }
            } catch (e) {
                alert(i18n.t('validate.request_error'));
            }
        },
        onScrollList() {
            const container = document.querySelector('.container-class');
            const scrollPosition = container.scrollTop + container.offsetHeight;
            const totalHeight = container.scrollHeight;
            if (scrollPosition >= totalHeight && this.page < this.pageCount && !this.loading) {
                this.page += 1
            }
        },
        onSearch() {
            if (this.page === 1) {
                this.getDataByDate()
            } else {
                this.page = 1
            }
        },

        generateQRCode(bankName, bankingAccount, content, account) {
          const acc = account.replace(' ', '%20');
          return `https://img.vietqr.io/image/${bankName}-${bankingAccount}-compact.jpg?addInfo=${content}&accountName=${acc}`;
        }
    },
}
</script>

<style scoped>
.bank-title {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
}

.bold-title {
    font-style: normal;
    font-weight: bold;
}

.notice-title {
    color: #FF0000;
    font-style: normal;
    font-weight: bold;
}

</style>
