<template>
  <v-container>
    <div>
      <v-row >
        <v-col cols="12" sm="12">
          <p class="header-title">{{ $t('changePass.title') }} </p>
        </v-col>
      </v-row >
    </div>

    <v-row>
      <!--      <v-form ref="form" v-model="valid" lazy-validation @keyup.enter.native="updateInformation">-->
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-text-field background-color="#ffffff" v-model="oldPassword" :append-icon="showOld ? 'visibility' : 'visibility_off'" :rules="passdRules" v-bind:label="$t('changePass.old_pass')"  outlined :type="showOld ? 'text' : 'password'" required @click:append="showOld = !showOld"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-text-field background-color="#ffffff" v-model="password" :append-icon="showNew ? 'visibility' : 'visibility_off'" :rules="passdRules" v-bind:label="$t('changePass.new_pass')" outlined :type="showNew ? 'text' : 'password'" required @click:append="showNew = !showNew"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-text-field background-color="#ffffff" v-model="confirmPassword" :error-messages="vertifyPassword" :append-icon="showConfirm ? 'visibility' : 'visibility_off'" :rules="passdRules" v-bind:label="$t('changePass.repeat_new_pass')"  outlined :type="showConfirm ? 'text' : 'password'" required @click:append="showConfirm = !showConfirm"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="2" background-color="#ffffff">
            <v-btn large block color="primary" class="normal-button" @click="changePassword"> {{ $t('changePass.btn_title') }} </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <!--      </v-form>-->
    </v-row>


    <v-overlay :value="overlay" :z-index="0">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
        v-model="snackbar"
        :color="color"
        :timeout="2000"
        top
        right
        elevation="24"
        class="mt-5"
    >
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import i18n from '@/plugins/i18n';

export default {
    data: () => ({
      overlay: false,
      formValid: false,
      showOld: false,
      showNew: false,
      showConfirm: false,

      snackbar: false,
      message: '',
      color: '#0C8F4D',

      valid: true,
      oldPassword: '',
      password: '',
      confirmPassword: '',
      passdRules: [
          v => !!v || i18n.t('validate.password_require'),
          v => (v && v.length > 3) || i18n.t('validate.password_invalid'),
      ],
    }),

    computed: {
        vertifyPassword() {
            return this.password === this.confirmPassword ? '' : i18n.t('validate.not_match');
        }
    },

    methods: {
      changePassword: async function() {
        const self = this;
        if (!self.oldPassword|| self.oldPassword.length == 0) {
          return;
        }

        if (!self.password || self.password.length == 0) {
          return;
        }

        if (!self.confirmPassword || self.confirmPassword.length == 0) {
          return;
        }

        const requestBody = {
          password_old: self.oldPassword,
          password_new: self.password
        };

        try {
          const response =  await this.$changePassword(requestBody)
          if (response.status === 200) {
            this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
            this.message = i18n.t('common.update_success');
            this.snackbar = true;

            const response =  await this.$logout();
            if (response.status == process.env.VUE_APP_SUCCESS_STATUS) {
              localStorage.removeItem('access_token');
              localStorage.removeItem('username');
              localStorage.removeItem('api_key');
              localStorage.removeItem('balance');
              localStorage.removeItem('userId');

              await this.$router.push('login');
            }
          } else if (response.data) {
            this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
            this.message = i18n.t('Mật khẩu cũ không đúng!')
            this.snackbar = true;
          } else {
            this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
            this.message = i18n.t('validate.request_error')
            this.snackbar = true;
          }
        } catch(e) {
          console.log(e.stack);
          // alert(e.message);
          alert(i18n.t('validate.request_error'));
        }
      }
    }

}
</script>

<style scoped>
p {
    color: #1D243E;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    padding-top: 20px;
}
</style>