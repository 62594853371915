import Vue from "vue";

export default {
    install() {
        Vue.prototype.$getAllSIM = async function (phone, dealerId, status, page, limit) {
            let result = null;
            let URL = process.env.VUE_APP_GET_ALL_SIM + "?sort=desc" + "&limit=" + limit + "&page=" + page;
            if (dealerId !== -1) {
                URL = URL + "&dealer_id=" + dealerId
            }

            if (phone.length > 0) {
                URL = URL + "&phone=" + phone
            }

            if (status !== -1) {
                URL = URL + "&status_sim=" + status
            }

            try {
                const response = await this.$http.get(URL);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$getAllSIMAgency = async function (agencyId, page, limit) {
            let result = null;
            try {
                const response = await this.$http.get(process.env.VUE_APP_GET_ALL_SIM + "?sort=desc&limit=" + limit + "&page=" + page + "&dealer_id=" + agencyId);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$getSIMCustomer = async function (service_id, network_id, phone) {
            let result = null;
            let URL = process.env.VUE_APP_GET_CODE + "?service_id=" + service_id;

            if (network_id !== -1) {
                URL = URL + "&network_id=" + network_id
            }

            if (phone.length > 0) {
                URL = URL + "&phone=" + phone
            }

            try {
                const response = await this.$http.get(URL);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$renewSim = async function (simId, service_id) {
            let result = null;

            try {
                const response = await this.$http.get(process.env.VUE_APP_RENEW_SIM + simId + '?service_id=' + service_id);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$reportSIM = async function (requestData) {

            let result = null;
            try {
                const response = await this.$http.post(process.env.VUE_APP_REPORT_SIM, JSON.stringify(requestData));
                if (response.data) {
                    result = response.data;
                }
                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$cancelSIM = async function (simId) {

            let result = null;
            try {
                const response = await this.$http.put(process.env.VUE_APP_CANCEL_SIM + simId);
                if (response.data) {
                    result = response.data;
                }
                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$statusSIM = function(item) {
            if (item.status == 0) {
                return 'otpError';
            } else {
                return 'otpSuccess';
            }
        };
    },
};