
import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
// import store from '../store';
// import i18n from '../plugins/i18n';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  // if (store.state.language.language && store.state.language.language !== i18n.locale) {
  //   i18n.locale = store.state.language.language;
  //   next();
  // } else if (!store.state.language.language) {
  //   store.dispatch('language/setLanguage', 'vn')
  //     .then(() => {
  //       i18n.locale = store.state.language.language;
  //       next();
  //     });
  // } else {
  //   next();
  // }
  if (to.meta.requireLogin !== false) {
    let token = localStorage.getItem('access_token');
    if (token) {
      next();
    } else {
      router.push('login');
    }
  } else {
    next();
  }
});

export default router;