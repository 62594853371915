import Vue from "vue";
import i18n from "@/plugins/i18n";

export default {
    install () {
        Vue.prototype.$thousandSeprator = function(amount) {
            // return amount;

            if (amount === 0) {
                return amount;
            }

            if (!amount) {
                return 'Undefined';
            }

            if (amount !== '' || amount !== 0 || amount !== '0' || amount !== null) {
                return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return amount;
            }
        };

        Vue.prototype.$statusString = function(value) {
            if (value === 1) {
                return  i18n.t("common.active");
            } else if (value === 0) {
                return  i18n.t("common.lock");
            } else if (value === 2) {
                return  i18n.t("common.new_user");
            } else {
                return 'NA';
            }
        };

        Vue.prototype.$statusServiceString = function(value) {
            if (value === 1) {
                return  i18n.t("service_management.active");
            } else if (value === 0) {
                return  i18n.t("service_management.inactive");
            } else {
                return 'NA';
            }
        };

        Vue.prototype.$eventTypeString = function(value) {
            console.log(value);
            if (value === 1) {
                return  i18n.t("common.create");
            } else if (value === 2) {
                return  i18n.t("common.update");
            } else if (value === 3) {
                return  i18n.t("common.delete");
            } else {
                return 'NA';
            }
        };

        Vue.prototype.$roleString = function(value) {
            if (value === 1) {
                return  i18n.t("common.admin");
            } else if (value === 2) {
                return  i18n.t("common.staff");
            } else {
                return 'NA';
            }
        };

        Vue.prototype.$statusPaymentString = function(value) {
            if (value === 0) {
                return  i18n.t("common.not_payment");
            } else if (value === 1) {
                return  i18n.t("common.unfinish");
            } else if (value === 2) {
                return  i18n.t("common.finish");
            } else {
                return 'NA';
            }
        };

        Vue.prototype.$creditTypeString = function(value) {
            if (value === 1) {
                return  i18n.t("transaction.topUp");
            } else if (value === 4) {
                return  i18n.t("withdraw.title");
            } else {
                return 'NA';
            }
        };

        Vue.prototype.$transactionTypeString = function(value) {
            if (value === 2) {
                return  i18n.t("transaction.title");
            } else if (value === 3) {
                return  i18n.t("transaction.deduction");
            } else {
                return 'NA';
            }
        };

        Vue.prototype.$OTPStatusString = function(value) {
            if (value === 0) {
                return  i18n.t("buy_code_management.timeout");
            } else if (value === 1) {
                return  i18n.t("buy_code_management.success");
            } else if (value === 2) {
                return  i18n.t("buy_code_management.waiting");
            } else if (value === 3) {
                return  i18n.t("buy_code_management.cancel");
            } else if (value === 4) {
                return  i18n.t("buy_code_management.spam");
            } else {
                return 'NA';
            }
        };

        Vue.prototype.$creditStatusString = function(value) {
            if (value === 0) {
                return  i18n.t("top_up_management.cancel");
            } else if (value === 1) {
                return  i18n.t("top_up_management.success");
            } else {
                return 'NA';
            }
        };

        Vue.prototype.$simStatusString = function(value) {
            if (value === 0) {
                return  i18n.t("sim_management.inactive");
            } else if (value === 1) {
                return  i18n.t("sim_management.active");
            } else {
                return 'NA';
            }
        };
    },
};