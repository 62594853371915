import Vue from "vue";

export default {
    install() {
        Vue.prototype.$getAllOTP = async function (search, dealerId, serviceId, statusId, page, limit, from, to) {
            let result = null;
            let URL = process.env.VUE_APP_GET_ALL_OTP + "?sort=desc&limit=" + limit + "&page=" + page + "&from_date=" + from + "&to_date=" + to;
            if (serviceId !== -1) {
                URL = URL + "&service_id=" + serviceId
            }

            if (dealerId !== -1) {
                URL = URL + "&dealer_id=" + dealerId
            }

            if (statusId !== -1) {
                URL = URL + "&status=" + statusId
            }

            if (search !== null && search.length > 0) {
                URL = URL + "&search_text=" + search
            }

            try {
                const response = await this.$http.get(URL);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$getAllOTPNotSpam = async function (serviceId, statusId, page, limit, from, to) {
            let result = null;
            let URL = process.env.VUE_APP_GET_ALL_OTP + "?sort=desc&limit=" + limit + "&page=" + page + "&from_date=" + from + "&to_date=" + to;
            if (serviceId !== -1) {
                URL = URL + "&service_id=" + serviceId
            }

            if (statusId !== -1) {
                URL = URL + "&status=" + statusId
            }

            try {
                const response = await this.$http.get(URL);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$getAllOTPByUser = async function (user_id, serviceId, statusId, page, limit, from, to) {
            let result = null;
            let URL = process.env.VUE_APP_GET_ALL_OTP + "?sort=desc&limit=" + limit + "&page=" + page + "&from_date=" + from + "&to_date=" + to + "&user_id=" + user_id;
            if (serviceId !== -1) {
                URL = URL + "&service_id=" + serviceId
            }

            if (statusId !== -1) {
                URL = URL + "&status=" + statusId
            }

            try {
                const response = await this.$http.get(URL);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$getAllOTPToday = async function (page, limit) {
            let result = null;
            try {
                const from = this.$formatStartUTCDate(new Date());
                const to = this.$formatEndUTCDate(new Date());

                const response = await this.$http.get(process.env.VUE_APP_GET_ALL_OTP + "?sort=desc&limit=" + limit + "&page=" + page + "&from_date=" + from + "&to_date=" + to);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$calculateOTP = async function (search, dealerId, serviceId, from, to) {
            let result = null;
            let URL = process.env.VUE_APP_CALCULATE_OTP + "?sort=desc" + "&from_date=" + from + "&to_date=" + to;
            if (serviceId !== -1) {
                URL = URL + "&service_id=" + serviceId
            }

            if (dealerId !== -1) {
                URL = URL + "&dealer_id=" + dealerId
            }

            if (search !== null && search.length > 0) {
                URL = URL + "&search_text=" + search
            }

            try {
                const response = await this.$http.get(URL);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$calculateOTPUser = async function (userId, serviceId, from, to) {
            let result = null;
            let URL = process.env.VUE_APP_CALCULATE_OTP + "?sort=desc&user_id=" + userId + "&from_date=" + from + "&to_date=" + to;
            if (serviceId !== -1) {
                URL = URL + "&service_id=" + serviceId
            }

            try {
                const response = await this.$http.get(URL);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$statusOTP = function(item) {
            if (item.status == 0) {
                return 'otpError';
            } else if (item.status == 1) {
                return 'otpSuccess';
            } else if (item.status == 2) {
                return 'otpPending';
            } else if (item.status == 3) {
                return 'otpCancel';
            } else if (item.status == 4) {
                return 'otpSpam';
            } else {
                return 'otpError';
            }
        };
    },
};