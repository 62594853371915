<template>
  <v-container fluid>
    <div>
      <div class="d-flex justify-space-between">
        <p class="header-title"> {{$t('buy_code_management.otp_management')}} </p>
        <v-spacer/>
        <div class="d-flex flex-row-reverse">
          <div @click="showHistoryDialog()" style="background-color: #0C8F4D; width: auto; height: 40px; text-align: center; padding-right: 10px; padding-left: 10px; padding-top: 8px; border-radius: 4px">
            <span style="color: white; font-size: 12px">
              Đã thanh toán:
              <span style="font-weight: bold; font-size: 16px"> {{ $thousandSeprator(editedItem.paid) }} </span>
            </span>
          </div>

          <div class="mr-2" style="background-color: #ff586b; width: auto; height: 40px; text-align: center; padding-right: 10px; padding-left: 10px; padding-top: 8px; border-radius: 4px">
            <span style="color: white; font-size: 12px">
              Tổng tiền:
              <span style="font-weight: bold; font-size: 16px"> {{ $thousandSeprator(editedItem.total) }} </span>
            </span>
          </div>
        </div>
      </div>

      <v-row class="mt-2">
        <v-col cols="12" sm="12" justify="center">
          <div class="d-flex flex-row-reverse">
            <v-btn class="ml-4" color="primary" dark @click="exportToExcel">
              Export
            </v-btn>

            <v-btn color="primary" dark @click="getAllOTP" class="ml-4">{{
                $t("common.search")
              }}</v-btn>

            <vc-date-picker v-model="endDate" :popover="{label: 'Hover', visibility: 'hover'}" style="width: 120px;" class="ml-4">
            </vc-date-picker>
            <span class="mt-2 ml-2">đến</span>
            <vc-date-picker v-model="startDate" :popover="{label: 'Hover', visibility: 'hover'}" style="width: 120px;" class="ml-4">
            </vc-date-picker>

            <v-autocomplete class="ml-4" background-color="#ffffff" v-model="selectService" :items="services" item-text="symbol"
                            v-bind:label="$t('buy_code_management.service_key')" return-object outlined dense >
            </v-autocomplete>

            <v-autocomplete background-color="#ffffff" v-model="selectOTPStatus" :items="otpStatus" item-text="title"
                            v-bind:label="$t('buy_code_management.status')" return-object outlined dense >
            </v-autocomplete>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :loading="loading"
        sort-by="email"
        class="elevation-1"
        hide-default-footer
        :items-per-page="itemsPerPage"
    >
      <!--      :item-class="this.$statusOTP"-->
      <template v-slot:top>

        <v-toolbar flat color="white" class="pt-5 mb-2">
          <v-row justify="start">
            <v-col cols="12" sm="2" >
              <v-autocomplete v-model="itemsPerPage" :items="numberPage" outlined :dense="true"></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="4">
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field v-model="search" append-icon="search" v-bind:label="$t('common.search')" single-line hide-details outlined :dense="true"></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
        <div>
          <span class="defaultFont">Tổng giao dịch: <span class="defaultBoldFont"> {{ $thousandSeprator(totalItem) }}</span> </span>
<!--          .-->
<!--          Tổng tiền: <span class="defaultBoldFont"> {{ $thousandSeprator(totalMoney) }} </span>-->
        </div>
      </template>

      <template v-slot:[`item.payment`]="{ item }">
        <span class="text-no-wrap">{{ $thousandSeprator(item.payment) }}</span>
      </template>

      <template v-slot:[`item.price_dealer`]="{ item }">
        <span class="text-no-wrap">{{ $thousandSeprator(item.price_dealer) }}</span>
      </template>

      <template v-slot:[`item.dealer`]="{ item }">
        <span class="text-no-wrap">{{ item.dealer.username }}</span>
      </template>

      <template v-slot:[`item.service`]="{ item }">
        <span class="text-no-wrap"> {{ item.service.symbol }} </span>
      </template>

      <template v-slot:[`item.otp`]="{ item }">
        <div class="contentAddress">
          <span class="text-no-wrap" style="display: inline-block; width: 60px;"> {{ item.otp }} </span>
          <v-icon small class="mr-2" @click="copyClipboard(item.otp)" v-if="item.otp.length > 0">
            content_copy
          </v-icon>
          <!--          <v-tooltip-->
          <!--              bottom-->
          <!--              max-width="300px"-->
          <!--              color="primary"-->
          <!--          >-->
          <!--            <template v-slot:activator="{ on }">-->
          <!--              <v-icon v-on="on" small class="mr-2" @click="copyClipboard(item.otp)" v-if="item.otp.length > 0">-->
          <!--                content_copy-->
          <!--              </v-icon>-->
          <!--            </template>-->
          <!--            <span>Click to Copy</span>-->
          <!--          </v-tooltip>-->
        </div>

      </template>

      <template v-slot:[`item.user`]="{ item }">
        <span class="text-no-wrap">{{ item.user.username  }}</span>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <span v-if="item.type !== 'audio'">{{ item.content  }}</span>
        <vuetify-audio v-else :file="item.audioMp3" color="primary" ></vuetify-audio>
      </template>

      <template v-slot:[`item.sim`]="{ item }">
        <div class="contentAddress">
          <span class="text-no-wrap" style="display: inline-block;"> {{ item.sim.phone }} </span>

          <v-icon small class="mr-1 ml-1" @click="copyClipboard(item.sim.phone)" v-if="item.sim.phone > 0">
            content_copy
          </v-icon>
          <!--          <v-tooltip-->
          <!--              bottom-->
          <!--              max-width="300px"-->
          <!--              color="primary"-->
          <!--          >-->
          <!--            <template v-slot:activator="{ on }">-->
          <!--              <v-icon v-on="on" small class="mr-2" @click="copyClipboard(item.sim.phone)" v-if="item.sim.phone > 0">-->
          <!--                content_copy-->
          <!--              </v-icon>-->
          <!--            </template>-->
          <!--          </v-tooltip>-->
        </div>
      </template>

      <template v-slot:[`item.create_date`]="{ item }">
        <span class="text-no-wrap">{{ item.create_date | formatDate }}</span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <span :class="$statusOTP(item)">{{ $OTPStatusString(item.status) }}</span>
      </template>

      <template v-slot:no-data>
        <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
      </template>
    </v-data-table>

    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="pageCount"></v-pagination>
    </div>

    <event-log v-model="historyDialog" :editedItem="editedItem" @closeDialog="closeHistoryDialog"
               v-if="historyDialog">
    </event-log>

    <v-overlay :value="overlay" :z-index="0">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import i18n from '@/plugins/i18n';
import socketClient from "@/plugins/socketClient";
import moment from 'moment'
import EventLog from "@/components/EventLog";
import * as XLSX from "xlsx";

export default {
  data: () => ({
    overlay: false,
    totalItem: 0,
    totalMoney: 0,
    page: 1,
    pageCount: 0,
    itemsPerPage: 1000,
    loading: false,
    headerNumber: '',
    numberPage: [
      50, 100, 300, 500, 1000
    ],
    dateRange: {start: new Date(), end: new Date()},
    startDate: new Date(),
    endDate: new Date(),

    selectNetwork: {},
    networks: [],

    historyDialog: false,
    selectService: { symbol: i18n.t("common.all"), id: -1 },
    services: [],
    isShowContent: false,
    editedItem: {},

    sendSmsDialog: false,

    snackbar: false,
    message: '',
    color: '#0C8F4D',

    search: '',
    dialog: false,
    headers: [
      { text: '#', align: 'left', sortable: false, value: 'index', },
      { text: i18n.t('buy_code_management.create_date'), value: 'create_date', sortable: false },
      // { text: i18n.t('buy_code_management.customer'), value: 'user', sortable: false },
      { text: i18n.t('buy_code_management.phone'), value: 'sim', sortable: false },

      { text: i18n.t('buy_code_management.service_key'), value: 'service', sortable: false },
      // { text: i18n.t('buy_code_management.price'), value: 'payment', sortable: false },
      { text: 'Giá', value: 'price_dealer', sortable: false },

      { text: i18n.t('buy_code_management.status'), value: 'status', sortable: false },
      // { text: 'Nội dung', value: 'type', sortable: false },
      // { text: 'Đại lý', value: 'dealer', sortable: false },
      // { text: '', align: 'left', value: 'action', sortable: false },
    ],

    otpStatus: [
      { title: i18n.t("common.all"), value: -1 },
      { title: i18n.t("buy_code_management.success"), value: 1 },
      { title: i18n.t("buy_code_management.waiting"), value: 2 },
      { title: i18n.t("buy_code_management.cancel"), value: 3 },
      { title: i18n.t("buy_code_management.timeout"), value: 0 },
    ],

    selectOTPStatus: { title: i18n.t("buy_code_management.success"), value: 1 },

    desserts: [],
    stringRules: [
      v => !!v || i18n.t('validate.not_empty'),
      // v => (v && v.length > 5) || i18n.t('validate.string_invalid')
    ],

    attributes: [
      // This is a single attribute
      {
        popover: { label: 'Hover', visibility: 'hover'}
      }
    ],
    selectedRows: [],
  }),

  components: {
    EventLog: EventLog
  },

  computed: {
    selectDragAttribute() {
      return {
        popover: {
          visibility: 'hover',
          isInteractive: false, // Defaults to true when using slot
        },
      };
    },
  },

  watch: {
    itemsPerPage() {
      this.page = 1;
      this.getAllOTP();
    },

    page() {
      this.getAllOTP();
    },
  },

  async created () {
    await Promise.all([
      await this.getTransaction(),
      await this.getAllService()
    ])

    await this.getAllOTP()

    socketClient.getIO().on('notifications-otp', (msg) => {
      this.desserts.forEach((element, index) => {
        if(element.sim.phone === msg.phone && element.status == 2) {
          this.desserts[index].create_date = msg.time
          this.desserts[index].status = msg.status;

          if (msg.status === 1) {
            Object.assign(this.desserts[index], msg);
          }

          return;
        }
      });
    });
  },

  methods: {
    async getTransaction() {
      try {
        const response = await this.$getAllTransactionStatistic(0, 1);

        if (response.status === 200) {
          if (response.data.content.length > 0) {
            this.editedItem = [].concat(response.data.content)[0];
            console.log(this.editedItem);
          }
        }
      } catch (e) {
        console.log(e.stack);
      }
    },

    async getAllOTP() {
      try {
        const from = this.$formatStartUTCDate(this.startDate);
        const to = this.$formatEndUTCDate(this.endDate);

        this.loading = true;
        const response = await this.$getAllOTPNotSpam(this.selectService.id, this.selectOTPStatus.value,this.page - 1, this.itemsPerPage, from, to);
        this.loading = false;

        if (response.status === 200) {
          this.desserts = [].concat(response.data.content);
          this.pageCount = response.data.totalPages;
          this.totalItem = response.data.totalElements;
          this.totalMoney = response.data.totalPayment;

          this.desserts.forEach(
              (item, index) => {
                item.index = (this.page - 1) * this.itemsPerPage + index + 1;
              }
          );
        }
      } catch (e) {
        console.log(e.stack);
      }
    },

    async exportToExcel() { // On Click Excel download button
      this.overlay = true
      const from = this.$formatStartUTCDate(this.startDate);
      const to = this.$formatEndUTCDate(this.endDate);

      const response = await this.$getAllOTPNotSpam(this.selectService.id, this.selectOTPStatus.value,this.page - 1, this.totalItem, from, to);
      this.overlay = false

      if (response.data && response.status === 200) {
        const responseData = response.data.content;
        const rows = responseData.map(row => ({
          create_date: moment.utc(String(row.create_date)).local().format("HH:mm:ss DD/MM/YYYY"),
          service: row.service.symbol,
          payment: row.price_dealer,
          phone: row.sim.phone,
          status: this.$OTPStatusString(row.status)
        }));

        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "OTP");

        XLSX.utils.sheet_add_aoa(worksheet, [[
          "Thời gian",
          "Dịch vụ",
          "Giá tiền",
          "Số điện thoại",
          "Trạng thái"]], { origin: "A1" });
        /* calculate column width */
        const max_width_date = rows.reduce((w, r) => Math.max(w, r.create_date.length), 15);

        worksheet["!cols"] = [
          { wch: max_width_date },
          { wch: max_width_date },
          { wch: max_width_date },
          { wch: max_width_date },
          { wch: max_width_date }
        ];
        // export Excel file
        XLSX.writeFile(workbook, "OTP.xlsx", { compression: true });
      }
    },

    copyClipboard(value) {
      const self = this;
      this.$copyText(value).then(function () {
        self.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
        self.message = 'Copy thành công ' + value;
        self.snackbar = true;
      }, function () {
        self.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
        self.message = 'Copy không thành công ' + value;
        self.snackbar = true;
      })
    },

    showHistoryDialog() {
      console.log("showHistoryDialog")
      this.historyDialog = true;
    },

    closeHistoryDialog(value) {
      this.historyDialog = value;
    },

    async getAllService() {
      try {
        const response = await this.$getAllService(0, 1000);

        if (response.status === 200) {
          console.log(response.data.content);
          let data = [].concat(response.data.content.filter(service => (service.symbol || service.symbol.length !== 0)))
          this.services = [this.selectService].concat(data);
        }
      } catch (e) {
        console.log(e.stack);
      }
    }
  }
}
</script>

<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

.bank-title {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
}

.bold-title {
  font-style: normal;
  font-weight: bold;
}

.notice-title {
  color: #FF0000;
  font-style: normal;
  font-weight: bold;
}

.v-btn {
  text-transform: unset !important;
}
</style>
