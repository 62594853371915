<template>
  <v-container fluid>
    <div>
      <v-row justify="space-between">
        <v-col cols="12" sm="6" justify="center">
          <p class="header-title">{{ $t("service_management.title") }}</p>
        </v-col>

        <v-col cols="12" sm="6">
          <div class="d-flex flex-row-reverse">
            <v-btn color="primary" dark @click="createNewService">{{
                $t("common.create")
              }}</v-btn>
            <v-btn color="primary" dark @click="getData" class="mr-5">{{
                $t("common.refresh")
              }}</v-btn>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-data-table
        :headers="headers"
        :items="desserts"
        class="elevation-1"
        hide-default-footer
        :loading="loading"
        :search="search"
        @dblclick:row="editItemDB"
        :items-per-page="itemsPerPage"
    >
      <template v-slot:top>
        <v-toolbar flat color="white" class="pt-5 mb-2">
          <v-row justify="start">
            <v-col cols="12" sm="2">
<!--              <v-autocomplete-->
<!--                  v-model="itemsPerPage"-->
<!--                  :items="numberPage"-->
<!--                  outlined-->
<!--                  :dense="true"-->
<!--              ></v-autocomplete>-->
            </v-col>

            <v-col cols="12" sm="1">
              <!--              <v-autocomplete-->
              <!--                  v-model="selectUserType"-->
              <!--                  :items="userTypes"-->
              <!--                  item-text="title"-->
              <!--                  v-bind:label="$t('profile.type')"-->
              <!--                  return-object-->
              <!--                  outlined-->
              <!--                  :dense="true"-->
              <!--              ></v-autocomplete>-->
            </v-col>

            <v-col cols="12" sm="2">
<!--              <v-autocomplete-->
<!--                  v-model="selectStatus"-->
<!--                  :items="serviceStatus"-->
<!--                  item-text="title"-->
<!--                  v-bind:label="$t('service_management.status')"-->
<!--                  return-object-->
<!--                  outlined-->
<!--                  :dense="true"-->
<!--              ></v-autocomplete>-->
            </v-col>

            <v-col cols="12" sm="7">
              <v-text-field
                  append-icon="search"
                  v-model.trim="search"
                  v-bind:label="$t('common.search')"
                  single-line
                  hide-details
                  outlined
                  :dense="true"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- </v-container> -->
        </v-toolbar>
        <div justify="start">
          <span class="defaultFont">Tổng số dịch vụ: <span class="defaultBoldFont"> {{ $thousandSeprator(totalItem) }}</span> </span>
        </div>
      </template>

      <template v-slot:[`item.name`]="{ item }">
<!--        <span class="text-no-wrap"> {{ item.name }} </span>-->
        <div class="contentAddress">
          <v-avatar size="30" class="mr-1">
            <img v-if="item.image" :src="item.image"/>
            <img v-else src='@/assets/no-photo.png'/>
          </v-avatar>

          <span class="text-no-wrap"> {{ item.name }} </span>
        </div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
<!--        <span :class="$statusService(item)">{{ $statusServiceString(item.status) }}</span>-->
        <v-switch
            color="green"
            value
            :input-value="item.status === 1"
            dense
            hide-details
            inset
            class="mb-2 ml-2"
            @change="changeStatus(item)"
        ></v-switch>
      </template>

      <template v-slot:[`item.warehouse_list`]="{ item }">
        <span>{{ convertListWareHouseToString(item.warehouse_list) }}</span>
      </template>

      <template v-slot:[`item.create_date`]="{ item }">
        <span class="text-no-wrap">{{ item.create_date | formatDate }}</span>
      </template>

      <template v-slot:[`item.price`]="{ item }">
        <span class="text-no-wrap">{{ $thousandSeprator(item.price) }}</span>
      </template>

      <template v-slot:[`item.level_1`]="{ item }">
        <span class="text-no-wrap">{{ $thousandSeprator(item.level_1) }}</span>
      </template>

      <template v-slot:[`item.level_2`]="{ item }">
        <span class="text-no-wrap">{{ $thousandSeprator(item.level_2) }}</span>
      </template>

      <template v-slot:[`item.time_out`]="{ item }">
        <span class="text-no-wrap">{{ item.time_out }} phút</span>
      </template>

      <template v-slot:[`item.is_repeat`]="{ item }">
        <v-icon v-show="!item.is_repeat" color="black">
          check
        </v-icon>
      </template>

      <template v-slot:[`item.level_3`]="{ item }">
        <span class="text-no-wrap">{{ $thousandSeprator(item.level_3) }}</span>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="contentTitle">
          <v-tooltip
              slot="append-outer"
              bottom
              max-width="300px"
              color="primary"
          >
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" size="20px" color="white" class="mr-2 iconBorderOrange" @click="editItem(item)">
                edit
              </v-icon>
            </template>
            <span>{{$t("user_management.edit_account")}}</span>
          </v-tooltip>

          <!--          <v-tooltip-->
          <!--              slot="append-outer"-->
          <!--              bottom-->
          <!--              max-width="300px"-->
          <!--              color="primary"-->
          <!--          >-->
          <!--            <template v-slot:activator="{ on }">-->
          <!--              <v-icon v-on="on" small class="mr-2" @click="disableAccount(item)">-->
          <!--                lock-->
          <!--              </v-icon>-->
          <!--            </template>-->
          <!--            <span>{{$t("user_management.lock_account")}}</span>-->
          <!--          </v-tooltip>-->

        </div>

      </template>

      <template v-slot:no-data>
        <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
      </template>
    </v-data-table>

<!--    <div class="text-center pt-2">-->
<!--      <v-pagination v-model="page" :length="pageCount"></v-pagination>-->
<!--    </div>-->

    <service-dialog
        v-model="dialogEditService"
        :editedItem="editedItem"
        :editedIndex="editedIndex"
        @closeDialog="closeEditService"
        @updateService="updateService"
        v-if="dialogEditService">
    </service-dialog>

    <v-snackbar
        v-model="snackbar"
        :color="color"
        :timeout="2000"
        top
        right
        elevation="24"
        class="mt-5"
    >
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>

import i18n from "@/plugins/i18n";
// import moment from "moment";
import ServiceDialog from "@/components/ServiceDialog";

export default {
  data: () => ({
    show: false,
    e1: 0,
    page: 1,
    pageCount: 0,
    itemsPerPage: 1000,
    isEdit: false,
    totalItem: 0,
    loading: true,
    // numberPage: [50, 100, 200, 300, 500],

    snackbar: false,
    message: '',
    color: '#0C8F4D',

    serviceStatus: [
      { title: i18n.t("common.all"), value: -1 },
      { title: i18n.t("service_management.active"), value: 1 },
      { title: i18n.t("service_management.inactive"), value: 0 }
    ],

    selectStatus: { title: i18n.t("common.all"), value: -1 },

    firstEdit: true,
    search: "",
    dialog: false,

    headers: [
      { text: "#", align: "left", sortable: false, value: "index" },
      { text: i18n.t("service_management.name"), value: "name", sortable: false },
      { text: i18n.t("service_management.signal"), value: "symbol", sortable: false },
      { text: i18n.t("service_management.sender"), value: "sender", sortable: false },
      { text: i18n.t("service_management.keyword"), value: "keyword", sortable: false },
      { text: i18n.t("service_management.time_out"), value: "time_out", sortable: false },
      // { text: i18n.t("service_management.agency"), value: "dealerResponses", sortable: false},
      { text: i18n.t("service_management.user_price"), value: "price", sortable: false},
      { text: i18n.t("service_management.user_level_1"), value: "level_1", sortable: false},
      { text: i18n.t("service_management.user_level_2"), value: "level_2", sortable: false},
      { text: i18n.t("service_management.user_level_3"), value: "level_3", sortable: false},
      { text: i18n.t("service_management.warehouse"), value: "warehouse_list", sortable: false },
      // { text: i18n.t("service_management.agency"), value: "dealerResponses", sortable: false},
      { text: i18n.t("service_management.repeat"), value: "is_repeat", sortable: false },
      // { text: i18n.t("service_management.create_date"), value: "create_date", sortable: false},
      { text: i18n.t("service_management.status"), value: "status", sortable: false},
      // { text: '', align: "center", value: "action", sortable: false}
    ],

    desserts: [],
    editedIndex: -1,
    editedItem: null,
    defaultItem: {},
    dialogEditService: false,

    stringRules: [
      (v) => !!v || i18n.t("validate.not_empty"),
      //   v => (v && v.length > 3) || i18n.t('validate.string_invalid')
    ],
    phoneRules: [
      (v) => !!v || i18n.t("validate.not_empty"),
      (v) => (v && v.length > 9) || i18n.t("validate.phone_invalid"),
    ],
    addressRules: [
      (v) => !!v || i18n.t("validate.not_empty"),
      // v => (v && v.length > 5) || i18n.t('validate.address_invalid'),
    ]
  }),

  computed: {

  },

  components: {
    ServiceDialog: ServiceDialog,
  },

  watch: {
    itemsPerPage() {
      this.page = 1;
      this.getData();
    },

    page() {
      this.getData();
    },
  },

  async created() {
    await this.getData();
  },

  mounted() {
    window.onpopstate = event => {
      this.$router.push("serviceManagement");
    };
  },

  methods: {
    async getData() {
      this.loading = true;
      const response = await this.$getAllService(this.page - 1, this.itemsPerPage);
      this.loading = false;

      if (response.data && response.status === 200) {
        this.desserts = [].concat(response.data.content);
        this.pageCount = response.data.totalPages;
        this.totalItem = response.data.totalElements;
        this.desserts.forEach(
            (item, index) => {
              item.index = (this.page - 1) * this.itemsPerPage + index + 1;
            }
        );

        // this.desserts = contextArr;
      } else {
        this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
        this.message = i18n.t('common.error')
        this.snackbar = true;
      }
    },

    createNewService() {
      this.editedIndex = -1;
      this.editedItem = {
        price: 0,
        time_out: 5,
        level_1: 0,
        level_2: 0,
        level_3: 0
      };

      this.dialogEditService = true;
    },

    convertListWareHouseToString(values) {
      var result = '';
      values.forEach(
          (item, index) => {
            if (index < values.length - 1) {
              result = result + item.name + ', '
            } else {
              result = result + item.name
            }
          }
      );

      return result;
    },

    closeEditService(value) {
      this.dialogEditService = value;
      this.editedItem = null;
      this.editedIndex = -1;
    },

    async updateService(value) {
      if (this.editedIndex > -1) {
        this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
        this.message = i18n.t('common.update_success');
        this.snackbar = true;

        // Object.assign(this.desserts[this.editedIndex], value);
      } else {
        this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
        this.message = i18n.t('common.add_success')
        this.snackbar = true;

        this.editedIndex = -1;
        // this.getData();
      }
      await this.getData();
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEditService = true;
    },

    editItemDB(event, {item}) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEditService = true;
    },

    async changeStatus(item) {
      const requestBody = {};

      Object.assign(requestBody, item);
      delete requestBody.warehouse_list;
      delete requestBody.dealer_list;
      requestBody.warehouse_id_list =  item.warehouse_list.map((obj) => obj.id);
      requestBody.dealer_id_list =  item.dealer_list.map((obj) => obj.id);
      requestBody.status = (item.status === 1 ? 0 : 1);

      const response = await this.$updateService(item.id, requestBody);
      if (response.status === 200) {
        console.log('VUE_APP_SUCCESS_COLOR_TOAST');
        this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
        this.message = i18n.t('common.update_success');
        this.snackbar = true;
      } else {
        console.log('VUE_APP_ERROR_COLOR_TOAST');
        this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
        this.message = 'Cập nhật không thành công!';
        this.snackbar = true;
      }

      await this.getData();
    }
  }
};
</script>

<style scoped>
.v-text-field .v-input__control .v-input__slot {
  min-height: 45px !important;
  /* display: flex !important; */
  align-items: center !important;
}
</style>
