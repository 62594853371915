<template>
    <div>
        <div class="content-item">
            Số TK: <span class="font-weight-bold"> {{ bankingAccount }} </span>
            <v-icon small color="primary" class="ml-2" @click="copyClipboard(bankingAccount)">
                file_copy
            </v-icon>
        </div>
        <div class="content-item">
          Ngân hàng: <span class="font-weight-bold"> {{ bankingName }} </span>
        </div>
        <div class="content-item">
            Chủ TK: <span class="font-weight-bold"> {{ account }} </span>
        </div>
        <div class="content-item">
            Nội dung chuyển khoản: <span class="bold-title"> {{ formatTopUp }} </span>
            <v-icon small color="primary" class="ml-2" @click="copyClipboard(formatTopUp)">
                file_copy
            </v-icon>
        </div>
        <div class="content-item">
            <span> Lưu ý: </span> <br/>
            <span class="note-text"> * Khi chuyển khoản quý khách vui lòng ghi đúng nội dung ck mà web cung cấp để auto nạp số dư. </span>
            <br/>
            <span class="note-text"> * Nếu quý khách ghi nhầm nội dung ck vui lòng chụp ảnh bill chi tiết gửi vào zalo hỗ trợ kèm theo nội dung ck đúng. </span>
            <br/>
            <span class="note-text"> * Số tiền nạp tối thiểu là {{ $thousandSeprator(minimumPrice) }}đ</span> <br/>
            <span class="note-text"> * Quý khách ghi đúng thông tin nạp tiền thì tài khoản sẽ được cộng tự động sau khi giao dịch thành công. </span>
            <br/>
            <span class="notice note-text"> * Hệ thống không chịu trách nhiệm nếu nội dung nạp không đúng. </span>
            <br/>
        </div>
    </div>
</template>

<script>
export default {
    name: "NoteTopUpMobile",
    props: {
        bankingAccount: String,
        account: String,
        bankingName: String,
        formatTopUp: String,
        minimumPrice: String,
        copyClipboard: Function
    },
    methods: {}
}
</script>

<style scoped>
.content-item {
    font-size: 17px
}

.note-text {
    font-size: 14px;
    font-weight: 400;
}

.notice {
    color: #FF0000;
}
</style>