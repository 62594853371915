import Vue from "vue";

export default {
    install() {
        Vue.prototype.$getAllTransaction = async function (page, limit) {
            let result = null;
            try {
                const response = await this.$http.get(process.env.VUE_APP_GET_ALL_TRANSACTION + "?sort=desc&limit=" + limit + "&page=" + page);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$getAllTransactionStatistic = async function (page, limit) {
            let result = null;
            try {
                const response = await this.$http.get(process.env.VUE_APP_GET_ALL_TRANSACTION_STATISTIC + "?sort=desc&limit=" + limit + "&page=" + page);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$createTransaction = async function (requestData) {
            let result = null;
            try {
                const response = await this.$http.post(process.env.VUE_APP_CREATE_TRANSACTION, JSON.stringify(requestData));
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$resetTransaction = async function (requestData) {
            let result = null;
            try {
                const response = await this.$http.delete(process.env.VUE_APP_CREATE_TRANSACTION, { data: JSON.stringify(requestData) });
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };
    },
};