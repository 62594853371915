<template>
  <div>
    <v-dialog v-model="value" persistent max-width="90%">
      <v-card class="elevation-16 mx-auto" height="90vh">
        <v-card-title class="header-title" primary-title>
          <span>{{$t("user_management.top_up_history")}}</span>
          <v-spacer></v-spacer>
          <v-icon @click="closeDialog()">close</v-icon>
        </v-card-title>

        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="desserts"
              :search="search"
              :loading="loading"
              sort-by="email"
              class="elevation-1"
              hide-default-footer

              :items-per-page="itemsPerPage">
            <template v-slot:top>

              <v-toolbar flat color="white" class="pt-5 mb-2">
                <v-row justify="start">
                  <v-col cols="12" sm="2">
                    <v-autocomplete v-model="itemsPerPage" :items="numberPage" outlined :dense="true"></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="10">
                    <div class="d-flex flex-row-reverse">
                      <v-btn color="primary" dark @click="getDataByDate" class="ml-4">{{
                          $t("common.search")
                        }}</v-btn>

                      <vc-date-picker mode="range" v-model="dateRange" :popover="{label: 'Hover', visibility: 'hover'}" style="width: 220px;">
                      </vc-date-picker>
                    </div>
                  </v-col>
                </v-row>
              </v-toolbar>
              <div>
          <span class="defaultFont">Tổng giao dịch: <span class="defaultBoldFont"> {{ $thousandSeprator(totalItem) }}</span>.
            Tổng tiền: <span class="defaultBoldFont"> {{ $thousandSeprator(totalMoney) }} </span> </span>

                <!--          <span>Tổng giao dịch: <span class="statisticFont"> {{ $thousandSeprator(totalItem) }}</span>. Tổng tiền: <span class="statisticFont"> {{ $thousandSeprator(totalMoney) }} </span> </span>-->
              </div>
            </template>

            <template v-slot:[`item.payment`]="{ item }">
              <span class="text-no-wrap">{{ $thousandSeprator(item.payment) }}</span>
            </template>

            <template v-slot:[`item.create_date`]="{ item }">
              <span class="text-no-wrap">{{ item.create_date | formatDate }}</span>
            </template>

            <template v-slot:no-data>
              <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
            </template>
          </v-data-table>

          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
          </div>
        </v-card-text>

<!--        <v-divider></v-divider>-->
<!--        <v-card-actions class="justify-space-between">-->
<!--          <v-spacer></v-spacer>-->
<!--          <v-btn color="primary" dark @click="closeDialog">{{ $t('common.close') }}</v-btn>-->
<!--        </v-card-actions>-->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n';
import moment from 'moment';

export default {
  name: 'credit-history-dialog',

  props: {
    value: Boolean,
    editedItem: Object
  },

  data: () => ({
    baseImage: process.env.VUE_APP_BASE_IMAGE,
    baseAttach: process.env.VUE_APP_BASE_ATTACH,

    loading: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 500,

    numberPage: [
      50, 100, 300, 500, 1000
    ],

    dateRange: {start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date()},

    totalItem: 0,
    totalMoney: 0,

    search: '',

    headers: [
      { text: '#', align: 'left', sortable: false, value: 'index', },
      { text: i18n.t('top_up_management.create_date'), value: 'create_date' },
      { text: i18n.t('credit_management.bank'), value: 'bank' },
      { text: i18n.t('top_up_management.content'), value: 'content' },
      { text: i18n.t('top_up_management.payment'), value: 'payment' },
      // { text: i18n.t('credit_management.user'), value: 'user_id' },
      // { text: '', value: 'action', sortable: false }
    ],

    desserts: [],
  }),

  computed: {},

  watch: {},

  mounted() {

  },

  created() {
    this.getData();
  },


  methods: {
    getDataByDate() {
      this.page = 1;
      this.getData();
    },

    async getData() {
      try {
        const from = this.$formatStartUTCDate(this.dateRange.start);
        const to = this.$formatEndUTCDate(this.dateRange.end);

        this.loading = true;
        const response = await this.$getAllCreditUser(this.editedItem.id,this.page - 1, this.itemsPerPage, from, to);
        this.loading = false;

        if (response.status === 200) {
          this.desserts = [].concat(response.data.content);
          this.pageCount = response.data.totalPages;
          this.totalItem = response.data.totalElements;
          this.totalMoney = response.data.totalPayment;
          this.desserts.forEach(
              (item, index) => {
                item.index = (this.page - 1) * this.itemsPerPage + index + 1;
              }
          );
        }
      } catch (e) {
        console.log(e.stack);
      }
    },

    closeDialog() {
      this.$emit('closeDialog', false);
    }
  }
}
</script>

<style scoped>
.v-text-field .v-input__control .v-input__slot {
  min-height: 45px !important;
  /* display: flex !important; */
  align-items: center !important;
}
</style>
