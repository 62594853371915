var render, staticRenderFns
import script from "./MarqueeText.vue?vue&type=script&lang=js&"
export * from "./MarqueeText.vue?vue&type=script&lang=js&"
import style0 from "./MarqueeText.vue?vue&type=style&index=0&id=57d9fc94&prod&module=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports