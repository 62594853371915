<template>
  <div>
    <v-dialog v-model="value" persistent max-width="60%">
      <v-card class="elevation-16 mx-auto">
        <v-card-title class="header-title" primary-title>
          {{ isEdit ? $t('common.edit') : $t('common.add') }}
          <v-spacer></v-spacer>
          <v-icon @click="cancelAction()">close</v-icon>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text>
          <v-form v-model="formValid" ref="myForm">
            <v-container align="center">
              <v-row class="mt-2 ml-2" align="center">
                <image-input v-model="avatar" fieldName="avatar" @input="uploadImage"  class="mr-4">
                  <div slot="activator">
                    <v-avatar size="120px" v-ripple v-if="!avatar || !avatar.base64" class="grey lighten-3 mb-3">
                      <span>{{ $t('common.select_image') }}</span>
                    </v-avatar>
                    <v-avatar size="120px" v-ripple v-else class="mb-3">
                      <img :src="avatar.base64" alt="avatar">
                    </v-avatar>
                  </div>
                </image-input>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="4" class="pr-2 pt-5">
                  <v-text-field  v-model="editedItem.name" v-bind:label="$t('service_management.name')" :rules="stringRules" outlined
                                 dense></v-text-field>
                </v-col>

                <v-col cols="12" sm="4" class="pr-2 pl-2 pt-5">
                  <v-numeric-field v-model="editedItem.time_out"
                                   v-bind:label="$t('service_management.time_out')" outlined dense>
                  </v-numeric-field>
                </v-col>

                <v-col cols="12" sm="4" class="pl-2 pt-5">
                  <v-numeric-field v-model="editedItem.price"
                                   v-bind:label="$t('service_management.user_price')" outlined dense>
                  </v-numeric-field>
                </v-col>


              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="4" class="pr-2">
                  <v-numeric-field v-model="editedItem.level_1"
                                   v-bind:label="$t('service_management.user_level_1')" outlined dense>
                  </v-numeric-field>
                </v-col>

                <v-col cols="12" sm="4" class="pr-2 pl-2">
                  <v-numeric-field v-model="editedItem.level_2"
                                   v-bind:label="$t('service_management.user_level_2')" outlined dense>
                  </v-numeric-field>
                </v-col>

                <v-col cols="12" sm="4" class="pl-2">
                  <v-numeric-field v-model="editedItem.level_3"
                                   v-bind:label="$t('service_management.user_level_3')" outlined dense>
                  </v-numeric-field>
                </v-col>

                <v-col cols="12" sm="4" class="pr-2">
                  <v-text-field v-model="editedItem.symbol" v-bind:label="$t('service_management.signal')" outlined
                                dense></v-text-field>
                </v-col>

                <v-col cols="12" sm="4" class="pl-2 pr-2">
                  <v-text-field v-model="editedItem.sender" v-bind:label="$t('service_management.sender')" outlined
                                dense></v-text-field>
                </v-col>

                <v-col cols="12" sm="4" class="pl-2">
                  <v-text-field v-model="editedItem.keyword" v-bind:label="$t('service_management.keyword')" outlined
                                dense></v-text-field>
                </v-col>

                <v-col cols="12" sm="12">
                  <v-autocomplete v-model="selectWarehouse" :items="warehouses" item-text="name"
                                  v-bind:label="$t('service_management.warehouse')" return-object multiple outlined dense>
                  </v-autocomplete>
                </v-col>
<!--                :error-messages="checkSelectionWareHouse"-->

                <v-col cols="12" sm="12">
                  <v-autocomplete v-model="selectAgency" :items="agencies" item-text="full_name"
                                  v-bind:label="$t('service_management.agency')" return-object multiple outlined dense>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="4" class="pr-2">
                  <v-numeric-field v-model="editedItem.start_index"
                                   v-bind:label="$t('service_management.start_index')" outlined dense>
                  </v-numeric-field>
                </v-col>

                <v-col cols="12" sm="4" class="pr-2 pl-2">
                  <v-numeric-field v-model="editedItem.length_sub"
                                   v-bind:label="$t('service_management.length_sub')" outlined dense>
                  </v-numeric-field>
                </v-col>

                <v-col cols="12" sm="4" class="pl-2">
                  <v-switch
                      dense
                      flat
                      hide-details
                      inset
                      v-model="isRepeat"
                      color="primary"
                      v-bind:label="$t('service_management.repeat')"
                  ></v-switch>
                </v-col>

<!--                <v-col cols="12" sm="6" class="pl-2">-->
<!--                  <v-autocomplete v-model="selectStatus" :items="serviceStatus" item-text="title"-->
<!--                                  v-bind:label="$t('service_management.status')" return-object outlined dense>-->
<!--                  </v-autocomplete>-->
<!--                </v-col>-->
              </v-row>
<!--              <v-row no-gutters>-->
<!--                <v-switch-->
<!--                    dense-->
<!--                    flat-->
<!--                    hide-details-->
<!--                    inset-->
<!--                    v-model="editedItem.is_repeat"-->
<!--                    color="primary"-->
<!--                    v-bind:label="$t('service_management.repeat')"-->
<!--                ></v-switch>-->
<!--              </v-row>-->
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="createService">
            {{ $t('common.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <!--      <v-overlay :value="overlay" :z-index="0">-->
      <!--        <v-progress-circular indeterminate size="64"></v-progress-circular>-->
      <!--      </v-overlay>-->
    </v-dialog>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n';
import VNumericField from "@/components/VNumericField";
import ImageInput from "@/components/ImageInput";

export default {
  name: 'service-dialog',

  props: {
    value: Boolean,
    editedItem: Object,
    editedIndex: Number
  },


  data: () => ({
    e1: 1,
    date: '',
    modal: false,
    show: false,
    formValid: false,
    dialog: false,
    overlay: false,
    isEdit: false,
    isRepeat: false,

    avatar: {},

    selectAgency: [],
    agencies: [],

    selectWarehouse: [],
    warehouses: [],

    serviceStatus: [
      { title: i18n.t("service_management.turn_on"), value: 1 },
      { title: i18n.t("service_management.turn_off"), value: 0 }
    ],

    selectStatus: 1,

    stringRules: [
      v => !!v || i18n.t('validate.string_require'),
      //   v => (v && v.length > 3) || i18n.t('validate.string_invalid')
    ],
    numberRules: [
      v => !!v || i18n.t('validate.not_empty'),
      v => (v && v > 0) || i18n.t('validate.string_invalid')
    ],
  }),

  computed: {
    checkSelectionWareHouse() {
      return this.selectWarehouse.length > 0 ? '' : i18n.t('service_management.warning_warehouse');
    }
  },

  components: {
    VNumericField: VNumericField,
    ImageInput: ImageInput
  },

  watch: {

  },

  async mounted() {

  },

  async created() {
    await Promise.all([
        this.getAllAgency(),
        this.getAllWareHouse()
    ])

    if (this.editedIndex > -1) {
      this.isEdit = true;
      this.avatar.base64 = this.editedItem.image;
      this.selectWarehouse = this.editedItem.warehouse_list
      this.selectAgency = this.editedItem.dealer_list
      this.selectStatus = this.editedItem.status
      this.isRepeat = !this.editedItem.is_repeat;
    }
  },

  methods: {
    async createService() {
      this.$refs.myForm.validate();

      if (!this.validateServiceInfor()) {
        return;
      }

      if (this.avatar && this.avatar.size > 500 * 1024) {
        alert("Ảnh đã chọn vượt quá 500KB");
        return;
      }
      // if (!this.avatar || !this.avatar.base64) {
      //   alert('Chưa chọn ảnh icon của dịch vụ');
      //   return;
      // }

      this.overlay = true;

      const self = this;
      const requestBody = {};

      Object.assign(requestBody, self.editedItem);
      delete requestBody.warehouse_list;
      delete requestBody.dealer_list;
      requestBody.warehouse_id_list =  self.selectWarehouse.map((obj) => obj.id);
      requestBody.dealer_id_list =  self.selectAgency.map((obj) => obj.id);
      requestBody.status = this.selectStatus;
      requestBody.is_repeat = !this.isRepeat;


      if (self.avatar && self.avatar.base64) {
        requestBody.image = self.avatar.base64;
      }

      console.log(requestBody);
      try {
        let response;
        if (this.isEdit) {
          response = await this.$updateService(self.editedItem.id, requestBody);
        } else {
          response = await this.$createService(requestBody);
        }

        this.overlay = false;

        if (response.status === 200) {
          this.$emit('updateService', this.editedItem);
          this.$emit('closeDialog', false);
          // this.$fire({
          //   title: this.isEdit ? i18n.t('common.update_success') : i18n.t('common.add_success'),
          //   text: "",
          //   type: "success"
          // }).then(() => {
          //   this.$emit('updateService', this.editedItem);
          //   this.$emit('closeDialog', false);
          // });
        } else if (response.data) {
          // alert(response.data);
          await this.$fire({
            title: i18n.t('common.error'),
            text: response.data,
            type: "error"
          });
          this.overlay = false;
        } else {
          alert(i18n.t('validate.request_error'));
        }
      } catch (e) {
        console.log(e.stack);
        alert(i18n.t('validate.request_error'));
      }
    },

    async getAllWareHouse() {
      try {
        const response = await this.$getAllWareHouse(0, 1000);

        if (response.status === 200) {
          this.warehouses = [].concat(response.data.content);
        }
      } catch (e) {
        console.log(e.stack);
      }
    },

    async getAllAgency() {
      try {
        let response = await this.$getAllUser("3,4,5", '1,2', 0, 1000);

        if (response.status === 200) {
          this.agencies = [].concat(response.data.content);
        }
      } catch (e) {
        console.log(e.stack);
      }
    },


    validateServiceInfor() {
      if (!this.editedItem.name || this.editedItem.name.length == 0) {
        return false;
      }

      // if (!this.editedItem.sender || this.editedItem.sender.length == 0) {
      //   return false;
      // }
      //
      // if (!this.editedItem.keyword || this.editedItem.keyword.length == 0) {
      //   return false;
      // }

      // if (this.selectWarehouse.length <= 0) {
      //   return false;
      // }

      return true;
    },

    uploadImage() {
      // console.log(this.avatar.base64)
    },

    cancelAction() {
      this.$emit('closeDialog', false);
    }
  }
}
</script>

<style scoped>

.v-text-field .v-input__control .v-input__slot {
  min-height: 45px !important;
  /* display: flex !important; */
  align-items: center !important;
}
</style>
