import Vue from "vue";

export default {
    install() {
        Vue.prototype.$login = async function (requestData) {

            let result = null;
            try {
                const response = await this.$defaultHttp.post(process.env.VUE_APP_LOGIN_API, JSON.stringify(requestData));
                console.log(response)
                if (response.data) {
                    result = response.data;
                }
                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$register = async function (requestData) {

            let result = null;
            try {
                const response = await this.$defaultHttp.post(process.env.VUE_APP_REGISTER_API, JSON.stringify(requestData));
                if (response.data) {
                    result = response.data;
                }
                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$logout = async function () {
            let result = null;
            try {
                const response = await this.$http.post(process.env.VUE_APP_LOGOUT_API);
                if (response.data) {
                    result = response.data;
                }
                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$resetPassword = async function (requestData) {
            let result = null;
            try {
                console.log(requestData);
                const response = await this.$http.put(process.env.VUE_APP_RESET_PW_USER_API, JSON.stringify(requestData));
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        // Vue.prototype.$getAllUser = async function (skip, limit, name, typeUser, status) {
        //     let result = null;
        //     let response;
        //     let paramFilter = '';
        //     if (name !== undefined && name.length !== 0) {
        //         paramFilter += '&name=' + name
        //     }
        //     if (typeUser !== undefined && typeUser !== -1) {
        //         paramFilter += '&type_user=' + typeUser
        //     }
        //
        //     if (status !== undefined && status !== -1) {
        //         paramFilter += '&status=' + status
        //     }
        //
        //     try {
        //         response = await this.$http.get(process.env.VUE_APP_GET_LIST_USER_URL + "?skip=" + skip + "&limit=" + limit + paramFilter);
        //
        //         if (response.data) {
        //             result = response.data;
        //         }
        //
        //         return result;
        //     } catch (e) {
        //         console.log(e.stack);
        //         return result;
        //     }
        // };

        Vue.prototype.$getAllUser = async function (roleId, statusId, page, limit) {
            let result = null;
            let URL = process.env.VUE_APP_USER_API + "?sort=desc" + "&limit=" + limit + "&page=" + page;
            if (roleId !== -1) {
                URL = URL + "&role_id=" + roleId
            }

            if (statusId !== -1) {
                URL = URL + "&status=" + statusId
            }

            try {
                const response = await this.$http.get(URL);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$getAllAgency = async function (page, limit, roleId) {
            let result = null;
            let URL = process.env.VUE_APP_USER_API + "?sort=desc&role_id=" + roleId + "&limit=" + limit + "&page=" + page;

            try {
                const response = await this.$http.get(URL);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$summaryBalance = async function (keyword, roleId, statusId) {
            let result = null;
            let URL = process.env.VUE_APP_SUMMARY_BALANCE_API + "?sort=desc";
            if (roleId !== -1) {
                URL = URL + "&role_id=" + roleId
            }

            if (keyword.length > 0) {
                URL = URL + "&keyword=" + keyword
            }

            if (statusId !== -1) {
                URL = URL + "&status=" + statusId
            }

            try {
                const response = await this.$http.get(URL);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        }

        Vue.prototype.$searchUserCombine = async function (keyword, roleId, statusId, page, limit) {
            let result = null;
            let URL = process.env.VUE_APP_USER_API + "?sort=desc" + "&limit=" + limit + "&page=" + page;
            if (roleId !== -1) {
                URL = URL + "&role_id=" + roleId
            }

            if (keyword.length > 0) {
                URL = URL + "&keyword=" + keyword
            }

            if (statusId !== -1) {
                URL = URL + "&status=" + statusId
            }

            try {
                const response = await this.$http.get(URL);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        // Vue.prototype.$getInforUserByID = async function (requestData) {
        //     let result = null;
        //     try {
        //         const response = await this.$http.get(process.env.VUE_APP_GET_PROFILE_URL + "?id=" + requestData);
        //         if (response.data) {
        //             result = response.data;
        //         }
        //
        //         return result;
        //     } catch (e) {
        //         console.log(e.stack);
        //         return result;
        //     }
        // };

        Vue.prototype.$searchUser = async function (keyword) {
            let result = null;
            try {
                const response = await this.$http.get(process.env.VUE_APP_SEARCH_USER_API + "?keyword=" + keyword);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$registerUser = async function (requestData) {
            let result = null;
            try {
                const response = await this.$http.post(process.env.VUE_APP_REGISTER_API, JSON.stringify(requestData));
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$createUser = async function (requestData) {
            let result = null;
            try {
                const response = await this.$http.post(process.env.VUE_APP_CREATE_USER_API, JSON.stringify(requestData));
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$updateUser = async function (requestData) {
            let result = null;
            try {
                const response = await this.$http.put(process.env.VUE_APP_EDIT_USER_API, JSON.stringify(requestData));
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$disableUser = async function (requestData) {
            let result = null;
            try {
                const response = await this.$http.put(process.env.VUE_APP_DISABLE_USER_API, JSON.stringify(requestData));
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$changePassword = async function (requestData) {
            let result = null;
            try {
                const response = await this.$http.put(process.env.VUE_APP_CHANGE_PW_USER_API, JSON.stringify(requestData));
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$getInformationUser = async function () {
            let result = null;
            try {
                const response = await this.$http.get(process.env.VUE_APP_GET_INFOR_USER_API);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$generateAPIKey = async function () {
            let result = null;
            try {
                const response = await this.$http.put(process.env.VUE_APP_GENERATE_API_KEY);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$pushNotification = async function (requestData) {
            let result = null;
            try {
                const response = await this.$http.post(process.env.VUE_APP_PUSH_NOTIFCATION_USER_API, JSON.stringify(requestData));
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };


        Vue.prototype.$updateInformationUser = async function (requestData) {
            let result = null;
            try {
                const response = await this.$http.put(process.env.VUE_APP_UPDATE_INFOR_USER_API, JSON.stringify(requestData));
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$statusUser = function(item) {
            if (item.status == 0 || item.status == 2) {
                return 'otpError';
            } else {
                return 'otpSuccess';
            }
        };
    },
};