import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueSimpleAlert from "vue-simple-alert";

import i18n from './plugins/i18n'
import router from './router/index'
import store from './store/index'
import axios from '@/plugins/axios';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vue-json-pretty/lib/styles.css';
import FlagIcon from 'vue-flag-icon'
import moment from 'moment';
import userApi from '@/controllers/userApi';
import common from '@/controllers/common';
import convert from '@/controllers/convertion';
import configApi from '@/controllers/configApi';
import serviceApi from '@/controllers/serviceApi';
import warehouseApi from '@/controllers/warehouseApi';
import networkServiceApi from '@/controllers/networkServiceApi';
import transactionApi from '@/controllers/transactionApi';
import creditApi from '@/controllers/creditApi';
import simApi from '@/controllers/simApi';
import otpApi from '@/controllers/otpApi';
import VCalendar from 'v-calendar';
import VueClipboard from 'vue-clipboard2';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import "vue-easytable/libs/theme-default/index.css";
import VueEasytable from "vue-easytable";
import { VePagination } from "vue-easytable";


// import JsonExcel from "vue-json-excel";
import VuetifyAudio from "@/components/vuetifyaudio";
import VuetifyToast from 'vuetify-toast-snackbar'
import VueLazyload from "vue-lazyload";
import VueVirtualScroller, {RecycleScroller} from 'vue-virtual-scroller'
import VueJsonPretty from 'vue-json-pretty'


Vue.use(VuetifyToast, {
    x: 'right', // default
    y: 'bottom', // default
    color: 'info', // default
    icon: 'info',
    iconColor: '', // default
    classes: [
        'body-2'
    ],
    timeout: 3000, // default
    dismissable: true, // default
    multiLine: false, // default
    vertical: false, // default
    queueable: false, // default
    showClose: false, // default
    closeText: '', // default
    closeIcon: 'close', // default
    closeColor: '', // default
    slot: [], //default
    shorts: {
        custom: {
            color: 'purple'
        }
    },
    property: '$toast' // default
})

// Vue.component("downloadExcel", JsonExcel);
Vue.component("vuetify-audio", VuetifyAudio);
Vue.component("vue-json-pretty", VueJsonPretty)
// Vue.component('marquee-text', MarqueeText)
Vue.config.productionTip = false

axios.instance.interceptors.response.use(function (response) {
    return response
}, function (error) {
    console.log(error.response)

    if (error.response.status === 401 || error.response.status === 403) {
        localStorage.removeItem('access_token');
        router.push('/login');
    }
    return error.response;
});

Vue.use(VCalendar, {
    componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});

Vue.use(VePagination);
Vue.use(VueEasytable);
Vue.use(VueSimpleAlert);
Vue.use(VueClipboard);
Vue.use(axios);
Vue.use(FlagIcon);
Vue.use(userApi);
Vue.use(convert);
Vue.use(configApi);
Vue.use(warehouseApi);
Vue.use(networkServiceApi);
Vue.use(serviceApi);

Vue.use(transactionApi);
Vue.use(creditApi);
Vue.use(simApi);
Vue.use(otpApi);
Vue.use(common);
Vue.use(VueLazyload, {
    lazyComponent: true
});
Vue.use(VueVirtualScroller)
Vue.component('RecycleScroller', RecycleScroller)

Vue.filter('formatDate', function (value) {
    if (value) {
        return moment.utc(String(value)).local().format("HH:mm:ss DD/MM/YYYY");
    }
});

const app = new Vue({
    vuetify,
    el: '#app',
    i18n,
    store,
    router,
    render: h => h(App)
});

export default app