import io from "socket.io-client";

let socketIO;

export default {
    createConnection: function() {
        // start socket.io server and cache io value

        socketIO = io.connect("https://apisim.codesim.vn?method=notifications-otp&user_code=" + localStorage.getItem('code')
            +'&user_id=' + localStorage.getItem('userId'), {
            transports: ['websocket']
        });

        // socketIO = io.connect("http://localhost:27056?method=notifications-otp&user_code=" + localStorage.getItem('code')
        //     +'&user_id=' + localStorage.getItem('userId'), {
        //     transports: ['websocket']
        // });

        if (!socketIO) {
            throw new Error("must call .createConnection(server) before you can call .getio()");
        }
        return socketIO;
    },

    getIO: function() {
        // return previously cached value
        if (!socketIO) {
            this.createConnection();
        }

        return socketIO;
    }
}

