<template>
  <div>
    <v-dialog v-model="value" persistent max-width="60%">
      <v-card class="elevation-16 mx-auto">
        <v-card-title>
          <span class="header-title">{{ isEdit ? $t('common.edit') : $t('common.add') }}</span>

          <v-spacer></v-spacer>
          <v-icon @click="cancelAction()">close</v-icon>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text>
          <v-form v-model="formValid" ref="myForm">
            <v-container align="center">
              <v-row no-gutters>
                <v-col cols="12" sm="6" class="pr-2 pt-5">
                  <v-text-field  v-model="editedItem.username" v-bind:label="$t('user_management.account')" :rules="isEdit ? [] : usernameRules" outlined
                                dense :readonly="isEdit" @keydown.space.prevent></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" class="pl-2 pt-5">
                  <v-text-field v-model="editedItem.full_name" label="6 số cuối số điện thoại"
                                oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                type = "number"
                                maxlength = "6"
                                :rules="isEdit ? [] :displayNameRules" outlined
                                :readonly="isEdit"
                                 dense></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="6" class="pr-2">
                  <v-numeric-field v-model="editedItem.limit_number" :rules="numberRules"
                                   v-bind:label="$t('user_management.thread')" outlined dense>
                  </v-numeric-field>
                </v-col>

                <v-col cols="12" sm="6" class="pl-2">
                  <v-autocomplete v-model="selectStatus" :items="userStatus" item-text="title"
                                  v-bind:label="$t('user_management.status')" return-object outlined dense>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" class="pr-2">
                  <v-autocomplete v-model="selectRole" :items="roles" item-text="content"
                                  v-bind:label="$t('user_management.role')" return-object outlined dense :readonly="isEdit">
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" class="pl-2" v-show="editedIndex == -1">
                  <v-text-field v-model="editedItem.password" :rules="passRules" v-bind:label="$t('user_management.password')" outlined :append-icon="show ? 'visibility' : 'visibility_off'" :type="show ? 'text' : 'password'" required @click:append="show = !show" dense></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" class="pl-2" v-show="isEdit">
                  <v-numeric-field v-model="editedItem.balance" :rules="numberRules"
                                   v-bind:label="$t('user_management.balance')" outlined dense>
                  </v-numeric-field>
                </v-col>

                <v-col cols="12" sm="12">
                  <v-text-field v-model="editedItem.content" v-bind:label="$t('user_management.note')" outlined
                                dense></v-text-field>
                </v-col>
              </v-row>

            </v-container>
          </v-form>

        </v-card-text>
        <v-divider></v-divider>
        <v-toolbar flat>
          <!--            <v-btn color="primary" @click="showResetPasswordDialog" class="mr-5" v-show="editedIndex !== -1">{{ $t('changePass.btn_title') }}</v-btn>-->
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="createUser" width="120px">
            {{ $t('common.ok') }}
          </v-btn>
        </v-toolbar>
      </v-card>

<!--      <v-overlay :value="overlay" :z-index="0">-->
<!--        <v-progress-circular indeterminate size="64"></v-progress-circular>-->
<!--      </v-overlay>-->
    </v-dialog>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n';
import VNumericField from "@/components/VNumericField";
import ImageInput from "@/components/ImageInput";
export default {
  name: 'user-dialog',

  props: {
    value: Boolean,
    editedItem: Object,
    editedIndex: Number
  },


  data: () => ({
    e1: 1,
    date: '',
    modal: false,
    show: true,
    formValid: false,
    dialog: false,
    overlay: false,
    isEdit: false,

    selectRole: {},
    roles: [],

    userStatus: [
      { title: i18n.t("common.new_user"), value: 2 },
      { title: i18n.t("user_management.active"), value: 1 },
      { title: i18n.t("user_management.lock"), value: 0 }
    ],

    selectStatus: {value: 1, title: i18n.t('user_management.active')},
    stringRules: [
      v => !!v || i18n.t('validate.string_require'),
      //   v => (v && v.length > 3) || i18n.t('validate.string_invalid')
    ],
    identifyRules: [
      v => (v && v.length > 8) || i18n.t('validate.indentify_invalid')
    ],
    phoneRules: [
      v => !!v || i18n.t('validate.string_require'),
      v => (v && v.length > 9) || i18n.t('validate.phone_invalid'),
    ],
    addressRules: [
      v => !!v || i18n.t('validate.string_require'),
      // v => (v && v.length > 5) || i18n.t('validate.address_invalid'),
    ],
    emailRules: [
      v => !!v || i18n.t('validate.email_require'),
      v => /.+@.+\..+/.test(v) || i18n.t('validate.email_invalid'),
    ],
    passRules: [
      v => !!v || i18n.t('validate.password_require'),
      v => (v && v.length > 2) || i18n.t('validate.password_invalid'),
    ],
    numberRules: [
      v => !!v || i18n.t('validate.not_empty'),
      v => (v && v > 0) || i18n.t('validate.string_invalid')
    ],
    displayNameRules: [
      v => !!v || i18n.t('validate.string_require'),
      v => (v && v.length === 6) || i18n.t('validate.string_invalid'),
    ],
    usernameRules: [
      v => !!v || i18n.t('validate.username_require'),
      v => /^[A-Za-z0-9]+$/.test(v) || i18n.t('validate.username_invalid')
    ],
  }),

  computed: {

  },

  watch: {

  },

  components: {
    VNumericField: VNumericField
  },

  async mounted() {

  },

  async created() {
    await this.getAllRole();

    if (this.editedIndex > -1) {
      this.isEdit = true;
      this.selectStatus = this.userStatus.find(status => status.value === this.editedItem.status)
      this.selectRole = this.roles.find(role => role.id === this.editedItem.role_id)
    }
  },

  methods: {
    async createUser() {
      this.$refs.myForm.validate();

      if (!this.validateUserInfor()) {
        return;
      }

      this.overlay = true;

      const self = this;
      const requestBody = {};
      this.editedItem.role_id = this.selectRole.id;
      this.editedItem.status = this.selectStatus.value;

      Object.assign(requestBody, self.editedItem);


      try {
        let response;
        if (this.isEdit) {
          console.log(requestBody);
          response = await this.$updateUser(requestBody);
        } else {
          console.log("new");
          response = await this.$createUser(requestBody);
        }

        this.overlay = false;

        if (response.status === 200) {
          this.$emit('updateUser', this.editedItem);
          this.$emit('closeDialog', false);
        } else if (response.data) {
          // alert(response.data);
          await this.$fire({
            title: i18n.t('common.error'),
            text: response.data,
            type: "error"
          });
          this.overlay = false;
        } else {
          alert(i18n.t('validate.request_error'));
        }
      } catch (e) {
        console.log(e.stack);
        alert(i18n.t('validate.request_error'));
      }
    },

    async getAllRole() {
      try {
        let response = await this.$getAllRole();

        if (response.status === 200) {
          this.roles = [].concat(response.data);
          if (this.editedIndex < 0) {
            if (this.roles.length > 0) {
              this.selectRole = this.roles[0];
            }
          }
        }
      } catch (e) {
        console.log(e.stack);
      }
    },

    validateUserInfor() {
      // if (!this.editedItem.username || this.editedItem.username.length == 0) {
      //   console.log("username invalid");
      //   return false;
      // }

      if (!this.isEdit) {
        if (!this.editedItem.password || this.editedItem.password.length == 0) {
          return false;
        }

        if (!/^[A-Za-z0-9]+$/.test(this.editedItem.username)) {
          return;
        }


        if (!this.editedItem.full_name || this.editedItem.full_name.length == 0) {
          return false;
        }

        if (this.editedItem.full_name && this.editedItem.full_name.length < 6) {
          return false;
        }
      }

      return true;
    },

    cancelAction() {
      this.$emit('closeDialog', false);
    }
  }
}
</script>

<style scoped>

.v-text-field .v-input__control .v-input__slot {
  min-height: 45px !important;
  /* display: flex !important; */
  align-items: center !important;
}
</style>
