<template>
  <v-container>
<!--    <vue-pdf-embed :source="urlPDF" width="1280"/>-->
    <v-row class="mt-5">
      <p class="text-h4" style="font-weight: bold;"> API Tích hợp </p>
    </v-row>

    <v-row>
      <div>
        <span class="text-h5 titleText"> 1. Lấy thông tin tài khoản </span> <br/>
        <span class="text-h5"> Phương thức: <span style="font-weight: bold;"> GET </span> </span> <br/>
        <span class="text-h5"> URL: </span>
        <a :href="accountURL" target="_blank">
          <span class="text-h5">{{ accountURL }}</span>
        </a> <br/>
        <span class="text-h5"> Tham số bắt buộc phải có: </span><br/>
        <span class="text-h5"> - <span style="font-weight: bold;"> api_key: </span> API key của tài khoản</span><br/>
        <span class="text-h5"> Kết quả trả về: </span>  <br/>
        <vue-json-pretty :data="accountFormat" style="background-color: #d9d9d9;"/> <br/>
      </div>
    </v-row>

    <v-row class="mt-6">
      <div>
        <span class="text-h5 titleText"> 2. Lấy danh sách dịch vụ hỗ trợ </span> <br/>
        <span class="text-h5"> Phương thức: <span style="font-weight: bold;"> GET </span> </span> <br/>
        <span class="text-h5"> URL: </span>
        <a :href="serviceURL" target="_blank">
          <span class="text-h5">{{ serviceURL }}</span>
        </a> <br/>
        <span class="text-h5"> Tham số bắt buộc phải có: </span><br/>
        <span class="text-h5"> - <span style="font-weight: bold;"> api_key: </span> API key của tài khoản</span><br/>
        <span class="text-h5"> Kết quả trả về: </span>  <br/>
        <vue-json-pretty :data="serviceFormat" style="background-color: #d9d9d9;"/> <br/>
      </div>
    </v-row>

    <v-row class="mt-6">
      <div>
        <span class="text-h5 titleText"> 3. Lấy danh sách nhà mạng hỗ trợ </span> <br/>
        <span class="text-h5"> Phương thức: <span style="font-weight: bold;"> GET </span> </span> <br/>
        <span class="text-h5"> URL: </span>
        <a :href="networkURL" target="_blank">
          <span class="text-h5">{{ networkURL }}</span>
        </a> <br/>
        <span class="text-h5"> Tham số bắt buộc phải có: </span><br/>
        <span class="text-h5"> - <span style="font-weight: bold;"> api_key: </span> API key của tài khoản</span><br/>
        <span class="text-h5"> Kết quả trả về: </span>  <br/>
        <vue-json-pretty :data="networkFormat" style="background-color: #d9d9d9;"/> <br/>
      </div>
    </v-row>

    <v-row class="mt-6">
      <div>
        <span class="text-h5 titleText"> 4. Lấy số điện thoại để nhận code</span> <br/>
        <span class="text-h5"> Phương thức: <span style="font-weight: bold;"> GET </span> </span> <br/>
        <span class="text-h5"> URL: </span>
        <a :href="getSimURLShort" target="_blank">
          <span class="text-h5">{{ getSimURLShort }}</span>
        </a> <br/>
        <span class="text-h5"> Tham số bắt buộc phải có: </span><br/>
        <span class="text-h5"> - <span style="font-weight: bold;"> api_key: </span> API key của tài khoản</span><br/>
        <span class="text-h5"> - <span style="font-weight: bold;"> service_id: </span> Id của dịch vụ được lấy theo api lấy danh sách dịch vụ ở trên</span><br/>
        <span class="text-h5"> Tham số không bắt buộc: </span><br/>
        <span class="text-h5"> - <span style="font-weight: bold;"> phone: </span> Tùy chọn đầu số muốn lấy</span><br/>
        <span class="text-h5"> - <span style="font-weight: bold;"> network_id: </span> Id của nhà mạng được lấy theo api lấy danh sách nhà mạng ở trên</span><br/>
        <span class="text-h5"> - Ví dụ:
          <a :href="getSimURL" target="_blank">
            <span class="text-h5">{{ getSimURL }}</span>
          </a>
        </span>  <br/>
        <span class="text-h5"> Kết quả trả về:</span>  <br/>
        <vue-json-pretty :data="simFormat" style="background-color: #d9d9d9;"/> <br/>
      </div>
    </v-row>

    <v-row class="mt-6">
      <div>
        <span class="text-h5 titleText"> 5. Kiểm tra trạng thái lấy code của số đang chờ</span> <br/>
        <span class="text-h5"> Phương thức: <span style="font-weight: bold;"> GET </span> </span> <br/>
        <span class="text-h5"> URL: </span>
        <a :href="checkOTPURL" target="_blank">
          <span class="text-h5">{{ checkOTPURL }}</span>
        </a> <br/>
        <span class="text-h5"> Tham số bắt buộc phải có: </span><br/>
        <span class="text-h5"> - <span style="font-weight: bold;"> api_key: </span> API key của tài khoản</span><br/>
        <span class="text-h5"> - <span style="font-weight: bold;"> otp_id: </span> Thông tin otpId của số đã lấy được theo api ở mục 4 bên trên</span><br/>
        <span class="text-h5" style="color: red; font-weight: bold; font-style: italic"> Lưu ý: Gọi kết quả để kiểm tra code, độ trễ 4 giây/lần call api.
          Gọi API nhanh quá sẽ bị khóa tài khoản vĩnh viễn</span><br/>
        <span class="text-h5"> Kết quả trả về: </span>  <br/>
        <vue-json-pretty :data="otpFormat" style="background-color: #d9d9d9;"/>  <br/>
      </div>
    </v-row>

    <v-row class="mt-6">
      <div>
        <span class="text-h5 titleText"> 6. Hủy số chờ nhận code</span> <br/>
        <span class="text-h5"> Phương thức: <span style="font-weight: bold;"> GET </span> </span> <br/>
        <span class="text-h5"> URL: </span>
        <a :href="cancelSimURL" target="_blank">
          <span class="text-h5">{{ cancelSimURL }}</span>
        </a> <br/>
        <span class="text-h5"> Tham số bắt buộc phải có: </span><br/>
        <span class="text-h5"> - <span style="font-weight: bold;"> api_key: </span> API key của tài khoản</span><br/>
        <span class="text-h5"> - <span style="font-weight: bold;"> sim_id: </span> Thông tin simId của số đã lấy được theo api ở mục 4 bên trên</span><br/>
        <span class="text-h5"> - Ví dụ:
          <a :href="cancelSimURLSample" target="_blank">
            <span class="text-h5">{{ cancelSimURLSample }}</span>
          </a>
        </span>  <br/>
        <span class="text-h5"> Kết quả trả về: </span>  <br/>
        <vue-json-pretty :data="cancelSimFormat" style="background-color: #d9d9d9;"/>  <br/>
      </div>
    </v-row>

    <v-row class="mt-6">
      <div>
        <span class="text-h5 titleText"> 7. Thuê lại số đã lấy code thành công </span> <br/>
        <span class="text-h5"> Phương thức: <span style="font-weight: bold;"> GET </span> </span> <br/>
        <span class="text-h5"> URL: </span>
        <a :href="reuseSimURL" target="_blank">
          <span class="text-h5">{{ reuseSimURL }}</span>
        </a> <br/>
        <span class="text-h5"> Tham số bắt buộc phải có: </span><br/>
        <span class="text-h5"> - <span style="font-weight: bold;"> api_key: </span> API key của tài khoản</span><br/>
        <span class="text-h5"> - <span style="font-weight: bold;"> phone: </span> Số điện thoại đã lấy được theo api ở mục 4 bên </span><br/>
        <span class="text-h5"> - Ví dụ:
          <a :href="reuseSimSample" target="_blank">
            <span class="text-h5">{{ reuseSimSample }}</span>
          </a>
        </span>  <br/>
        <span class="text-h5" style="color: red; font-weight: bold; font-style: italic"> Lưu ý: Chỉ áp dụng với số đã lấy code thành công và của chính tài khoản này</span><br/>
        <span class="text-h5"> Kết quả trả về: </span>  <br/>
        <vue-json-pretty :data="reuseSimFormat" style="background-color: #d9d9d9;"/>  <br/>
      </div>
    </v-row>

  </v-container>
</template>

<script>

// import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  data: () => ({
    editedItem: null,
    accountURL: process.env.VUE_APP_BASE_URL + "/yourself/information-by-api-key?api_key=",
    accountFormat: {
      timestamp: "2023-06-29T03:19:57Z",
      status: 200,
      message: "Thành công",
      data: {
        id: 28527,
        phone: "",
        balance: 46100,
        status: 1,
        username: "abababab",
        last_login: "2023-06-27T08:59:48Z"
      }
    },

    serviceURL: process.env.VUE_APP_BASE_URL + "/service/get_service_by_api_key?api_key=",
    serviceFormat: {
      timestamp: "2023-06-29T03:57:00Z",
      status: 200,
      message: "Thành công",
      data: [
        {
          id: 1,
          name: "Facebook",
          code: "SE22Najq",
          price: 1200
        },
        {
          id: 2,
          name: "Gmail",
          code: "SE22Naa3da",
          price: 1000
        }
      ]
    },

    networkURL: process.env.VUE_APP_BASE_URL + "/network/get-network-by-api-key?api_key=",
    networkFormat: {
      timestamp: "2023-06-29T04:01:01Z",
      status: 200,
      message: "Thành công",
      data: [
        {
          id: 1,
          name: "VIETTEL",
          code: "NE22Z099RJ",
          status: 1,
          create_date: "2022-11-21T22:24:03Z"
        },
        {
          id: 2,
          name: "MOBIFONE",
          code: "NE22HK2QI1",
          status: 1,
          create_date: "2022-11-21T22:24:30Z"
        }
      ]
    },

    getSimURLShort: process.env.VUE_APP_BASE_URL + "/sim/get_sim?service_id=1&api_key=",
    getSimURL: process.env.VUE_APP_BASE_URL + "/sim/get_sim?service_id=1&network_id=1&phone=098&api_key=",
    simFormat: {
      timestamp: "2023-03-09T15:59:07Z",
      status: 200,
      message: "Thành công",
      data: {
        otpId: 139126,
        simId: 7280,
        payment: 800.0,
        phone: "0569680819",
        createDate: "2023-03-09T15:59:07Z",
        serviceName: "Fastex",
        serviceId: 60
      }
    },

    checkOTPURL: process.env.VUE_APP_BASE_URL + "/otp/get_otp_by_phone_api_key?otp_id=0388&api_key=",
    otpFormat: {
      timestamp: "2023-03-09T18:38:15Z",
      status: 200,
      message: "Thành công",
      data: {
        idOtp: 139126,
        phone: "0888710471",
        updateDate: "2023-03-09T18:37:10Z",
        content: "H123446 gửi thông báo ",
        audio: null,
        code: "123446",
        senderName: "Fastex",
        serviceName: "Fastex"
      }
    },

    cancelSimURLSample: process.env.VUE_APP_BASE_URL + "/sim/cancel_api_key/7280?api_key=",
    cancelSimURL: process.env.VUE_APP_BASE_URL + "/sim/cancel_api_key/{{sim_id}}?api_key=",
    cancelSimFormat: {
      timestamp: "2023-03-09T15:59:07Z",
      status: 200,
      message: "Thành công",
      data: "Hủy thành công"
    },

    reuseSimSample: process.env.VUE_APP_BASE_URL + "/sim/reuse_by_phone_api_key?phone=038888888&service_id=3&api_key=",
    reuseSimURL: process.env.VUE_APP_BASE_URL + "/sim/reuse_by_phone_api_key?phone={{phone}}&service_id=3&api_key=",
    reuseSimFormat: {
      timestamp: "2023-03-09T18:32:16Z",
      status: 200,
      message: "Thành công",
      data: {
        otpId: 139126,
        simId: 7672,
        payment: 800.0,
        phone: "0888710471",
        createDate: "2023-03-09T18:32:16Z",
        serviceName: "Fastex",
        serviceId: 60
      }
    },
  }),

  components: {
    // VuePdfEmbed
  },

  watch: {
  },

  computed: {
  },

  async created () {
    const response = await this.$getInformationUser();
    if (response.status === 200) {
      this.editedItem = response.data;
      this.accountURL = this.accountURL + this.editedItem.api_key;
      this.serviceURL = this.serviceURL + this.editedItem.api_key;
      this.networkURL = this.networkURL + this.editedItem.api_key;
      this.getSimURL = this.getSimURL + this.editedItem.api_key;
      this.getSimURLShort = this.getSimURLShort + this.editedItem.api_key;
      this.checkOTPURL = this.checkOTPURL + this.editedItem.api_key;

      this.cancelSimURL = this.cancelSimURL + this.editedItem.api_key;
      this.cancelSimURLSample = this.cancelSimURLSample + this.editedItem.api_key;
      this.reuseSimURL = this.reuseSimURL + this.editedItem.api_key;
      this.reuseSimSample = this.reuseSimSample + this.editedItem.api_key;
    }
  },

  methods: {

  }
}
</script>

<style scoped>
  .titleText {
    font-weight: bold;
    color: #c00000;
  }

  .linkText {
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
  }
</style>