import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@/styles/styles.sass'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: '#228EF0',
                secondary: '#b0bec5',
                accent: '#8c9eff',
                error: '#b71c1c',
                text: '#1D243E',
                white: '#ffffff',
                background: '#E5E5E5',
                cancel: "#FF586B"
            },
        },
    },
    customVariables: ['~/styles/variables.scss'],
});
// #570f63