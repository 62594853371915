<template>
  <v-container fluid>
    <div>
      <v-row justify="space-between">
        <v-col cols="12" sm="6" justify="center">
          <p class="header-title">{{ $t("menu.user_management") }}</p>
        </v-col>

        <v-col cols="12" sm="6">
          <div class="d-flex flex-row-reverse">
            <v-btn class="ml-4" color="primary" dark @click="createNewUser">{{
                $t("common.create")
              }}</v-btn>

            <v-btn class="ml-4" color="primary" dark @click="exportToExcel">
                Export
             </v-btn>

            <v-btn class="ml-4" color="primary" dark @click="reloadData">{{
                $t("common.refresh")
              }}</v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-data-table
        :headers="headers"
        :items="desserts"
        class="elevation-1"
        hide-default-footer
        disable-filtering
        disable-sort
        :loading="loading"
        @dblclick:row="editItem"
        :items-per-page="itemsPerPage"
    >
      <template v-slot:top>
        <v-toolbar flat color="white" class="pt-5 mb-2">
          <v-row justify="start">
            <v-col cols="12" sm="2">
              <v-autocomplete
                  v-model="itemsPerPage"
                  :items="numberPage"
                  outlined
                  :dense="true"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="2">
              <v-autocomplete v-model="selectRole" :items="roles" item-text="content"
                              v-bind:label="$t('user_management.role')" return-object outlined dense>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="2">
              <v-autocomplete
                  v-model="selectStatus"
                  :items="userStatus"
                  item-text="title"
                  v-bind:label="$t('user_management.status')"
                  return-object
                  outlined
                  :dense="true"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="4">
              <v-text-field
                  append-icon="search"
                  v-model="search"
                  v-bind:label="$t('common.search')"
                  single-line
                  hide-details
                  outlined
                  :dense="true"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="2">
              <v-btn color="primary" dark @click="reloadData">{{
                  $t("common.search")
                }}</v-btn>
            </v-col>
          </v-row>
          <!-- </v-container> -->

        </v-toolbar>
        <div justify="start">
          <span class="defaultFont">Tổng số: <span class="defaultBoldFont"> {{ $thousandSeprator(totalItem) }}</span> người dùng.
            Tổng số tiền: <span class="defaultBoldFont"> {{ $thousandSeprator(totalMoney) }}đ</span></span>
        </div>
      </template>

<!--      <template v-slot:[`item.role_id`]="{ item }">-->
<!--        <span class="text-no-wrap">{{ $roleString(item.role_id) }}</span>-->
<!--      </template>-->

      <template v-slot:[`item.status`]="{ item }">
        <span class="text-no-wrap">{{ $statusString(item.status) }}</span>
      </template>

      <template v-slot:[`item.create_date`]="{ item }">
        <span class="text-no-wrap">{{ item.create_date | formatDate }}</span>
      </template>

      <template v-slot:[`item.last_login`]="{ item }">
        <span class="text-no-wrap">{{ item.last_login | formatDate }}</span>
      </template>

      <template v-slot:[`item.balance`]="{ item }">
        <span class="text-no-wrap">{{ $thousandSeprator(item.balance) }}</span>
      </template>

<!--      <template v-slot:[`item.limit_number`]="{ item }">-->
<!--        <span class="text-no-wrap">{{ $thousandSeprator(item.limit_number) }}</span>-->
<!--      </template>-->

      <template v-slot:[`item.action`]="{ item }">
        <div class="contentTitle">
          <v-tooltip
              slot="append-outer"
              bottom
              max-width="300px"
              color="primary"
          >
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" size="12px" color="white" class="mr-1 iconBorderGreen" @click="showCreditDialog(item)">
                currency_exchange
              </v-icon>
            </template>
            <span> {{$t("user_management.top_up_history")}} </span>
          </v-tooltip>

          <v-tooltip
              slot="append-outer"
              bottom
              max-width="300px"
              color="primary"
          >
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" size="12px" color="white" class="mr-1 iconBorderGreen" @click="showOTPHistoryDialog(item)">
                chat
              </v-icon>
            </template>
            <span>{{$t("user_management.otp_history")}}</span>
          </v-tooltip>

          <v-tooltip
              slot="append-outer"
              bottom
              max-width="300px"
              color="primary"
          >
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" size="12px" color="white" class="mr-1 iconBorderGreen" @click="lockUser(item)" v-show="item.status === 1 || item.status === 2">
                lock
              </v-icon>
            </template>
            <span>Khóa tài khoản</span>
          </v-tooltip>

          <v-tooltip
              slot="append-outer"
              bottom
              max-width="300px"
              color="primary"
          >
            <template v-slot:activator="{ on }" >
              <v-icon v-on="on" size="12px" color="white" class="mr-1 iconBorderRed" @click="unLockUser(item)" v-show="item.status === 0">
                lock_open
              </v-icon>
            </template>
            <span>Mở khóa tài khoản</span>
          </v-tooltip>

          <v-tooltip
              slot="append-outer"
              bottom
              max-width="300px"
              color="primary"
          >
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" size="12px" color="white" class="iconBorderRed" @click="showResetPass(item)">
                settings
              </v-icon>
            </template>
            <span>{{$t("user_management.reset_password")}}</span>
          </v-tooltip>
        </div>

      </template>

      <template v-slot:no-data>
        <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
      </template>
    </v-data-table>

    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="pageCount"></v-pagination>
    </div>

    <user-dialog
        v-model="dialogEditUser"
        :editedItem="editedItem"
        :editedIndex="editedIndex"
        @closeDialog="closeEditUser"
        @updateUser="updateUser"
        v-if="dialogEditUser">
    </user-dialog>

    <credit-history-dialog
        v-model="dialogCreditUser"
        :editedItem="editedItem"
        @closeDialog="closeCreditDialog"
        v-if="dialogCreditUser">
    </credit-history-dialog>

    <otp-history-dialog
        v-model="dialogOTPUser"
        :editedItem="editedItem"
        @closeDialog="closeOTPHistoryDialog"
        v-if="dialogOTPUser">
    </otp-history-dialog>


    <v-dialog v-model="resetPassDialog" max-width="400px">
      <v-card class="elevation-16">
        <v-card-title>
          <span class="header-title"> {{ $t('user_management.reset_password')}}</span>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-container fluid>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="12">
                <v-text-field v-model="password" :append-icon="showNew ? 'visibility' : 'visibility_off'" :rules="passdRules" v-bind:label="$t('user_management.new_password')"
                              outlined :type="showNew ? 'text' : 'password'" required @click:append="showNew = !showNew" dense> </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-spacer/>
          <v-btn dark color="primary" @click="resetPassword">
            {{ $t('common.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        :color="color"
        :timeout="2000"
        top
        right
        elevation="24"
        class="mt-5"
    >
      {{ message }}
    </v-snackbar>

    <v-overlay :value="overlay" :z-index="0">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>


</template>

<script>

import i18n from "@/plugins/i18n";
// import moment from "moment";
import UserDialog from "@/components/UserDialog";
import CreditHistoryDialog from "@/components/CreditHistoryDialog";
import OtpHistoryDialog from "@/components/OtpHistoryDialog";
import moment from "moment";
import * as XLSX from 'xlsx'

export default {
  data: () => ({
    show: false,
    e1: 0,
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    isEdit: false,
    overlay: false,
    snackbar: false,
    message: '',
    color: '#0C8F4D',
    dialogCreditUser: false,
    dialogOTPUser: false,

    resetPassDialog: false,
    password: '',
    confirmPassword: '',
    showNew: false,
    showConfirm: false,
    valid: true,
    totalItem: 0,
    selectRole: { content: i18n.t("common.all"), id: -1 },
    roles: [],

    loading: true,
    numberPage: [50, 100, 200, 300, 500, 1000, 3000, 5000],

    modal: false,

    userStatus: [
      { title: i18n.t("common.all"), value: -1 },
      { title: i18n.t("common.active"), value: 1 },
      { title: i18n.t("common.lock"), value: 0 },
      { title: i18n.t("common.new_user"), value: 2 },
    ],

    selectStatus: { title: i18n.t("common.all"), value: -1 },

    firstEdit: true,
    search: "",
    dialog: false,
    totalMoney: 0,
    headers: [
      { text: "#", align: "left", sortable: false, searchable: false, value: "index" },
      { text: i18n.t("user_management.account"), value: "username", sortable: false, searchable: false },
      { text: i18n.t("user_management.balance"), value: "balance", sortable: false, searchable: false },
      { text: i18n.t("user_management.limit_number"), value: "limit_number", sortable: false, searchable: false },
      { text: i18n.t("user_management.six_phone"), value: "full_name", sortable: false, searchable: false },
      { text: i18n.t("user_management.created_date"), value: "create_date", sortable: false, searchable: false },
      { text: i18n.t("user_management.last_update"), value: "last_login", sortable: false, searchable: false},
      // { text: i18n.t("user_management.phone"), value: "phone", sortable: true },

      { text: i18n.t("user_management.note"), value: "content", sortable: false, searchable: false },
      { text: i18n.t("user_management.top_up_code"), value: "code", sortable: false, searchable: false },
      { text: i18n.t("user_management.status"), value: "status", sortable: false, searchable: false},
      { text: '', align: "center", value: "action", sortable: false, searchable: false}
    ],

    desserts: [],
    fullStaff: [],
    editedIndex: -1,
    editedItem: null,
    defaultItem: {},

    dialogEditUser: false,

    stringRules: [
      (v) => !!v || i18n.t("validate.not_empty"),
      //   v => (v && v.length > 3) || i18n.t('validate.string_invalid')
    ],
    identifyRules: [
      (v) => (v && v.length > 8) || i18n.t("validate.indentify_invalid"),
    ],
    phoneRules: [
      (v) => !!v || i18n.t("validate.not_empty"),
      (v) => (v && v.length > 9) || i18n.t("validate.phone_invalid"),
    ],
    addressRules: [
      (v) => !!v || i18n.t("validate.not_empty"),
      // v => (v && v.length > 5) || i18n.t('validate.address_invalid'),
    ],
    emailRules: [
      (v) => !!v || i18n.t("validate.email_require"),
      (v) => /.+@.+\..+/.test(v) || i18n.t("validate.email_invalid"),
    ],
    passdRules: [
      (v) => !!v || i18n.t("validate.password_require"),
      (v) => (v && v.length > 5) || i18n.t("validate.password_invalid"),
    ],
  }),

  computed: {
    vertifyPassword() {
      return this.password === this.confirmPassword ? '' : i18n.t('user_management.password_not_match');
    }
  },


  components: {
    UserDialog: UserDialog,
    CreditHistoryDialog: CreditHistoryDialog,
    OtpHistoryDialog: OtpHistoryDialog
  },

  watch: {
    itemsPerPage() {
      this.reloadData();
    },

    page() {
      this.getData();
    },

    // selectRole() {
    //   this.reloadData();
    // },
    //
    // selectStatus() {
    //   this.reloadData();
    // },
  },

  async created() {
    await Promise.all([
      await this.getData(),
      await this.getAllRole(),
      await this.getStatistic()
    ])
  },

  mounted() {
    window.onpopstate = event => {
      this.$router.push("staffManagement");
    };
  },

  methods: {
    async getData() {
      this.loading = true;

      // const response = await this.$getAllUser(this.selectRole.id, this.selectStatus.value, this.page - 1, this.itemsPerPage);
      const response = await this.$searchUserCombine(this.search, this.selectRole.id, this.selectStatus.value, this.page - 1, this.itemsPerPage);
      this.loading = false;

      if (response.data && response.status === 200) {
        this.desserts = [].concat(response.data.content);
        // this.desserts = this.desserts.filter(
        //     (item) => item.username !== localStorage.getItem("username")
        // );

        this.pageCount = response.data.totalPages;
        // this.totalItem = response.data.totalElements;
        // this.totalMoney = response.data.totalPayment;
        this.desserts.forEach(
            (item, index) => {
              item.index = (this.page - 1) * this.itemsPerPage + index + 1;
            }
        );

        // this.desserts = contextArr;
      } else {
        this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
        this.message = i18n.t('common.error')
        this.snackbar = true;
      }
    },

    async getStatistic() {
      this.loading = true;
      const response = await this.$summaryBalance(this.search, this.selectRole.id, this.selectStatus.value);
      this.loading = false;
      if (response.status === 200) {
        this.totalItem = response.data.total_user;
        this.totalMoney = response.data.total_balance;
      }
    },

    async exportToExcel() { // On Click Excel download button
      this.overlay = true
      const response = await this.$searchUserCombine(this.search, this.selectRole.id, this.selectStatus.value, this.page - 1, this.totalItem);
      this.overlay = false
      if (response.data && response.status === 200) {
        const responseData = response.data.content;
        const rows = responseData.map(row => ({
          username: row.username,
          balance: row.balance,
          full_name: row.full_name,
          create_date: moment.utc(String(row.create_date)).local().format("HH:mm:ss DD/MM/YYYY"),
          last_login: moment.utc(String(row.last_login)).local().format("HH:mm:ss DD/MM/YYYY"),
          content: row.content,
          code: row.code,
          status: this.$statusString(row.status)
        }));

        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "TK");

        XLSX.utils.sheet_add_aoa(worksheet, [["Tài khoản", "Số dư", "6 số cuối", "Ngày đăng ký", "Lần đăng nhập cuối", "Ghi chú", "Mã nạp tiền", "Trạng thái"]], { origin: "A1" });
        /* calculate column width */
        const max_width_username = rows.reduce((w, r) => Math.max(w, r.username.length), 15);
        const max_width_date = rows.reduce((w, r) => Math.max(w, r.create_date.length), 15);
        const max_width_status = rows.reduce((w, r) => Math.max(w, r.status.length), 15);

        worksheet["!cols"] = [
          { wch: max_width_username },
          { wch: max_width_username },
          { wch: max_width_username },
          { wch: max_width_date },
          { wch: max_width_date },
          { wch: max_width_username },
          { wch: max_width_username },
          { wch: max_width_status }
        ];
        // export Excel file
        XLSX.writeFile(workbook, "TK.xlsx", { compression: true });
      }
    },

    reloadData() {
      this.page = 1;
      Promise.all([
        this.getData(),
        this.getStatistic()
      ]);
    },

    async getAllRole() {
      try {
        let response = await this.$getAllRole();

        if (response.status === 200) {
          this.roles = [{ content: i18n.t("common.all"), id: -1 }].concat(response.data);
        }
      } catch (e) {
        console.log(e.stack);
      }
    },

    createNewUser() {
      this.editedIndex = -1;
      this.editedItem = {
        limit_number: 2
      };

      this.dialogEditUser = true;
    },

    closeEditUser(value) {
      this.dialogEditUser = value;
      this.editedItem = null;
      this.editedIndex = -1;
    },

    updateUser(value) {
      if (this.editedIndex > -1) {
        this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
        this.message = i18n.t('common.update_success');
        this.snackbar = true;

        Object.assign(this.desserts[this.editedIndex], value);
      } else {
        this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
        this.message = i18n.t('common.add_success')
        this.snackbar = true;

        this.editedIndex = -1;
        this.getData();
      }
    },

    editItem(event, {item}) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEditUser = true;
    },

    showCreditDialog(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogCreditUser = true;
    },

    closeCreditDialog(value) {
      this.dialogCreditUser = value;
      this.editedItem = null;
      this.editedIndex = -1;
    },

    showOTPHistoryDialog(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogOTPUser = true;
    },

    closeOTPHistoryDialog(value) {
      this.dialogOTPUser = value;
      this.editedItem = null;
      this.editedIndex = -1;
    },

    async lockUser(item) {
      item.status = 0;

      const response = await this.$updateUser(item);
      if (response.status === 200) {
        this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
        this.message = 'Cập nhật thành công!'
        this.snackbar = true;
      } else {
        this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
        this.message = 'Cập nhật không thành công!'
        this.snackbar = true;
        item.status = 1;
      }
    },

    async unLockUser(item) {
      item.status = 1;
      const response = await this.$updateUser(item);
      if (response.status === 200) {
        this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
        this.message = 'Cập nhật thành công!'
        this.snackbar = true;
      } else {
        this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
        this.message = 'Cập nhật không thành công!'
        this.snackbar = true;

        item.status = 0;
      }
    },

    showResetPass(item) {
      this.editedItem = item;
      this.resetPassDialog = true;
    },

    async resetPassword() {
      if (!this.password || this.password.length == 0) {
        return;
      }

      const requestData = {
        password: this.password,
        user_id: this.editedItem.id
      }
      const response = await this.$resetPassword(requestData);
      if (response.status == 200) {
        this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
        this.message = i18n.t('user_management.change_pw_success')
        this.snackbar = true;

        this.resetPassDialog = false;
      } else if (response.data) {
        this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
        this.message = 'Cập nhật không thành công!'
        this.snackbar = true;
      } else {
        alert(i18n.t('validate.request_error'));
      }
    }
  }
};
</script>

<style scoped>
/*.v-text-field .v-input__control .v-input__slot {*/
/*  min-height: 45px !important;*/
/*  !* display: flex !important; *!*/
/*  align-items: center !important;*/
/*}*/
</style>
