<template>
  <div>
    <v-dialog v-model="value" max-width="80%">
      <v-card class="elevation-16 mx-auto">
        <v-card-title>
          <span class="header-title">Tìm người nạp</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-data-table :headers="headers" :items="desserts" :search="search" class="elevation-1" hide-default-footer
                        :page.sync="page" :loading="loading" :items-per-page="itemsPerPage"
                        @page-count="pageCount = $event">
            <template v-slot:top>
              <v-container fluid>
                <v-row no-gutters>
                  <v-col cols="12" sm="12">
                    <v-textarea style="font-size: 13px !important;" height="80px" v-model="editedItem.content" v-bind:label="$t('credit_management.content')" outlined dense readonly></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="10">
                    <v-text-field v-model="username" append-icon="search" v-bind:label="$t('common.search')" single-line outlined :dense="true"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="2">
                    <v-btn class="ml-2" depressed color="primary" @click="searchUser">
                      Tìm kiếm
                    </v-btn>
                  </v-col>

                </v-row>
              </v-container>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <div class="contentTitle">
                <v-tooltip
                    slot="append-outer"
                    bottom
                    max-width="300px"
                    color="primary"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" size="12px" color="white" class="mr-1 iconBorderGreen" @click="addMoneyForUser(item)">
                      currency_exchange
                    </v-icon>
                  </template>
                  <span> Nạp tiền cho người dùng </span>
                </v-tooltip>
              </div>
            </template>

            <template v-slot:no-data>
              <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
            </template>
          </v-data-table>

<!--          <div class="text-center pt-2">-->
<!--            <v-pagination v-model="page" :length="pageCount"></v-pagination>-->
<!--          </div>-->
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions class="justify-space-between">
          <v-spacer></v-spacer>
          <v-btn color="primary" dark @click="closeDialog">{{ $t('common.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n';
// import moment from 'moment';

export default {
  name: 'searching-user-dialog',

  props: {
    value: Boolean,
    editedItem: Object
  },


  data: () => ({
    loading: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 1000,

    search: '',
    username: '',
    headers: [
      {text: '#', align: 'left', sortable: false, value: 'index',},
      { text: i18n.t("user_management.account"), value: "username", sortable: false },
      { text: i18n.t("user_management.six_phone"), value: "full_name", sortable: true },
      { text: i18n.t("user_management.top_up_code"), value: "code", sortable: false},
      { text: '', align: "center", value: "action", sortable: false}
    ],
    desserts: [],
  }),

  computed: {},

  watch: {},

  mounted() {

  },

  async created() {

  },


  methods: {
    async addMoneyForUser(item) {
      const requestBody = {
        user_id: item.id
      }

      const response = await this.$updateCredit(this.editedItem.id, requestBody);
      if (response.status == 200) {
        this.$emit('updateCredit', this.editedItem);
        this.$emit('closeDialog', false);
      } else {
        await this.$fire({
          title: i18n.t('common.error'),
          text: response.data,
          type: "error"
        });
      }
    },

    closeDialog() {
      this.$emit('closeDialog', false);
    },

    async searchUser() {
      // const response = await this.$searchUserCombine(this.username);
      const response = await this.$searchUserCombine(this.username, -1, -1, this.page - 1, this.itemsPerPage);

      if (response.status == 200) {
        this.desserts = [].concat(response.data.content);
        this.desserts.forEach(
            (item, index) => {
              item.index = (this.page - 1) * this.itemsPerPage + index + 1;
            }
        );
      }
    }
  }
}
</script>

<style scoped>
.v-text-field .v-input__control .v-input__slot {
  min-height: 45px !important;
  /* display: flex !important; */
  align-items: center !important;
}
</style>
