<template>
    <div class="wrapper">
        <div class="d-flex justify-space-between">
            <div class="wrap-text">
                <span>{{ $t('top_up_management.create_date') }}: </span> <span>{{data.create_date | formatDate }}</span>
            </div>
            <span :class='$statusCredit(data) + " status-container"'>{{ $creditStatusString(data.status) }}</span>
        </div>
        <div class="wrap-text">
            {{ $t('credit_management.bank') }}: <span>{{ data.bank }}</span>
        </div>
        <div class="wrap-text">
            {{ $t('credit_management.payment') }}: <span>{{ $thousandSeprator(data.payment) }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "HistoryItem",
    props: {
        data: Object
    }
}
</script>

<style scoped>
.wrapper {
    padding: 8px 15px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    margin-bottom: 12px;
}
.status-container {
    font-size: 12px;
    margin: 0;
    height: 19px;
    display: flex;
    align-items: center
}

.wrap-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>