import Vue from "vue";

export default {
    install() {
        Vue.prototype.$getAllRole = async function () {
            let result = null;

            try {
                const response = await this.$http.get(process.env.VUE_APP_GET_ALL_ROLE_URL);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$getAllConfig = async function () {
            let result = null;

            try {
                const response = await this.$http.get(process.env.VUE_APP_GET_ALL_CONFIG_API);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };


        Vue.prototype.$createConfig = async function (requestData) {
            let result = null;
            try {
                const response = await this.$http.post(process.env.VUE_APP_CREATE_CONFIG_API, JSON.stringify(requestData));
                if (response.data) {
                    result = response.data;
                }
                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };
    },
};