import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';

Vue.use(VueLocalStorage);

export default {
  namespaced: true,
  state: {
    isLogin: Vue.localStorage.get('isLogin'),
  },
  mutations: {
    SAVE_STATE(state, value) {
      Vue.localStorage.set('isLogin', value);
      state.isLogin = value;
    },
  },
  actions: {
    setStateLogin({ commit }, value) {
        commit('SAVE_STATE', value);
    },
  },
};