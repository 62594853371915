<template>
    <div>
        <v-app-bar app color="primary" dark>
            <!--      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>-->
            <template v-if="isMobile">
                <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                <v-spacer/>
            </template>
            <div v-if="!isMobile" class="mr-5" style="width: calc(100vw - 400px); display: inline-block; white-space: nowrap;">
                <marquee-text>
                    &nbsp;&nbsp; {{ userNotification }}
                </marquee-text>
            </div>

            <v-spacer/>
            <v-btn color="#0cc37d" to="topUpManagement" flat class="mr-2" v-if="isCustomer">Nạp tiền</v-btn>
            <div class="titleName"> {{ username }} ({{ $thousandSeprator(balance) }} đ)</div>
            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <!--          <v-avatar dark v-on="on" size="45px">-->
                    <!--            <img v-if="avatar" :src="avatar"/>-->
                    <!--            <img v-else src='@/assets/avatar_default.png'/>-->
                    <!--          </v-avatar >-->
                    <v-icon v-on="on" size="32px">
                        person_outline
                    </v-icon>
                </template>
                <v-list>
                    <v-list-item v-for="(item, index) in menus" :key="index" @click="selectItem(index)">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <v-main>
            <div class="d-flex flex-row align-stretch">
                <!-- temporary -->
                <v-navigation-drawer
                        :temporary="isMobile"
                        :permanent="!isMobile"
                        :expand-on-hover="!isMobile"
                        :touchless="true"
                        v-model="drawer"
                        app
                        dark
                        width="300px"
                        overlay-color="primary"
                        class="blue darken-1"
                >
                    <template v-if="!isMobile">
                        <v-list>
                            <v-list-item class="px-2">
                                <!--              <v-img src='@/assets/logo_icon2.png' max-height="50px" max-width="80px"/>-->
                            </v-list-item>
                        </v-list>

                        <v-divider></v-divider>
                    </template>

                    <v-list>
                        <v-list-item to="staffManagement" v-show="isAdmin" active-class="bg-active">
                            <v-list-item-icon>
                                <v-icon>person</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ $t('menu.user_management') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item to="SIMManagement" v-show="isAdmin" active-class="bg-active">
                            <v-list-item-icon>
                                <v-icon>sd_storage</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ $t('menu.sim_management') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item to="dealerSIMManagement" v-show="isDealer" active-class="bg-active">
                            <v-list-item-icon>
                                <v-icon>sd_storage</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ $t('menu.sim_management') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>


                        <v-list-item to="buyCodeManagement" v-show="isCustomer" active-class="bg-active">
                            <v-list-item-icon>
                                <v-icon>input</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ $t('menu.buy_code') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item to="customerOTPManagement" v-show="isCustomer" active-class="bg-active">
                            <v-list-item-icon>
                                <v-icon>textsms</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ $t('menu.otp_management') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item to="OTPManagement" v-show="isAdmin" active-class="bg-active">
                            <v-list-item-icon>
                                <v-icon>textsms</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ $t('menu.otp_management') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item to="dealerOTPManagement" v-show="isDealer" active-class="bg-active">
                            <v-list-item-icon>
                                <v-icon>textsms</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ $t('menu.otp_management') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item to="transactionManagement" v-show="isAdmin" active-class="bg-active">
                            <v-list-item-icon>
                                <v-icon>payment</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ $t('menu.payment_management') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item to="creditManagement" v-show="isAdmin" active-class="bg-active">
                            <v-list-item-icon>
                                <v-icon>currency_exchange</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ $t('menu.credit_management') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item to="topUpManagement" v-show="isCustomer" active-class="bg-active">
                            <v-list-item-icon>
                                <v-icon>currency_exchange</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ $t('menu.top_up_management') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item to="serviceManagement" v-show="isAdmin" active-class="bg-active">
                            <v-list-item-icon>
                                <v-icon>category</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ $t('menu.service_management') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item to="storeManagement" v-show="isAdmin" active-class="bg-active">
                            <v-list-item-icon>
                                <v-icon>store</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ $t('menu.store_management') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item to="networkServiceManagement" v-show="isAdmin" active-class="bg-active">
                            <v-list-item-icon>
                                <v-icon>wifi_2_bar</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ $t('menu.network_management') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item to="settingManagement" v-show="isAdmin" active-class="bg-active">
                            <v-list-item-icon>
                                <v-icon>settings</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ $t('menu.setting') }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item to="FAQ" v-show="isCustomer" active-class="bg-active">
                            <v-list-item-icon>
                                <v-icon>quiz</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title> Phần mềm Fplus </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item to="APIIntegration" v-show="isCustomer" active-class="bg-active">
                            <v-list-item-icon>
                                <v-icon>cloud_queue</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title> API tích hợp</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item href="https://zalo.me/0947324568" target="_blank" v-show="isCustomer"
                                     active-class="bg-active">
                            <v-list-item-icon>
                              <v-icon>live_help</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title> Hỗ trợ zalo 0947324568</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-navigation-drawer>
                <router-view/>
            </div>
        </v-main>
    </div>
</template>

<script>
import i18n from '@/plugins/i18n';
import MarqueeText from '@/components/MarqueeText';
import socketClient from "@/plugins/socketClient";
import {isMobileBrowser} from "@/utils/Device";

// import VueJwtDecode from 'vue-jwt-decode';

export default {
    data: () => ({
        menus: [
            {title: i18n.t('menu.profile')},
            {title: i18n.t('menu.change_pass')},
            {title: i18n.t('menu.logout')},
        ],

        balance: 0,
        disabled: false,
        absolute: false,
        openOnHover: false,
        value: false,
        closeOnClick: true,
        closeOnContentClick: true,
        offsetX: false,
        offsetY: true,
        drawer: false,
        group: null,
        user: {},
        avatar: '',
        username: '',
        tabSelect: 0,
        isAdmin: false,
        isStaff: false,
        isDealer: false,
        isCustomer: false,

        userNotification: ''
    }),

    components: {
        MarqueeText: MarqueeText
    },

    watch: {
        group() {
            this.drawer = false
        }
    },

    computed: {
        isMobile() {
            return isMobileBrowser()
        }
    },

    methods: {
        selectItem: async function (index) {
            if (this.isDealer) {
                if (index == 0) {
                  await this.$router.push('profile');
                } else {
                  const response = await this.$logout();
                  if (response.status == process.env.VUE_APP_SUCCESS_STATUS) {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('username');
                    localStorage.removeItem('api_key');
                    localStorage.removeItem('balance');
                    localStorage.removeItem('userId');

                    await this.$router.push('login');
                  }
                }

                return;
            }

            if (index == 0) {
                await this.$router.push('profile');
            } else if (index == 1) {
                await this.$router.push('changePassword');
            } else if (index == 2) {
                // this.$store.dispatch('login/setStateLogin', false);
                const response = await this.$logout();

                if (response.status == process.env.VUE_APP_SUCCESS_STATUS) {
                    socketClient.getIO().close();

                    localStorage.removeItem('access_token');
                    localStorage.removeItem('username');
                    localStorage.removeItem('api_key');
                    localStorage.removeItem('balance');
                    localStorage.removeItem('userId');

                    await this.$router.push('login');
                } else if (response.data && response.status !== process.env.VUE_APP_SUCCESS_STATUS) {
                    // await this.$fire({
                    //   title: i18n.t('login.error_login'),
                    //   text: response.message,
                    //   type: "error"
                    // });
                } else {
                    // alert("Request error!");
                }
            }
        },

        async getSettingInfor() {
            try {
                const response = await this.$getAllConfig();
                if (response.status === 200) {
                    const contextArr = [].concat(response.data);
                    contextArr.forEach(item => {
                        if (item.key == 'UserNotification' && this.isCustomer) {
                            this.userNotification = item.value;
                        } else if (item.key == 'SaleNotification' && this.isDealer) {
                            this.userNotification = item.value;
                        }
                    });
                }
                // else if (response.data) {
                //   alert(i18n.t(response.data));
                // }
                // else {
                //   alert(i18n.t('validate.request_error'));
                // }
            } catch (e) {
                // alert(i18n.t('validate.request_error'));
            }
        }
    },

    async created() {
        // this.updateAllSim();

        // await this.getSettingInfor();
        const response = await this.$getInformationUser();
        if (response.status === 200) {
            localStorage.setItem('api_key', response.data.api_key);
            localStorage.setItem('balance', response.data.balance);
            this.balance = response.data.balance;
        }

        let socket;
        socket = await socketClient.createConnection();

        socket.on('connect', function () {
            // if (socketClient.getIO().connected) {
            //     alert("connected");
            // }
        });

        socket.on('notifications-message', (msg) => {
            this.$fire({
                title: "Thông báo",
                text: msg.message,
                type: "warning"
            });
        });

        socket.on('notifications-update-balance', (msg) => {
            this.balance = msg;
        });

        socket.on('disconnect', function () {
          // alert("disconnect");
        });

        const path = this.$router.history.current.name;
        this.username = localStorage.getItem("username");
        this.role_id = localStorage.getItem("role_id");

        if (this.role_id == 1) {
            this.isAdmin = true;
            this.isStaff = false;
            this.isDealer = false;
            this.isCustomer = false;
            this.menus = [
                {title: i18n.t('menu.profile')},
                {title: i18n.t('menu.change_pass')},
                {title: i18n.t('menu.logout')},
            ];
        } else if (this.role_id == 2) {
            this.isAdmin = false;
            this.isStaff = true;
            this.isDealer = false;
            this.isCustomer = false;
            this.menus = [
                {title: i18n.t('menu.profile')},
                {title: i18n.t('menu.change_pass')},
                {title: i18n.t('menu.logout')},
            ];
        } else if (this.role_id == 3 || this.role_id == 4 || this.role_id == 5) {
            this.isAdmin = false;
            this.isStaff = false;
            this.isDealer = true;
            this.isCustomer = false;
            this.menus = [
              {title: i18n.t('menu.profile')},
              {title: i18n.t('menu.logout')},
            ];
        } else {
            this.isAdmin = false;
            this.isStaff = false;
            this.isDealer = false;
            this.isCustomer = true;
            this.menus = [
                {title: i18n.t('menu.profile')},
                {title: i18n.t('menu.change_pass')},
                {title: i18n.t('menu.logout')},
            ];
        }

        await this.getSettingInfor();

        if (path && path != "main") {
            await this.$router.push(path);
        } else {
            if (this.role_id == 1) {
                await this.$router.push('staffManagement');
            } else if (this.role_id == 2) {
                await this.$router.push('SIMManagement');
            } else if (this.role_id == 6) {
                await this.$router.push('buyCodeManagement');
            } else {
                await this.$router.push('dealerOTPManagement');
            }
        }
    }
}
</script>


<style>

/*html {*/
/*  overflow: hidden !important;*/
/*  scrollbar-width: none;*/
/*  -ms-overflow-style: none;*/
/*}*/


/*.v-list-item--active {*/
/*  z-index: -1;*/
/*  border-right: 5px solid #ff586b;*/
/*}*/

.bg-active {
    z-index: -1;
    border-right: 5px solid #ff586b;
}

.titleName {
    text-align: end;
    margin-right: 10px;

    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.otpSuccess {
    display: inline-block;
    white-space: nowrap;
    margin: auto;
    background-color: #0C8F4D;
    padding: 5px;
    color: #FFFFFF;
    border-radius: 4px;
}

.otpSpam {
    display: inline-block;
    white-space: nowrap;
    margin: auto;
    background-color: gray;
    padding: 5px;
    color: #FFFFFF;
    border-radius: 4px;
}

.otpPending {
    display: inline-block;
    white-space: nowrap;
    margin: auto;
    background-color: #16bbd8;
    padding: 5px;
    color: #FFFFFF;
    border-radius: 4px;
}

.otpError {
    display: inline-block;
    white-space: nowrap;
    margin: auto;
    background-color: #ff586b;
    padding: 5px;
    color: #FFFFFF;
    border-radius: 4px;
}

.otpCancel {
    display: inline-block;
    white-space: nowrap;
    margin: auto;
    background-color: #F08942;
    padding: 5px;
    color: #FFFFFF;
    border-radius: 4px;
}

.linkText {
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    color: #F08942;
}

.errorColor {
    color: #ff586b;
}

.waitingText {
    color: #F08942;
}

.successText {
    color: #0C8F4D;
}

.timeoutText {
    color: #FF0000;
}

.freelancerText {
    color: #33CAFF;
}

.saleText {
    color: #0C8F4D;
}

.unreadText {
    font-weight: bold;
}

.readText {
    font-weight: normal;
}

.contentTitle {
    display: inline-block;
    /*max-width: 300px;*/
    overflow: hidden;
    /*text-overflow: ellipsis;*/
    white-space: nowrap;
}

.header-title {
    color: #000000;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
}

.text-title {
    color: #1D243E;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
}

.defaultFont {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
}

.defaultBoldFont {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
}

.contentName {
    display: inline-block;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.contentAddress {
    display: inline-block;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.subTitleStyle {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #6C738D;
}

.statisticFont {
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    color: #000000;
}

.v-list-item:hover {
    background-color: rgba(84, 164, 235, 0.40) !important;
}

.v-data-table .v-data-table-header tr th {
    white-space: nowrap;
    font-size: 13px !important;
    /*font-family: Arial;*/
    color: #000000 !important;
    border: 1px solid #e0e0e0;
}

.v-data-table th, td {
    padding: 3.6px !important;
    height: 32px !important;
}

.ve-table-body tr:first-of-type {
  display: none;
}

.v-data-table td {
    font-size: 12px !important;
    font-style: normal;
    font-weight: normal;
    color: #212529 !important;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
}

.v-data-table tbody tr:hover td, .table-hover tbody tr:hover th {
    background-color: rgba(84, 164, 235, 0.40) !important;
}

.v-btn {
    /*font-family: Arial;*/
    /*font-size: 12px !important;*/
    font-weight: normal;
    text-transform: none;
    letter-spacing: 0px;
    /*height: 32px !important;*/
}

/*.v-input .v-label {*/
/*  font-size: 12px !important;*/
/*}*/
/*.v-input input {*/
/*  font-size: 12px !important;*/
/*}*/

/*.v-text-field .v-input__slot {*/
/*  min-height: 32px !important;*/
/*  max-height: 32px !important;*/
/*  display: flex !important;*/
/*  align-items: center !important;*/
/*}*/

.v-list-item .v-list-item__title {
    font-size: 13px !important
}


.iconBorderOrange {
    background-color: #F08942;
    padding: 5px;
    border-radius: 3px;
}

.iconBorderGreen {
    background-color: #0cc37d;
    padding: 5px;
    border-radius: 3px;
}

.iconBorderRed {
    background-color: #ff0000;
    padding: 5px;
    border-radius: 3px;
}

.custom-date-picker-mobile > input {
    height: 48px !important;
}
</style>