<template>
  <div>
    <v-dialog v-model="value" persistent max-width="90%">
      <v-card class="elevation-16 mx-auto" height="90vh">
        <v-card-title class="header-title" primary-title>
          <span>{{$t("user_management.otp_history")}}</span>
          <v-spacer></v-spacer>
          <v-icon @click="closeDialog()">close</v-icon>
        </v-card-title>

        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="desserts"
              :loading="loading"
              sort-by="email"
              class="elevation-1"
              hide-default-footer
              :items-per-page="itemsPerPage"
          >
            <template v-slot:top>
              <v-toolbar flat color="white" class="pt-5 mb-2">
                <v-row justify="start">
                  <v-col cols="12" sm="3">
                    <v-autocomplete v-model="itemsPerPage" :items="numberPage" outlined :dense="true"></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="9">
                    <div class="d-flex flex-row-reverse">
                      <v-btn color="primary" dark @click="getDataByDate" class="ml-2">{{
                          $t("common.search")
                        }}</v-btn>

                      <vc-date-picker class="ml-2" mode="range" v-model="dateRange" :popover="{label: 'Hover', visibility: 'hover'}" style="width: 220px;">
                      </vc-date-picker>

                      <v-autocomplete class="ml-2" background-color="#ffffff" v-model="selectService" :items="services" item-text="name"
                                      v-bind:label="$t('buy_code_management.service')" return-object outlined dense >
                      </v-autocomplete>

                      <v-autocomplete background-color="#ffffff" v-model="selectOTPStatus" :items="otpStatus" item-text="title"
                                      v-bind:label="$t('buy_code_management.status')" return-object outlined dense >
                      </v-autocomplete>
                    </div>
                  </v-col>
                </v-row>
              </v-toolbar>

              <div>
                <span class="defaultFont">Tổng giao dịch: <span class="defaultBoldFont"> {{ $thousandSeprator(totalItem) }}</span>.
                  Tổng tiền: <span class="defaultBoldFont"> {{ $thousandSeprator(totalMoney) }} </span> </span>
              </div>
            </template>

            <template v-slot:[`item.payment`]="{ item }">
              <span class="text-no-wrap">{{ $thousandSeprator(item.payment) }}</span>
            </template>

            <template v-slot:[`item.service`]="{ item }">
              <span class="text-no-wrap"> {{ item.service.name }} </span>
            </template>

            <template v-slot:[`item.otp`]="{ item }">
              <div class="contentAddress">
                <span class="text-no-wrap" style="display: inline-block; width: 60px;"> {{ item.otp }} </span>
              </div>

            </template>

            <template v-slot:[`item.dealer`]="{ item }">
              <span class="text-no-wrap">{{ item.dealer.username  }}</span>
            </template>

            <template v-slot:[`item.type`]="{ item }">
              <span v-if="item.type !== 'audio'">{{ item.content  }}</span>
              <vuetify-audio v-else :file="item.audioMp3" color="primary" ></vuetify-audio>
            </template>

            <template v-slot:[`item.sim`]="{ item }">
              <div class="contentAddress">
                <span class="text-no-wrap" style="display: inline-block; width: 100px;"> {{ item.sim.phone }} </span>
              </div>
            </template>

            <template v-slot:[`item.create_date`]="{ item }">
              <span class="text-no-wrap">{{ item.create_date | formatDate }}</span>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <span :class="$statusOTP(item)">{{ $OTPStatusString(item.status) }}</span>
            </template>

<!--            <template v-slot:[`item.action`]="{ item }">-->
<!--              <div class="contentAddress d-flex justify-start">-->
<!--                <v-tooltip-->
<!--                    bottom-->
<!--                    max-width="300px"-->
<!--                    color="primary"-->
<!--                >-->
<!--                  <template v-slot:activator="{ on }">-->
<!--                    <v-icon v-on="on" size="16px" color="white" class="mr-2 iconBorderGreen" @click="showContent(item)">-->
<!--                      chat-->
<!--                    </v-icon>-->
<!--                  </template>-->
<!--                  <span>Gửi tin nhắn</span>-->
<!--                </v-tooltip>-->
<!--              </div>-->
<!--            </template>-->

            <template v-slot:no-data>
              <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
            </template>
          </v-data-table>

          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n';
import moment from 'moment';

export default {
  name: 'otp-history-dialog',

  props: {
    value: Boolean,
    editedItem: Object
  },

  data: () => ({
    baseImage: process.env.VUE_APP_BASE_IMAGE,
    baseAttach: process.env.VUE_APP_BASE_ATTACH,

    loading: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 500,

    numberPage: [
      50, 100, 300, 500, 1000
    ],

    dateRange: {start: new Date(), end: new Date()},

    totalItem: 0,
    totalMoney: 0,

    otpStatus: [
      { title: i18n.t("common.all"), value: -1 },
      { title: i18n.t("buy_code_management.success"), value: 1 },
      { title: i18n.t("buy_code_management.waiting"), value: 2 },
      { title: i18n.t("buy_code_management.timeout"), value: 0 },
    ],

    selectOTPStatus: { title: i18n.t("common.all"), value: -1 },
    selectService: { name: i18n.t("common.all"), id: -1 },
    services: [],

    search: '',
    headers: [
      { text: '#', align: 'left', sortable: false, value: 'index', },
      { text: i18n.t('buy_code_management.create_date'), value: 'create_date', sortable: false },
      // { text: i18n.t('buy_code_management.customer'), value: 'user', sortable: false },
      { text: i18n.t('buy_code_management.phone'), value: 'sim', sortable: false },

      { text: i18n.t('buy_code_management.service'), value: 'service', sortable: false },
      { text: i18n.t('buy_code_management.price'), value: 'payment', sortable: false },
      { text: 'Giá đại lý', value: 'payment', sortable: false },

      { text: i18n.t('buy_code_management.status'), value: 'status', sortable: false },
      { text: 'Nội dung', value: 'type', sortable: false },
      { text: 'Đại lý', value: 'dealer', sortable: false },
      // { text: '', align: 'left', value: 'action', sortable: false },
    ],

    desserts: [],
  }),

  computed: {},

  watch: {},

  mounted() {

  },

  async created() {
    await Promise.all([
      await this.getData(),
      await this.getAllService(),
      await this.getStatistic()
    ])
  },

  methods: {
    getDataByDate() {
      this.page = 1;
      this.getData();
    },

    async getData() {
      try {
        const from = this.$formatStartUTCDate(this.dateRange.start);
        const to = this.$formatEndUTCDate(this.dateRange.end);

        this.loading = true;
        const response = await this.$getAllOTPByUser(this.editedItem.id, this.selectService.id, this.selectOTPStatus.value,this.page - 1, this.itemsPerPage, from, to);
        this.loading = false;

        if (response.status === 200) {
          this.desserts = [].concat(response.data.content);
          this.pageCount = response.data.totalPages;
          this.totalItem = response.data.totalElements;
          // this.totalMoney = response.data.totalPayment;

          this.desserts.forEach(
              (item, index) => {
                item.index = (this.page - 1) * this.itemsPerPage + index + 1;
              }
          );
        }
      } catch (e) {
        console.log(e.stack);
      }
    },

    async getStatistic() {
      const from = this.$formatStartUTCDate(this.dateRange.start);
      const to = this.$formatEndUTCDate(this.dateRange.end);

      const response = await this.$calculateOTPUser(this.editedItem.id, this.selectService.id, from, to);

      if (response.status === 200) {
        this.totalMoney = response.data.payment;
      }
    },

    async getAllService() {
      try {
        const response = await this.$getAllService(0, 10000);

        if (response.status === 200) {
          this.services = [{ name: i18n.t("common.all"), id: -1 }].concat(response.data.content);
        }
      } catch (e) {
        console.log(e.stack);
      }
    },

    closeDialog() {
      this.$emit('closeDialog', false);
    }
  }
}
</script>

<style scoped>
.v-text-field .v-input__control .v-input__slot {
  min-height: 45px !important;
  /* display: flex !important; */
  align-items: center !important;
}
</style>
