<template>
  <v-container fluid>
    <div>
      <v-row justify="space-between">
        <v-col cols="12" sm="6" justify="center">
          <p class="header-title"> {{$t('transaction_management.title')}} </p>
        </v-col>

        <v-col cols="12" sm="6" justify="center">
          <div class="d-flex flex-row-reverse">
<!--            <v-btn color="primary" dark @click="getData">{{-->
<!--                $t("common.refresh")-->
<!--              }}</v-btn>-->
          </div>
        </v-col>
      </v-row>
    </div>
    <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :loading="loading"
        sort-by="email"
        class="elevation-1"
        hide-default-footer
        :items-per-page="itemsPerPage">
      <template v-slot:top>

        <v-toolbar flat color="white" class="pt-5 mb-2">
          <v-row justify="start">
            <v-col cols="12" sm="2">
              <v-autocomplete v-model="itemsPerPage" :items="numberPage" outlined :dense="true"></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="2">
            </v-col>

            <v-col cols="12" sm="2">
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field v-model="search" append-icon="search" v-bind:label="$t('common.search')" single-line hide-details outlined :dense="true"></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>

      <template v-slot:[`item.total`]="{ item }">
        <span class="text-no-wrap">{{ $thousandSeprator(item.total) }}</span>
      </template>

      <template v-slot:[`item.paid`]="{ item }">
        <span class="text-no-wrap">{{ $thousandSeprator(item.paid) }}</span>
      </template>

      <template v-slot:[`item.debit`]="{ item }">
        <span class="text-no-wrap">{{ $thousandSeprator(item.total - item.paid) }}</span>
      </template>

      <template v-slot:[`item.dealer`]="{ item }">
        <span class="text-no-wrap">{{ item.dealer.username }}</span>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="contentTitle">
          <v-tooltip
              slot="append-outer"
              bottom
              max-width="300px"
              color="primary"
          >
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" size="12px" color="white" class="mr-1 iconBorderGreen" @click="showPaymentDialog(item)">
                currency_exchange
              </v-icon>
            </template>
            <span> {{$t("transaction_management.payment")}} </span>
          </v-tooltip>

          <v-tooltip
              slot="append-outer"
              bottom
              max-width="300px"
              color="primary"
          >
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" size="12px" color="white" class="mr-1 iconBorderGreen" @click="showHistoryDialog(item)">
                chat
              </v-icon>
            </template>
            <span>{{$t("transaction_management.title")}}</span>
          </v-tooltip>

          <v-tooltip
              slot="append-outer"
              bottom
              max-width="300px"
              color="primary"
          >
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" size="12px" color="white" class="iconBorderRed" @click="deleteTransaction(item)">
                settings
              </v-icon>
            </template>
            <span>{{$t("transaction_management.reset")}}</span>
          </v-tooltip>
        </div>

      </template>

      <template v-slot:no-data>
        <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
      </template>
    </v-data-table>

    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="pageCount"></v-pagination>
    </div>

    <v-dialog v-model="paymentDialog" max-width="400px">
      <v-card class="elevation-16">
        <v-card-title>
          <span class="header-title"> {{ $t('transaction_management.payment')}}</span>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-container fluid class="mt-4">
            <v-row align="center" justify="center">
              <v-numeric-field v-model="payment" :rules="numberRules"
                               v-bind:label="$t('transaction_management.money')" outlined dense>
              </v-numeric-field>
            </v-row>

            <v-row>
              <v-text-field v-model="content" :rules="stringRules"
                            v-bind:label="$t('transaction_management.content')" outlined dense>
              </v-text-field>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-spacer/>
          <v-btn dark color="primary" @click="createTransaction">
            {{ $t('common.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <event-log v-model="historyDialog" :editedItem="editedItem" @closeDialog="closeEventDialog"
               v-if="historyDialog">
    </event-log>

    <v-snackbar
        v-model="snackbar"
        :color="color"
        :timeout="2000"
        top
        right
        elevation="24"
        class="mt-5"
    >
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import i18n from '@/plugins/i18n';
import VNumericField from "@/components/VNumericField";
import EventLog from "@/components/EventLog";

export default {
  data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 50000,
    loading: false,

    payment: 0,
    content: '',

    numberPage: [
      50, 100, 300, 500, 1000, 3000, 5000
    ],
    historyDialog: false,
    paymentDialog: false,
    snackbar: false,
    message: '',
    color: '#0C8F4D',

    search: '',
    dialog: false,
    headers: [
      { text: '#', align: 'left', sortable: false, value: 'index', },
      { text: i18n.t('transaction_management.dealer'), value: 'dealer' },
      { text: i18n.t('transaction_management.total'), value: 'total' },
      { text: i18n.t('transaction_management.paid'), value: 'paid' },
      { text: i18n.t('transaction_management.debit'), value: 'debit' },
      { text: '', value: 'action' },

      // { text: '', value: 'action', sortable: false }
    ],

    desserts: [],
    editedIndex: -1,
    editedItem: null,
    stringRules: [
      v => !!v || i18n.t('validate.not_empty'),
      // v => (v && v.length > 5) || i18n.t('validate.string_invalid')
    ],

    numberRules: [
      v => !!v || i18n.t('validate.not_empty'),
      v => (v && v > 0) || i18n.t('validate.string_invalid')
    ],
  }),

  computed: {
  },

  components: {
    VNumericField: VNumericField,
    EventLog: EventLog
  },


  watch: {
    itemsPerPage() {
      this.page = 1;
      this.getData();
    },

    page() {
      this.getData();
    },
  },

  async created () {
    await this.getData()
  },

  methods: {
    async getData() {
      try {
        this.loading = true;
        const response = await this.$getAllTransactionStatistic(this.page - 1, this.itemsPerPage);
        this.loading = false;

        if (response.status === 200) {
          this.desserts = [].concat(response.data.content.filter(item => item.total > 0));
          this.pageCount = response.data.totalPages;

          this.desserts.forEach(
              (item, index) => {
                item.index = (this.page - 1) * this.itemsPerPage + index + 1;
              }
          );
        }
      } catch (e) {
        console.log(e.stack);
      }
    },

    showPaymentDialog(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.paymentDialog = true;
    },

    closePaymentDialog() {
      this.editedIndex = -1;
      this.editedItem = null;
      this.paymentDialog = false;
    },

    showHistoryDialog(item) {
      this.historyDialog = true;
      this.editedItem = Object.assign({}, item)
    },

    async createTransaction() {
      if (!this.validateUserInfor()) {
        return;
      }

      const requestBody = {
        content: this.content,
        payment: this.payment,
        dealer_id: this.editedItem.dealer.id
      }

      const response = await this.$createTransaction(requestBody);
      if (response.status === 200) {
        this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
        this.message = i18n.t('common.update_success');
        this.snackbar = true;

      } else {
        this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
        this.message = 'Không thành công!';
        this.snackbar = true;
      }

      this.closePaymentDialog();
      await this.getData();
    },

    async deleteTransaction(item) {
      const requestBody = {
       dealer_id: item.dealer.id
      }
      const response = await this.$resetTransaction(requestBody);

      if (response.status === 200) {
        this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
        this.message = i18n.t('common.update_success');
        this.snackbar = true;
      } else {
        this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
        this.message = 'Không thành công!';
        this.snackbar = true;
      }

      await this.getData();
    },

    validateUserInfor() {
      if (!this.content || this.content.length == 0) {
        return false;
      }

      if (!this.payment || this.payment == 0) {
        return false;
      }

      return true;
    },

    closeEventDialog(value) {
      this.historyDialog = value;
      this.editedItem = null;
    }
  },
}
</script>