<template>
  <v-container fluid>
    <div>
      <v-row class="mt-2 ml-1">
        <p class="header-title"> {{$t('buy_code_management.otp_management')}} </p>
      </v-row>

      <div class="d-flex flex-row-reverse mt-2">
        <v-btn color="primary" dark @click="searchData" class="ml-2">{{
            $t("common.search")
          }}</v-btn>

        <vc-date-picker v-model="endDate" :popover="{label: 'Hover', visibility: 'hover'}" style="width: 120px;" class="ml-2">
        </vc-date-picker>
        <span class="mt-2 ml-2">đến</span>
        <vc-date-picker v-model="startDate" :popover="{label: 'Hover', visibility: 'hover'}" style="width: 120px;" class="ml-2">
        </vc-date-picker>

        <v-text-field class="ml-2" background-color="#ffffff"  v-model="search" v-bind:label="$t('common.search')" single-line hide-details outlined :dense="true"></v-text-field>

        <v-autocomplete class="ml-2" background-color="#ffffff" v-model="selectService" :items="services" item-text="name"
                        v-bind:label="$t('buy_code_management.service')" return-object outlined dense >
        </v-autocomplete>

        <v-autocomplete class="ml-2" background-color="#ffffff" v-model="selectAgency" :items="agencies" item-text="username"
                        v-bind:label="$t('sim_management.agency')" return-object outlined dense >
        </v-autocomplete>

        <v-autocomplete background-color="#ffffff" v-model="selectOTPStatus" :items="otpStatus" item-text="title"
                        v-bind:label="$t('buy_code_management.status')" return-object outlined dense >
        </v-autocomplete>
      </div>
    </div>

    <v-data-table
        :headers="headers"
        :items="desserts"

        :loading="loading"
        sort-by="email"
        class="elevation-1"
        hide-default-footer
        @dblclick:row="editItemDB"
        :items-per-page="itemsPerPage"
        >
<!--      :search="search"-->
<!--      :item-class="this.$statusOTP"-->
      <template v-slot:top>

        <v-toolbar flat color="white" class="pt-5 mb-2">
          <v-row justify="start">
            <v-col cols="12" sm="2" >
              <v-autocomplete v-model="itemsPerPage" :items="numberPage" outlined :dense="true"></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="2">
              <v-btn color="primary" dark @click="exportToExcel">
                Export
              </v-btn>
            </v-col>

            <v-col cols="12" sm="8">

<!--              <v-text-field v-model="search" append-icon="search" v-bind:label="$t('common.search')" single-line hide-details outlined :dense="true"></v-text-field>-->
            </v-col>
          </v-row>
        </v-toolbar>
        <div>
          <span class="defaultFont">Tổng giao dịch: <span class="defaultBoldFont"> {{ $thousandSeprator(totalItem) }}</span>
            . Tổng tiền: <span class="defaultBoldFont"> {{ $thousandSeprator(totalPayment) }} </span>
            . Tổng tiền đại lý: <span class="defaultBoldFont"> {{ $thousandSeprator(totalPaymentDealer) }} </span>
          </span>
        </div>
      </template>

      <template v-slot:[`item.payment`]="{ item }">
        <span class="text-no-wrap">{{ (item.status == 1 || item.status == 2) ? $thousandSeprator(item.payment) : ""}}</span>
      </template>

      <template v-slot:[`item.price_dealer`]="{ item }">
        <span class="text-no-wrap">{{ (item.status == 1 || item.status == 2) ? $thousandSeprator(item.price_dealer) : ""}}</span>
      </template>

      <template v-slot:[`item.dealer`]="{ item }">
        <span class="text-no-wrap">{{ item.dealer.username }}</span>
      </template>

      <template v-slot:[`item.service`]="{ item }">
        <span class="text-no-wrap"> {{ item.service.name }} </span>
      </template>

      <template v-slot:[`item.otp`]="{ item }">
        <div class="contentAddress">
          <span class="text-no-wrap" style="display: inline-block; width: 60px;"> {{ item.otp }} </span>
          <v-icon small class="mr-2" @click="copyClipboard(item.otp)" v-if="item.otp.length > 0">
            content_copy
          </v-icon>
<!--          <v-tooltip-->
<!--              bottom-->
<!--              max-width="300px"-->
<!--              color="primary"-->
<!--          >-->
<!--            <template v-slot:activator="{ on }">-->
<!--              <v-icon v-on="on" small class="mr-2" @click="copyClipboard(item.otp)" v-if="item.otp.length > 0">-->
<!--                content_copy-->
<!--              </v-icon>-->
<!--            </template>-->
<!--            <span>Click to Copy</span>-->
<!--          </v-tooltip>-->
        </div>

      </template>

      <template v-slot:[`item.user`]="{ item }">
        <span class="text-no-wrap">{{ item.user.username }}</span>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <span v-if="item.type !== 'audio'">{{ item.content  }}</span>
        <vuetify-audio v-else :file="item.audioMp3" color="primary" ></vuetify-audio>
      </template>

      <template v-slot:[`item.sim`]="{ item }">
        <div class="contentAddress">
          <span class="text-no-wrap" style="display: inline-block;"> {{ item.sim.phone}} </span>

          <v-icon small class="mr-1 ml-1" @click="copyClipboard(item.sim.phone)" v-if="item.sim.phone > 0">
            content_copy
          </v-icon>
<!--          <v-tooltip-->
<!--              bottom-->
<!--              max-width="300px"-->
<!--              color="primary"-->
<!--          >-->
<!--            <template v-slot:activator="{ on }">-->
<!--              <v-icon v-on="on" small class="mr-2" @click="copyClipboard(item.sim.phone)" v-if="item.sim.phone > 0">-->
<!--                content_copy-->
<!--              </v-icon>-->
<!--            </template>-->
<!--          </v-tooltip>-->
        </div>
      </template>

      <template v-slot:[`item.create_date`]="{ item }">
        <span class="text-no-wrap">{{ item.create_date | formatDate }}</span>
      </template>

      <template v-slot:[`item.update_date`]="{ item }">
        <span class="text-no-wrap">{{ item.update_date | formatDate }}</span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <span :class="$statusOTP(item)">{{ $OTPStatusString(item.status) }}</span>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="contentAddress d-flex justify-start">
            <v-tooltip
                bottom
                max-width="300px"
                color="primary"
            >
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" size="12px" color="white" class="mr-2 iconBorderGreen" @click="showSendSms(item)">
                  chat
                </v-icon>
              </template>
              <span>Gửi tin nhắn</span>
            </v-tooltip>
        </div>

      </template>

      <template v-slot:no-data>
        <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
      </template>
    </v-data-table>

    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="pageCount"></v-pagination>
    </div>

    <v-dialog v-model="isShowContent" max-width="600px" v-if="editedItem">
      <v-card class="elevation-16 mx-auto">
        <v-card-title>
           <span class="header-title">Nội dung tin nhắn</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-card-text class="px-1">
            <span> Thời gian: <span class="bold-title"> {{ editedItem.create_date | formatDate }} </span> </span> <br/>
            <span> Số gửi: <span class="bold-title"> {{ editedItem.sender_name }} </span> </span> <br/>
            <span> Nội dung: <span class="bold-title"> {{ editedItem.content }} </span> </span> <br/>
          </v-card-text>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions class="justify-space-between">
          <v-spacer></v-spacer>
          <v-btn dark color="primary" @click="closeContentDialog" width="120px">
            {{ $t('common.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <send-sms-dialog
        v-model="sendSmsDialog"
        :editedItem="editedItem"
        @closeDialog="closeSendSms"
        @sendSuccess="sendSmsSuccess"
        v-if="sendSmsDialog">
    </send-sms-dialog>

    <v-snackbar
        v-model="snackbar"
        :color="color"
        :timeout="2000"
        top
        right
        elevation="24"
        class="mt-5"
    >
      {{ message }}
    </v-snackbar>

    <v-overlay :value="overlay" :z-index="0">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import i18n from '@/plugins/i18n';
import socketClient from "@/plugins/socketClient";
import moment from 'moment'
import SendSmsDialog from "@/components/SendSmsDialog";
import * as XLSX from "xlsx";

export default {
  data: () => ({
    overlay: false,
    totalItem: 0,
    totalMoney: 0,
    page: 1,
    pageCount: 0,
    itemsPerPage: 300,
    loading: false,
    headerNumber: '',
    numberPage: [
      50, 100, 300, 500, 1000, 3000, 5000
    ],
    dateRange: {start: new Date(), end: new Date()},
    startDate: new Date(),
    endDate: new Date(),
    selectNetwork: {},
    networks: [],
    agencies: [],

    selectAgency: { username: i18n.t("common.all"), id: -1 },
    selectService: { name: i18n.t("common.all"), id: -1 },
    services: [],
    isShowContent: false,
    editedItem: null,

    sendSmsDialog: false,
    totalPayment: 0,
    totalPaymentDealer: 0,
    snackbar: false,
    message: '',
    color: '#0C8F4D',

    search: '',
    dialog: false,
    headers: [
      { text: '#', align: 'left', sortable: false, value: 'index', },
      { text: i18n.t('buy_code_management.create_date'), value: 'create_date', sortable: false },
      { text: i18n.t('buy_code_management.update_date'), value: 'update_date', sortable: false },
      { text: i18n.t('buy_code_management.customer'), value: 'user', sortable: false },
      { text: i18n.t('buy_code_management.phone'), value: 'sim', sortable: false },

      { text: i18n.t('buy_code_management.service'), value: 'service', sortable: false },
      { text: i18n.t('buy_code_management.price'), value: 'payment', sortable: false },
      { text: 'Giá đại lý', value: 'price_dealer', sortable: false },

      { text: i18n.t('buy_code_management.status'), value: 'status', sortable: false },
      { text: 'Nội dung', value: 'type', sortable: false },
      { text: 'Đại lý', value: 'dealer', sortable: false },
      { text: '', align: 'left', value: 'action', sortable: false },
    ],

    otpStatus: [
      { title: i18n.t("common.all"), value: -1 },
      { title: i18n.t("buy_code_management.success"), value: 1 },
      { title: i18n.t("buy_code_management.waiting"), value: 2 },
      { title: i18n.t("buy_code_management.cancel"), value: 3 },
      { title: i18n.t("buy_code_management.spam"), value: 4 },
      { title: i18n.t("buy_code_management.timeout"), value: 0 },
    ],

    selectOTPStatus: { title: i18n.t("common.all"), value: -1 },

    desserts: [],
    stringRules: [
      v => !!v || i18n.t('validate.not_empty'),
      // v => (v && v.length > 5) || i18n.t('validate.string_invalid')
    ],

    attributes: [
      // This is a single attribute
      {
        popover: { label: 'Hover', visibility: 'hover'}
      }
    ],

    selectedRows: [],
  }),

  components: {
    SendSmsDialog: SendSmsDialog
  },

  computed: {
    selectDragAttribute() {
      return {
        popover: {
          visibility: 'hover',
          isInteractive: false, // Defaults to true when using slot
        },
      };
    },
  },

  watch: {
    itemsPerPage() {
      this.page = 1;
      this.getAllOTP();
    },

    page() {
      this.getAllOTP();
    },
  },

  async created () {
    await Promise.all([
      await this.getAllOTP(),
      await this.getAllService(),
      await this.getAllAgency()
    ])

    await this.getStatistic();
    socketClient.getIO().on('notifications-otp', (msg) => {
      this.desserts.forEach((element, index) => {
        if(element.sim.phone === msg.phone && element.status == 2) {
          this.desserts[index].create_date = msg.time
          this.desserts[index].status = msg.status;

          if (msg.status === 1) {
            this.desserts[index].create_date = msg.time
            this.desserts[index].otp = msg.code;
            this.desserts[index].status = msg.status;
            this.desserts[index].content = msg.content;
          }

          return;
        }
      });
    });
    socketClient.getIO().on('notifications-otp-init', (msg) => {
      this.desserts.unshift(msg);
      this.desserts.forEach(
          (item, index) => {
            item.index = (this.page - 1) * this.itemsPerPage + index + 1;
          }
      );

      this.totalItem = this.totalItem + 1;
    });
  },

  methods: {
    async getAllAgency() {
      try {
        const response1 = await this.$getAllAgency(0, 1000, 3);
        if (response1.status === 200) {
          this.agencies = [{ username: i18n.t("common.all"), id: -1 }].concat(response1.data.content);
        }

        const response2 = await this.$getAllAgency(0, 1000, 4);
        if (response2.status === 200) {
          this.agencies = this.agencies.concat(response2.data.content);
        }

        const response3 = await this.$getAllAgency(0, 1000, 5);
        if (response3.status === 200) {
          this.agencies = this.agencies.concat(response3.data.content);
        }
      } catch (e) {
        console.log(e.stack);
      }
    },

    async searchData() {
      this.totalPayment = 0;
      this.totalPaymentDealer = 0;
      await Promise.all([
        await this.getAllOTP(),
        await this.getStatistic()
      ])
    },

    async getStatistic() {
      const from = this.$formatStartUTCDate(this.startDate);
      const to = this.$formatEndUTCDate(this.endDate);

      this.loading = true;
      const response = await this.$calculateOTP(this.search, this.selectAgency.id, this.selectService.id, from, to);
      this.loading = false;
      if (response.status === 200) {
        this.totalPayment = response.data.payment;
        this.totalPaymentDealer = response.data.payment_dealer;
      }
    },

    async getAllOTP() {
      try {
        const from = this.$formatStartUTCDate(this.startDate);
        const to = this.$formatEndUTCDate(this.endDate);

        this.loading = true;
        const response = await this.$getAllOTP(this.search, this.selectAgency.id, this.selectService.id, this.selectOTPStatus.value,this.page - 1, this.itemsPerPage, from, to);
        this.loading = false;
        // console.log(response.data);
        if (response.status === 200) {
          this.desserts = [].concat(response.data.content);
          this.pageCount = response.data.totalPages;
          this.totalItem = response.data.totalElements;
          // this.totalMoney = response.data.totalPayment;

          this.desserts.forEach(
              (item, index) => {
                item.index = (this.page - 1) * this.itemsPerPage + index + 1;
              }
          );
        }
      } catch (e) {
        console.log(e.stack);
      }
    },

    async exportToExcel() { // On Click Excel download button
      this.overlay = true
      const from = this.$formatStartUTCDate(this.startDate);
      const to = this.$formatEndUTCDate(this.endDate);

      const response = await this.$getAllOTP(this.search, this.selectAgency.id, this.selectService.id, this.selectOTPStatus.value,this.page - 1, this.totalItem, from, to);
      this.overlay = false

      if (response.data && response.status === 200) {
        const responseData = response.data.content;
        const rows = responseData.map(row => ({
          create_date: moment.utc(String(row.create_date)).local().format("HH:mm:ss DD/MM/YYYY"),
          username: row.user.username,
          service: row.service.name,
          payment: row.payment,
          price_dealer: row.price_dealer,
          phone: row.sim.phone,
          code: row.otp,
          audio: row.audioMp3,
          content: row.content,
          status: this.$OTPStatusString(row.status),
          dealer: row.dealer.username,
        }));

        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "OTP");

        XLSX.utils.sheet_add_aoa(worksheet, [[
          "Thời gian",
          "Khách hàng",
          "Dịch vụ",
          "Giá tiền",
          "Giá đại lý",
          "Số điện thoại",
          "Code",
          "Audio",
          "Nội dung",
          "Trạng thái",
          "Đại lý",]], { origin: "A1" });
        /* calculate column width */
        const max_width_date = rows.reduce((w, r) => Math.max(w, r.create_date.length), 15);
        // const max_width_audio = rows.reduce((w, r) => Math.max(w, r.audio === null ? 0 : r.audio.length), 15);
        // const max_width_content = rows.reduce((w, r) => Math.max(w, r.content === null ? 0 : r.content.length), 15);

        worksheet["!cols"] = [
          { wch: max_width_date },
          { wch: max_width_date },
          { wch: max_width_date },
          { wch: max_width_date },
          { wch: max_width_date },
          { wch: max_width_date },
          { wch: max_width_date },
          { wch: max_width_date },
          { wch: max_width_date },
          { wch: max_width_date },
          { wch: max_width_date }
        ];
        // export Excel file
        XLSX.writeFile(workbook, "OTP.xlsx", { compression: true });
      }
    },

    async cancelSMS(item) {
      const response = await this.$cancelSIM(item.sim.id);
      const self = this;
      if (response.status === 200) {
        self.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
        self.message = 'Hủy thành công!';
        self.snackbar = true;
        // await this.getAllOTP();
      } else {
        self.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
        self.message = 'Hủy không thành công!';
        self.snackbar = true;
      }
    },

    async renewSMS(item) {
      try {
        this.overlay = true;
        const response = await this.$renewSim(item.sim.id, item.service.id)
        this.overlay = false;

        if (response.status === 200) {
          this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
          this.message = 'Lấy lại số thành công!'
          this.snackbar = true;

          this.desserts.unshift(response.data);
          this.desserts.forEach(
              (item, index) => {
                item.index = (this.page - 1) * this.itemsPerPage + index + 1;
              }
          );
          // await this.getAllOTP();
        } else if (response.status === 312) {
          this.color = process.env.VUE_APP_WARNING_COLOR_TOAST;
          this.message = response.data
          this.snackbar = true;

          return;
        } else if (response.data) {
          this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
          this.message = response.data;
          this.snackbar = true;

          return;
        } else {
          this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
          this.message = i18n.t('validate.request_error')
          this.snackbar = true;
        }
      } catch (e) {
        console.log(e.stack);
        alert(i18n.t('validate.request_error'));
      }
    },

    copyClipboard(value) {
      const self = this;
      this.$copyText(value).then(function () {
        self.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
        self.message = 'Copy thành công ' + value;
        self.snackbar = true;
      }, function () {
        self.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
        self.message = 'Copy không thành công ' + value;
        self.snackbar = true;
      })
    },

    async getAllService() {
      try {
        const response = await this.$getAllService(0, 1000);

        if (response.status === 200) {
          this.services = [{ name: i18n.t("common.all"), id: -1 }].concat(response.data.content);
        }
      } catch (e) {
        console.log(e.stack);
      }
    },

    closeContentDialog() {
      this.isShowContent = false;
      this.editedItem = null;
    },

    showSendSms(item) {
      this.editedItem = item;
      this.sendSmsDialog = true;
    },

    sendSmsSuccess() {
      this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
      this.message = 'Gửi thành công!';
      this.snackbar = true;
    },

    closeSendSms() {
      this.sendSmsDialog = false;
      this.editedItem = null;
    },

    editItemDB(event, {item}) {
      if (item.status === 1) {
        this.editedIndex = this.desserts.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.isShowContent = true;
      }
    },
  }
}
</script>

<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

.bank-title {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
}

.bold-title {
  font-style: normal;
  font-weight: bold;
}

.notice-title {
  color: #FF0000;
  font-style: normal;
  font-weight: bold;
}

.v-btn {
  text-transform: unset !important;
}
</style>
