import Vue from "vue";

export default {
    install() {

        Vue.prototype.$getAllNetworkService = async function (page, limit) {
            let result = null;
            try {
                const response = await this.$http.get(process.env.VUE_APP_GET_ALL_NETWORK_SERVICE_API + "?limit=" + limit + "&page=" + page);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$createNetworkService = async function (requestData) {
            let result = null;
            try {
                const response = await this.$http.post(process.env.VUE_APP_CREATE_NETWORK_SERVICE_API, JSON.stringify(requestData));
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$updateNetworkService = async function (id, requestData) {
            let result = null;
            try {
                const response = await this.$http.put(process.env.VUE_APP_UPDATE_NETWORK_SERVICE_API + id, JSON.stringify(requestData));
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$deleteNetworkService = async function (id) {
            let result = null;
            try {
                const response = await this.$http.delete(process.env.VUE_APP_DELETE_NETWORK_SERVICE_API + id);
                if (response.data) {
                    result = response.data;
                }

                return result;
            } catch (e) {
                console.log(e.stack);
                return result;
            }
        };

        Vue.prototype.$statusWarehouse = function(item) {
            if (item.status == 0) {
                return 'timeoutText';
            }
        };
    },
};