<template>
  <v-app style="background-color: #E5E5E5;">
    <v-fade-transition mode="out-in">
      <app-loading v-if="loading" :loading="loading" />
      <router-view />
    </v-fade-transition>
  </v-app>
</template>

<script>
import {mapActions} from 'vuex';
import LoadingScreen from '@/pages/LoadingScreen';
export default {
  // name: 'App',
  components: {
    'app-loading': LoadingScreen
  },
  async created() {
    return this.initApp();
  },
  data() {
    return {
      loading: true
    }
  },
  watch: {
    group() {
      this.drawer = false
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false; //false
    }, 3000);
  },
  methods: {
    ...mapActions(['initApp'])
  },

};
</script>



<style scoped>
.tile:hover {
  background: #F5F6FA;
}
.application {
  background-color: #E5E5E5;
}
</style>
