<template>
  <v-container fluid>
    <div>
      <v-row justify="space-between">
        <v-col cols="12" sm="6" justify="center">
          <p class="header-title"> {{$t('sim_management.title')}} </p>
        </v-col>

        <v-col cols="12" sm="6" justify="center">
<!--          <div class="d-flex flex-row-reverse">-->
<!--            <v-btn class="ml-4" color="primary" dark @click="exportToExcel">-->
<!--              Export-->
<!--            </v-btn>-->

<!--            <v-btn dark depressed color="#ff586b" width="65px" @click="reportSims()" v-if="selectedRows.length > 0">Báo lỗi</v-btn>-->
<!--          </div>-->
        </v-col>
      </v-row>
    </div>

    <v-toolbar color="#E5E5E5" flat class="pt-1 mb-1">
      <v-row justify="start">
        <v-col cols="12" sm="2">
          <v-autocomplete
              background-color="#FFFFFF"
              v-model="selectAgency"
              :items="agencies"
              item-text="username"
              v-bind:label="$t('sim_management.agency')"
              return-object
              outlined
              :dense="true"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="4">
          <v-text-field background-color="#FFFFFF" v-model.lazy="search" append-icon="search" v-bind:label="$t('common.search')" single-line hide-details outlined :dense="true"></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <div class="d-flex flex-row">
            <v-btn color="primary" width="80px" dark @click="getData">
              Tìm kiếm
            </v-btn>

            <v-btn class="ml-4" width="80px"  color="primary" dark @click="exportToExcel">
              Export
            </v-btn>

            <v-btn class="ml-4" width="80px" color="#ff586b" dark @click="reportSims()" v-if="selectedRows.length > 0">Báo lỗi</v-btn>
          </div>
        </v-col>

<!--        <v-col cols="12" sm="2">-->
          <!--          <v-autocomplete v-model="itemsPerPage" :items="numberPage" outlined :dense="true"></v-autocomplete>-->
<!--        </v-col>-->

<!--        <v-col cols="12" sm="2">-->
          <!--          <v-autocomplete v-model="selectSIMStatus" :items="simStatus" item-text="title"-->
          <!--                          v-bind:label="$t('sim_management.status')" return-object outlined dense>-->
          <!--          </v-autocomplete>-->
<!--        </v-col>-->
      </v-row>
    </v-toolbar>
    <div justify="start">
      <span class="defaultFont">Tổng số kết quả: <span class="defaultBoldFont"> {{ $thousandSeprator(totalItem) }}</span> </span>
    </div>

    <ve-table
        style="background-color: white;"
        :fixed-header="false"
        max-height="calc(100vh - 220px)"
        :virtual-scroll-option="virtualScrollOption"
        :checkbox-option="checkboxOption"
        :columns="columns"
        :table-data="desserts"
        row-key-field-name="index"
        border-y
    />

    <v-snackbar
        v-model="snackbar"
        :color="color"
        :timeout="2000"
        top
        right
        elevation="24"
        class="mt-5"
    >
      {{ message }}
    </v-snackbar>

    <v-overlay :value="overlay" :z-index="0">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>

import i18n from '@/plugins/i18n';
import socketClient from '@/plugins/socketClient';
import moment from "moment";
import * as XLSX from "xlsx";

const DateComp = {
  name: "DateComp",
  template: `
        <div>
            <span>{{ row.create_date | formatDate }}</span>
        </div>
    `,
  props: {
    row: Object
  },
};

const StatusComp = {
  name: "StatusComp",
  template: `
        <div>
          <span :class="$statusSIM(row)">{{ $simStatusString(row.status) }}</span>
        </div>
    `,
  props: {
    row: Object
  },
};

export default {
  data() {
    return {
      virtualScrollOption: {
        enable: true
      },
      checkboxOption: {
        selectedRowChange: ({row, isSelected, selectedRowKeys}) => {
          this.selectedRowChange(row, isSelected);
        },

        selectedAllChange: ({isSelected, selectedRowKeys}) => {
          this.selectAllToggle(isSelected);
        },
      },

      overlay: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10000,
      loading: true,
      height: 1000,
      numberPage: [100, 300, 500, 1000, 3000],
      totalItem: 0,
      simStatus: [
        {title: i18n.t("common.all"), value: -1},
        {title: i18n.t("sim_management.active"), value: 1},
        {title: i18n.t("sim_management.inactive"), value: 0},
      ],

      selectSIMStatus: {title: i18n.t("sim_management.active"), value: 1},
      selectAgency: {username: i18n.t("common.all"), id: -1},
      agencies: [],
      selectedRows: [],

      snackbar: false,
      message: '',
      color: '#0C8F4D',

      isFilter: false,
      search: '',
      dialog: false,

      columns: [
        {field: "", key: "a", type: "checkbox", title: "", width: 40, align: "center"},
        {field: "index", key: "index", title: "#", align: "center"},
        {
          field: "dealer.username", key: "dealer", title: i18n.t('sim_management.dealer'), align: "left",
          renderBodyCell: ({row}) => {
            return (
                <span> {row.dealer.username} </span>
            );
          },
        },
        {
          field: "create_date",
          key: "create_date",
          title: i18n.t('sim_management.create_date'),
          width: 300,
          align: "left",
          renderBodyCell: ({row}) => {
            return <DateComp row={row}/>;
          },
        },
        {
          field: "network_id.name", key: "network_id", title: i18n.t('sim_management.network_service'), align: "left",
          renderBodyCell: ({row}) => {
            return (
                <span> {row.network_id.name} </span>
            );
          },
        },
        {field: "phone", key: "phone", title: i18n.t('sim_management.phone'), align: "center"},
        {
          field: "status", key: "status", title: i18n.t('sim_management.status'), align: "center",
          renderBodyCell: ({row}) => {
            return <StatusComp row={row}/>;
          },
        },
        {
          field: "warehouse.name", key: "warehouse", title: i18n.t('sim_management.ware_house'), align: "left",
          renderBodyCell: ({row}) => {
            return (
                <span> {row.warehouse.name} </span>
            );
          },
        },
        {
          field: "", key: "action", title: "", align: "center",
          renderBodyCell: ({row}) => {
            return (
                <span>
                  <button style="background-color: #ff586b; width: 65px; height: 32px; color: #FFFFFF; border-radius: 4px;"
                          on-click={() => this.reportSim(row)}>Báo lỗi</button>
                </span>
            );
          },
        }
      ],

      desserts: [],
      stringRules: [
        v => !!v || i18n.t('validate.not_empty'),
        // v => (v && v.length > 5) || i18n.t('validate.string_invalid')
      ]
    }
  },

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? i18n.t('common.add') : i18n.t('common.edit')
    },
  },

  watch: {
    // itemsPerPage() {
    //   this.page = 1;
    //   this.getData();
    // },

    page() {
      this.getData();
    },

    // selectAgency() {
    //   this.page = 1;
    //   this.getData();
    // },

    // selectSIMStatus() {
    //   this.page = 1;
    //   this.getData();
    // },
  },

  mounted() {
    // window.onpopstate = event => {
    //   this.$router.push("SIMManagement");
    // };

    // this.height = (window.innerHeight - 140)
  },

  async created () {
    socketClient.getIO().on('notifications-update', (msg) => {
      if (this.isFilter) {
        return;
      }

      let foundIndex = this.desserts.findIndex(x => x.phone === msg.phone)
      if (this.selectSIMStatus.value === -1) {
        if (foundIndex === -1) {
          this.desserts.push(msg)
          this.totalItem = this.totalItem + 1;
        } else {
          Object.assign(this.desserts[foundIndex], msg);
        }
      } else if (this.selectSIMStatus.value === 0) {
        if (foundIndex === -1) {
          if (msg.status === 0) {
            this.desserts.push(msg)
            this.totalItem = this.totalItem + 1;
          }
        } else {
          if (msg.status === 1) {
            this.desserts.splice(foundIndex, 1);
            this.totalItem = this.totalItem - 1;
          }
        }
      } else {
        if (foundIndex === -1) {
          if (msg.status === 1) {
            this.desserts.push(msg)
            this.totalItem = this.totalItem + 1;
          }
        } else {
          if (msg.status === 0) {
            this.desserts.splice(foundIndex, 1);
            this.totalItem = this.totalItem - 1;
          } else {
            Object.assign(this.desserts[foundIndex], msg);
          }
        }
      }

      this.desserts.forEach(
          (item, index) => {
            item.index = (this.page - 1) * this.itemsPerPage + index + 1;
          }
      );
    });

    await this.getAllAgency();
    await this.getData()
  },

  methods: {
    selectAllToggle(isSelect) {
      console.log("selectedAllChange");
      if (isSelect) {
        this.selectedRows = [].concat(this.desserts);
      } else {
        this.selectedRows = [];
      }

      console.log(this.selectedRows);
    },

    selectedRowChange(row, isSelected) {
      console.log("selectedRowChange");
      if (isSelected) {
        this.selectedRows.push(row);
      } else {
        const foundIndex = this.selectedRows.indexOf(row);
        this.selectedRows.splice(foundIndex, 1);
      }

      console.log(this.selectedRows);
    },

    async getData() {
      if (this.search.length > 0 || this.selectAgency.id > 0) {
        this.isFilter = true;
      } else {
        this.isFilter = false;
      }

      try {
        this.loading = true;
        const response = await this.$getAllSIM(this.search, this.selectAgency.id, this.selectSIMStatus.value,this.page - 1, this.itemsPerPage);
        this.loading = false;

        if (response.status === 200) {
          this.desserts = [].concat(response.data.content);
          this.pageCount = response.data.totalPages;
          this.totalItem = response.data.totalElements;
          this.desserts.forEach(
              (item, index) => {
                item.index = (this.page - 1) * this.itemsPerPage + index + 1;
              }
          );
        }
      } catch (e) {
        console.log(e.stack);
      }
    },

    async exportToExcel() { // On Click Excel download button
      this.overlay = true
      const response = await this.$getAllSIM(this.search, this.selectAgency.id, this.selectSIMStatus.value,this.page - 1, 10000);
      this.overlay = false

      if (response.data && response.status === 200) {
        const responseData = response.data.content;
        const rows = responseData.map(row => ({
          username: row.dealer.username,
          create_date: moment.utc(String(row.create_date)).local().format("HH:mm:ss DD/MM/YYYY"),
          network: row.network.name,
          phone: row.phone,
          status: this.$simStatusString(row.status),
          ware_house: row.ware_house.name
        }));

        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "SIM");

        XLSX.utils.sheet_add_aoa(worksheet, [["Đại lý", "Cập nhật", "Nhà mạng", "Số điện thoại", "Trạng thái", "Kho số"]], { origin: "A1" });
        /* calculate column width */
        const max_width_username = rows.reduce((w, r) => Math.max(w, r.username.length), 15);
        const max_width_date = rows.reduce((w, r) => Math.max(w, r.create_date.length), 15);
        const max_width_status = rows.reduce((w, r) => Math.max(w, r.status.length), 15);

        worksheet["!cols"] = [
          { wch: max_width_username },
          { wch: max_width_date },
          { wch: max_width_username },
          { wch: max_width_username },
          { wch: max_width_status },
          { wch: max_width_username }
        ];
        // export Excel file
        XLSX.writeFile(workbook, "SIM.xlsx", { compression: true });
      }
    },

    async getAllAgency() {
      try {
        const response1 = await this.$getAllAgency(0, 1000, 3);
        if (response1.status === 200) {
          this.agencies = [{ username: i18n.t("common.all"), id: -1 }].concat(response1.data.content);
        }

        const response2 = await this.$getAllAgency(0, 1000, 4);
        if (response2.status === 200) {
          this.agencies = this.agencies.concat(response2.data.content);
        }

        const response3 = await this.$getAllAgency(0, 1000, 5);
        if (response3.status === 200) {
          this.agencies = this.agencies.concat(response3.data.content);
        }
      } catch (e) {
        console.log(e.stack);
      }
    },

    async reportSims() {
      const requestBody = this.selectedRows.map(function(item) {
        return item.phone;
      });

      const response = await this.$reportSIM(requestBody);
      if (response.status === 200) {
        this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
        this.message = 'Báo lỗi thành công!';
        this.snackbar = true;

        await this.getData();
      } else if (response.data) {
        this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
        this.message = i18n.t('common.error');
        this.snackbar = true;
      } else {
        this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
        this.message = i18n.t('validate.request_error');
        this.snackbar = true;
      }
    },

    async reportSim(item) {
      let objIndex = this.desserts.indexOf(item);

      const requestBody = [
        item.phone
      ];

      const response = await this.$reportSIM(requestBody);
      if (response.status === 200) {
        this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
        this.message = 'Báo lỗi thành công!';
        this.snackbar = true;
        this.desserts[objIndex].status = 0;

        // await this.getData();
      } else if (response.data) {
        this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
        this.message = i18n.t('common.error');
        this.snackbar = true;
      } else {
        this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
        this.message = i18n.t('validate.request_error');
        this.snackbar = true;
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.item-cell {
  border: 1px solid #212529;
}
.t-bottom {
  border-top: 1px solid #212529;
  background-color: #212529;
}

.scroller {
  height: 100%;
}

.message {
  display: flex;
  min-height: 32px;
  padding: 12px;
  box-sizing: border-box;
}
</style>