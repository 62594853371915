<template>
  <v-container fluid>
    <div>
      <v-row justify="space-between">
        <v-col cols="12" sm="6" justify="center">
          <p class="header-title"> {{$t('store_management.title')}} </p>
        </v-col>

        <v-col cols="12" sm="6" justify="center">
          <div class="d-flex flex-row-reverse">
            <v-dialog v-model="dialog" persistent max-width="600px">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on">{{ $t('common.add')}}</v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="header-title">{{ formTitle }}</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-container>
                    <v-row no-gutters>
                      <v-col>
                        <v-text-field v-model="editedItem.name" :rules="stringRules" v-bind:label="$t('store_management.name')" outlined dense></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row no-gutters v-if="editedIndex > -1">
                      <v-col>
                        <v-autocomplete v-model="selectStatus" :items="warehouseStatus" item-text="text" v-bind:label="$t('store_management.status')" return-object outlined dense>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" dark @click="close">{{ $t('common.cancel')}}</v-btn>
                  <v-btn color="primary" dark @click="save">{{ $t('common.save')}}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>

        </v-col>
      </v-row>
    </div>
    <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :loading="loading"
        sort-by="email"
        class="elevation-1"
        hide-default-footer
        @dblclick:row="editItem"
        :items-per-page="itemsPerPage"
        :item-class="this.$statusWarehouse">
      <template v-slot:top>

        <v-toolbar flat color="white" class="pt-5">
          <v-row justify="start">
            <v-col cols="12" sm="2">
              <v-autocomplete v-model="itemsPerPage" :items="numberPage" outlined :dense="true"></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="1">
            </v-col>

            <v-col cols="12" sm="2">
            </v-col>

            <v-col cols="12" sm="7">
              <v-text-field v-model="search" append-icon="search" v-bind:label="$t('common.search')" single-line hide-details outlined :dense="true"></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>

      <template v-slot:[`item.create_date`]="{ item }">
        <span class="text-no-wrap">{{ item.create_date | formatDate }}</span>
      </template>

<!--      <template v-slot:[`item.action`]="{ item }">-->
<!--        <v-icon v-on="on" size="16px" color="white" class="mr-2 iconBorderOrange" @click="editItem(item)">-->
<!--          edit-->
<!--        </v-icon>-->

<!--      </template>-->

      <template v-slot:no-data>
        <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
      </template>
    </v-data-table>

    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="pageCount"></v-pagination>
    </div>

    <v-snackbar
        v-model="snackbar"
        :color="color"
        :timeout="2000"
        top
        right
        elevation="24"
        class="mt-5"
    >
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import i18n from '@/plugins/i18n';

export default {
  data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    loading: true,

    numberPage: [
      5, 10, 15, 20, 25
    ],

    selectStatus: { text: i18n.t('store_management.active'), value: 1 },
    warehouseStatus: [
      { text: i18n.t('store_management.active'), value: 1 },
      { text: i18n.t('store_management.inactive'), value: 0 },
    ],

    snackbar: false,
    message: '',
    color: '#0C8F4D',

    search: '',
    dialog: false,
    headers: [
      { text: '#', align: 'left', sortable: false, value: 'index', },
      { text: i18n.t('store_management.identity'), value: 'code' },
      { text: i18n.t('store_management.name'), value: 'name' },
      { text: i18n.t('store_management.create_date'), value: 'create_date' },
      // { text: '', value: 'action', sortable: false }
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    stringRules: [
      v => !!v || i18n.t('validate.not_empty'),
      // v => (v && v.length > 5) || i18n.t('validate.string_invalid')
    ]
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? i18n.t('common.add') : i18n.t('common.edit')
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },

    itemsPerPage() {
      this.page = 1;
      this.getData();
    },

    page() {
      this.getData();
    },
  },

  async created () {
    await this.getData()
  },

  methods: {
    editItem(event, {item}) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)

      this.selectStatus = this.warehouseStatus.find(status => status.value === this.editedItem.status)
      this.dialog = true
    },

    async deleteItem(item) {
      const agree = confirm(i18n.t('common.confirm_delete'))
      if (agree) {
        const response =  await this.$deleteWareHouse(item.id);
        if(response.status === 200) {
          this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
          this.message = 'Xóa thành công!';
          this.snackbar = true;

          await this.getData()
        } else {
          this.color = process.env.VUE_APP_ERROR_COLOR_TOAST;
          this.message = i18n.t('common.error')
          this.snackbar = true;

          this.editedIndex = -1;
        }
      }
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    async save () {
      const self = this;
      if (!this.editedItem.name || this.editedItem.name.length == 0) {
        alert(i18n.t('validate.infor_empty'));
        return;
      }

      if (this.editedIndex > -1) {
        try {
          self.editedItem.status = this.selectStatus.value;
          const requestBody = self.editedItem;
          const response = await this.$updateWareHouse(self.editedItem.id, requestBody);
          if (response.status === 200) {
            Object.assign(this.desserts[this.editedIndex], this.editedItem);
            self.editedItem = {};

            this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
            this.message = i18n.t('common.update_success');
            this.snackbar = true;
          } else if (response.data) {
            // alert(response.data);
            await this.$fire({
              title: i18n.t('common.error'),
              text: response.data,
              type: "error"
            });
            return;
          }
        } catch (e) {
          console.log(e.stack);
          alert(i18n.t('validate.request_error'));
        }
      } else {
        try {
          const requestBody = self.editedItem;
          const response = await this.$createWareHouse(requestBody);

          if (response.status === 200) {
            self.editedItem = response.data;

            this.desserts.push(self.editedItem);
            self.editedItem.index = this.desserts.length;
            self.editedItem = {};

            this.color = process.env.VUE_APP_SUCCESS_COLOR_TOAST;
            this.message = i18n.t('common.add_success')
            this.snackbar = true;

            this.editedIndex = -1;
          } else if (response.data) {
            // alert(response.data);
            await this.$fire({
              title: i18n.t('common.error'),
              text: response.data,
              type: "error"
            });
            return;
          }
        } catch (e) {
          console.log(e.stack);
          alert(i18n.t('validate.request_error'));
        }
      }
      this.close()
    },

    async getData() {
      try {
        this.loading = true;

        const response = await this.$getAllWareHouse(this.page - 1, this.itemsPerPage);
        this.loading = false;

        if (response.status === 200) {
          this.desserts = [].concat(response.data.content);

          this.pageCount = response.data.totalPages;

          this.desserts.forEach(
              (item, index) => {
                item.index = (this.page - 1) * this.itemsPerPage + index + 1;
              }
          );
        }
      } catch (e) {
        console.log(e.stack);
      }
    }
  },
}
</script>