<template>
  <div>
    <v-dialog v-model="value" persistent max-width="60%">
      <v-card class="elevation-16 mx-auto">
        <v-card-title >
          <span class="header-title">Nhập ghi chú</span>
          <v-spacer></v-spacer>
          <v-icon @click="cancelAction()">close</v-icon>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text>
          <v-form v-model="formValid" ref="myForm">
            <v-textarea class="mt-5" v-model="content" label="Ghi chú" outlined></v-textarea>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="updateNote">
            {{ $t('common.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <!--      <v-overlay :value="overlay" :z-index="0">-->
      <!--        <v-progress-circular indeterminate size="64"></v-progress-circular>-->
      <!--      </v-overlay>-->
    </v-dialog>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n';

export default {
  name: 'create-note-credit',

  props: {
    value: Boolean,
    editedItem: Object,
  },


  data: () => ({
    e1: 1,
    date: '',
    modal: false,
    show: false,
    formValid: false,
    dialog: false,
    overlay: false,

    content: '',

    stringRules: [
      v => !!v || i18n.t('validate.string_require'),
      //   v => (v && v.length > 3) || i18n.t('validate.string_invalid')
    ],
    numberRules: [
      v => !!v || i18n.t('validate.not_empty'),
      v => (v && v > 0) || i18n.t('validate.string_invalid')
    ],
  }),

  computed: {

  },

  components: {

  },

  watch: {

  },

  async mounted() {

  },

  async created() {

  },

  methods: {
    async updateNote() {
      this.$refs.myForm.validate();

      if (!this.validateInfor()) {
        return;
      }

      const requestBody = {
        note: this.content
      }

      const response = await this.$updateNoteCredit(this.editedItem.id, requestBody);
      if (response.status === 200) {
        this.$emit('updateSuccess');
        this.$emit('closeDialog', false);
      } else {
        alert(i18n.t('validate.request_error'));
      }
    },

    validateInfor() {
      if (!this.content || this.content.length == 0) {
        return false;
      }

      return true;
    },

    cancelAction() {
      this.$emit('closeDialog', false);
    }
  }
}
</script>

<style scoped>

.v-text-field .v-input__control .v-input__slot {
  min-height: 45px !important;
  /* display: flex !important; */
  align-items: center !important;
}
</style>
