import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '../translations/index';
// import store from '../store';
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'vn',
  fallbackLocale: 'vn',
  messages
});

export default i18n;