<template>
  <v-container fluid>
    <div>
      <v-row justify="space-between">
        <v-col cols="12" sm="6" justify="center">
          <p class="header-title"> {{$t('sim_management.title')}} </p>
        </v-col>
      </v-row>
    </div>

    <v-data-table
        :headers="headers"
        :items="desserts"
        :loading="loading"
        sort-by="email"
        class="elevation-1"
        hide-default-footer
        :items-per-page="itemsPerPage">
      <template v-slot:top>
        <v-toolbar flat color="white" class="pt-5 mb-2">
          <v-row justify="start">
            <v-col cols="12" sm="2">
              <v-autocomplete v-model="itemsPerPage" :items="numberPage" outlined :dense="true"></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="2">
              <v-autocomplete v-model="selectSIMStatus" :items="simStatus" item-text="title"
                              v-bind:label="$t('sim_management.status')" return-object outlined dense>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="5">
              <v-text-field v-model="search" append-icon="search" v-bind:label="$t('common.search')" single-line hide-details outlined :dense="true"></v-text-field>
            </v-col>

            <v-col cols="12" sm="3">

              <div class="d-flex">
                <v-btn class="mr-4" color="primary" dark @click="getData">
                  Tìm kiếm
                </v-btn>

                <v-btn color="primary" dark @click="exportToExcel">
                  Export
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-toolbar>
        <div justify="start">
          <span class="defaultFont">Tổng số kết quả: <span class="defaultBoldFont"> {{ $thousandSeprator(totalItem) }}</span> </span>
        </div>
      </template>

      <template v-slot:[`item.ware_house`]="{ item }">
        <span class="text-no-wrap">{{ item.ware_house.name }}</span>
      </template>

      <template v-slot:[`item.dealer`]="{ item }">
        <span class="text-no-wrap">{{ item.dealer.username }}</span>
      </template>

      <template v-slot:[`item.network_id`]="{ item }">
        <span class="text-no-wrap">{{ item.network_id.name }}</span>
      </template>

      <template v-slot:[`item.create_date`]="{ item }">
        <span class="text-no-wrap">{{ item.create_date | formatDate }}</span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <span :class="$statusSIM(item)">{{ $simStatusString(item.status) }}</span>
      </template>

      <template v-slot:no-data>
        <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
      </template>
    </v-data-table>

    <!--    <div class="text-center pt-2">-->
    <!--      <v-pagination v-model="page" :length="pageCount"></v-pagination>-->
    <!--    </div>-->

    <v-overlay :value="overlay" :z-index="0">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>

import i18n from '@/plugins/i18n';
import socketClient from '@/plugins/socketClient';
import moment from "moment";
import * as XLSX from "xlsx";

export default {
  data: () => ({
    overlay: false,
    page: 1,
    pageCount: 0,
    itemsPerPage: 1000,
    loading: true,

    numberPage: [100, 300, 500, 1000, 3000],
    totalItem: 0,
    simStatus: [
      { title: i18n.t("common.all"), value: -1 },
      { title: i18n.t("sim_management.active"), value: 1 },
      { title: i18n.t("sim_management.inactive"), value: 0 },
    ],

    selectSIMStatus: { title: i18n.t("sim_management.active"), value: 1 },
    selectAgency: {username: i18n.t("common.all"), id: -1 },
    agencies: [],
    selectedRows: [],

    snackbar: false,
    message: '',
    color: '#0C8F4D',


    search: '',
    dialog: false,
    headers: [
      { text: '#', align: 'left', sortable: false, value: 'index', },
      // { text: i18n.t('sim_management.dealer'), value: 'dealer' },

      { text: i18n.t('sim_management.create_date'), value: 'create_date' },
      { text: i18n.t('sim_management.network_service'), value: 'network_id' },
      { text: i18n.t('sim_management.phone'), value: 'phone' },
      { text: i18n.t('sim_management.status'), value: 'status' },
      // { text: i18n.t('sim_management.ware_house'), value: 'ware_house' },
      // { text: i18n.t('sim_management.service'), value: 'services' },

      // { text: '', value: 'action', sortable: false }
    ],

    desserts: [],
    stringRules: [
      v => !!v || i18n.t('validate.not_empty'),
      // v => (v && v.length > 5) || i18n.t('validate.string_invalid')
    ]
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? i18n.t('common.add') : i18n.t('common.edit')
    },
  },

  watch: {
    // itemsPerPage() {
    //   this.page = 1;
    //   this.getData();
    // },

    page() {
      this.getData();
    },

    // selectAgency() {
    //   this.page = 1;
    //   this.getData();
    // },
    //
    // selectSIMStatus() {
    //   this.page = 1;
    //   this.getData();
    // },
  },

  mounted() {
    window.onpopstate = event => {
      this.$router.push("DealerSIMManagement");
    };
  },

  async created () {
    // socketClient.getIO().on('notifications-update', (msg) => {
    //   let foundIndex = this.desserts.findIndex(x => x.phone === msg.phone)
    //   if (this.selectSIMStatus.value === -1) {
    //     if (foundIndex === -1) {
    //       this.desserts.push(msg)
    //     } else {
    //       Object.assign(this.desserts[foundIndex], msg);
    //     }
    //   } else if (this.selectSIMStatus.value === 0) {
    //     if (foundIndex === -1) {
    //       if (msg.status === 0) {
    //         this.desserts.push(msg)
    //       }
    //     } else {
    //       if (msg.status === 1) {
    //         this.desserts.splice(foundIndex, 1);
    //       }
    //     }
    //   } else {
    //     if (foundIndex === -1) {
    //       if (msg.status === 1) {
    //         this.desserts.push(msg)
    //       }
    //     } else {
    //       if (msg.status === 0) {
    //         this.desserts.splice(foundIndex, 1);
    //       } else {
    //         Object.assign(this.desserts[foundIndex], msg);
    //       }
    //     }
    //   }
    //
    //   this.desserts.forEach(
    //       (item, index) => {
    //         item.index = (this.page - 1) * this.itemsPerPage + index + 1;
    //       }
    //   );
    //   this.totalItem = this.desserts.length;
    // });

    await this.getData()
  },

  methods: {
    async getData() {
      try {
        this.loading = true;
        const response = await this.$getAllSIM(this.search, this.selectAgency.id, this.selectSIMStatus.value,this.page - 1, this.itemsPerPage);
        this.loading = false;

        if (response.status === 200) {
          this.desserts = [].concat(response.data.content);
          this.pageCount = response.data.totalPages;
          this.totalItem = response.data.totalElements;
          this.desserts.forEach(
              (item, index) => {
                item.index = (this.page - 1) * this.itemsPerPage + index + 1;
              }
          );
        }
      } catch (e) {
        console.log(e.stack);
      }
    },

    async exportToExcel() { // On Click Excel download button
      this.overlay = true
      const response = await this.$getAllSIM(this.search, this.selectAgency.id, this.selectSIMStatus.value,this.page - 1, 100000);
      this.overlay = false

      if (response.data && response.status === 200) {
        const responseData = response.data.content;
        const rows = responseData.map(row => ({
          create_date: moment.utc(String(row.create_date)).local().format("HH:mm:ss DD/MM/YYYY"),
          network: row.network.name,
          phone: row.phone,
          status: this.$simStatusString(row.status)
        }));

        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "SIM");

        XLSX.utils.sheet_add_aoa(worksheet, [["Cập nhật", "Nhà mạng", "Số điện thoại", "Trạng thái"]], { origin: "A1" });
        /* calculate column width */
        const max_width_date = rows.reduce((w, r) => Math.max(w, r.create_date.length), 15);

        worksheet["!cols"] = [
          { wch: max_width_date },
          { wch: max_width_date },
          { wch: max_width_date },
          { wch: max_width_date }
        ];
        // export Excel file
        XLSX.writeFile(workbook, "SIM.xlsx", { compression: true });
      }
    },
  },
}
</script>