import Login from '@/pages/Login';
import Main from '@/pages/Main';
import Register from '@/pages/Register';
import StaffManagement from '@/pages/StaffManagement';

import StoreManagement from '@/pages/StoreManagement';
import NetworkServiceManagement from '@/pages/NetworkServiceManagement';
import ServiceManagement from '@/pages/ServiceManagement';
import SettingManagement from '@/pages/SettingManagement';
import TopUpManagement from '@/pages/TopUpManagement';
import OTPManagement from '@/pages/OTPManagement';
import CustomerOTPManagement from '@/pages/CustomerOTPManagement.vue';
import SIMManagement from '@/pages/SimManagementNew';
import DealerSIMManagement from '@/pages/DealerSIMManagement';
import BuyCodeManagement from '@/pages/BuyCodeManagement';

import TransactionManagement from '@/pages/TransactionManagement';
import CreditManagement from '@/pages/CreditManagement';

import DealerOTPManagement from '@/pages/DealerOTPManagement';
import FAQ from '@/pages/FAQ';
import APIIntegration from '@/pages/APIIntegration';
import ChangePassword from '@/pages/ChangePassword';
import Profile from '@/pages/Profile';

const routes = [
    {path: '/login', name: 'login', component: Login, meta: {requireLogin: false}},
    {path: '/register', name: 'register', component: Register, meta: {requireLogin: false}},
    {
        path: '/', name: 'main', component: Main, children: [
            {path: '/staffManagement', name: 'staffManagement', component: StaffManagement},
            {path: '/storeManagement', name: 'storeManagement', component: StoreManagement},
            {path: '/networkServiceManagement', name: 'networkServiceManagement', component: NetworkServiceManagement},
            {path: '/serviceManagement', name: 'serviceManagement', component: ServiceManagement},
            {path: '/settingManagement', name: 'settingManagement', component: SettingManagement},
            {path: '/topUpManagement', name: 'topUpManagement', component: TopUpManagement},
            {path: '/OTPManagement', name: 'OTPManagement', component: OTPManagement},
            {path: '/customerOTPManagement', name: 'customerOTPManagement', component: CustomerOTPManagement},
            {path: '/SIMManagement', name: 'SIMManagement', component: SIMManagement},
            {path: '/dealerSIMManagement', name: 'DealerSIMManagement', component: DealerSIMManagement},
            {path: '/buyCodeManagement', name: 'buyCodeManagement', component: BuyCodeManagement},
            {path: '/transactionManagement', name: 'transactionManagement', component: TransactionManagement},
            {path: '/creditManagement', name: 'creditManagement', component: CreditManagement},
            {path: '/dealerOTPManagement', name: 'dealerOTPManagement', component: DealerOTPManagement},
            {path: '/FAQ', name: 'FAQ', component: FAQ},
            {path: '/APIIntegration', name: 'APIIntegration', component: APIIntegration},
            {path: '/changePassword', name: 'changePassword', component: ChangePassword},
            {path: '/profile', name: 'profile', component: Profile}
        ]
    },
];

export default routes;